/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "assets/img/pencil-icon.svg";
import DeleteIcon from "assets/img/trash-icon.svg";
import ArrowDownImg from "assets/img/table-class-before.svg";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import {
  addObjective,
  deleteObjective,
  getAllObjectives,
  updateObjective,
  resetObjectives,
} from "redux/actionCreators/objectives";
import {
  AddObjectiveModal,
  DeleteObjectiveModal,
  UpdateObjectiveModal,
  CreateFormativeModal,
  DeleteFormativeOnlyModal
} from "./objectives-modals";
import { getSubjectAndGradeList } from "services/get-subgrade-list";
import { TableSelectCommon } from "components/common/selectComponent";
import PdfIcon from "components/common/svgs/pdfIcon";
import { fileKeys } from "./uploadFiles";
import ActionType from "redux/actionTypes";
import ObjectiveFilesModal from "./files-modal";
import PowerStandard from "components/common/svgs/power-standard";
import DropdownWithLabel from "components/common/DropdownWithLabel";
import ToolTip from "components/common/Tooltip";
import BackButton from "components/common/backButton";
import { useNavigate } from "react-router-dom";

interface RootState {
  objectives: any;
}

const EditObjectives = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { objectives } = useSelector((state: RootState) => state);
  const [subAndGradeList, setSubAndGradeList] = useState<null | any>(null);
  const [selectedSubAndGrade, setSelectedSubAndGrade] = useState<any>();
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [openFilesModal, setOpenFilesModal] = useState(false);
  const [currentSubGrade, setCurrentSubGrade] = useState<null | any>(null);
  const [openDropdown, setOpenDropdown] = useState<boolean | undefined>(true);
  const [data, setData] = useState<any>(null);
  const [sortBy, setSortBy] = useState("name");
  const [OrderByColumn, setOrderBy] = useState("ASC");
  const [search, setSearch] = useState('');
  const [showDropDown, setShowDropDown] = useState<boolean>();
  const [dropDownLabel, setDropDownLabel] = useState<string>("EN Description");
  const [createFormativeModal, setCreateFormativeModal] = useState(false);
  const [createFormativeModalHeader, setCreateFormativeHeader] = useState('');

  const [deleteFormativeModal, setDeleteFormativeModal] = useState(false);

  const getSubGrade = async () => {
    try {
      let response = await getSubjectAndGradeList(false);
      setSubAndGradeList(response?.data?.grade_subjects_array.map((item: any, index: number) => ({...item, label: item.name, value: index+1})));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSubGrade();
    return(() => {
      dispatch(resetObjectives());
    })
  }, []);

  useEffect(() => {
    if (selectedSubAndGrade) {
      setCurrentSubGrade(selectedSubAndGrade);
      dispatch(
        getAllObjectives(
          selectedSubAndGrade.grade_id,
          selectedSubAndGrade.subject_id,
          OrderByColumn,
          sortBy
        )
      );
    }
  }, [selectedSubAndGrade, OrderByColumn, sortBy]); //, objectives.objectiveFlag

  useEffect(() => {
    //obj updated successfully
    if (data && !objectives.objectiveFlag) {
      objectives &&
        objectives?.allObjectives &&
        objectives?.allObjectives.map((obj: any) => {
          if (obj.id === currentItem.id) {
            obj.objective_number = data.objective_number;
            obj.name = data.name;
            obj.description = data.description;
          }

          return obj;
        });
      setData(null);
    } else {
      if (selectedSubAndGrade) {
        setCurrentSubGrade(selectedSubAndGrade);
        dispatch(
          getAllObjectives(
            selectedSubAndGrade.grade_id,
            selectedSubAndGrade.subject_id,
            OrderByColumn,
            sortBy
          )
        );
      }
    }
  }, [data, objectives.objectiveFlag]);

  const onAddObjective = (data: any) => {
    let finalData = {
      grade_id: currentSubGrade.grade_id,
      subject_id: currentSubGrade.subject_id,
      ...data,
    };

    dispatch(addObjective(finalData, setAddModal));
  };

  const onDeleteObjective = () => {
    dispatch(deleteObjective(currentItem.id, setDeleteModal));
  };

  const onDeleteFormative = () => {
    dispatch(deleteObjective(currentItem.id, setDeleteFormativeModal, true));
  };

  const onUpdateObjective = (data: any) => {
    dispatch(updateObjective(currentItem?.id, data, onUpdateSuccess));
  };

  const onUpdateSuccess = (flag: boolean, data: any) => {
    if (data.check_formative) {
      setCreateFormativeModal(false);
      setCreateFormativeHeader('');
    } else {
      setData(data);
      setUpdateModal(flag);
    }
  
    setCurrentItem(null);
  };

  const onClickPowerStandard = async (item: any) => {
    setCurrentItem(item);
    dispatch({
      type: ActionType.GET_OBJECTIVES_SUCCESS,
      payload: objectives.allObjectives.map((obj: any) => obj.id === item.id ? ({...obj, power_standard: !obj.power_standard}) : obj)
    });
    dispatch(updateObjective(item?.id, {power_standard: !item.power_standard}, () => {}, false));
    
  }
  
  const skeletonLoad = objectives.allObjectivesLoading;
  const filteredObjectives = objectives.allObjectives?.filter((item: any) => item.objective.toLowerCase().includes(search.toLowerCase())) || [];

  return (
    <>
      <div className="accordion__header open cursor-default">
        <div className="accordion__header-heading">
          <BackButton 
            onClick={() => {
              dispatch(resetObjectives());
              navigate(-1);
            }}
            onKeyDown={() => {
              dispatch(resetObjectives());
              navigate(-1);
            }}
          />
          <h6>Manage Objectives</h6>
        </div>
        <div>
          <div className="accordion__header-buttons">
            <button
              onClick={() => setAddModal(true)}
              disabled={!selectedSubAndGrade}
              className={`btn outline-btn outline-btn-lg ${
                !selectedSubAndGrade ? "disabled" : ""
              }`}
            >
              Add Objective
            </button>
          </div>
        </div>
      </div>
      <div className="accordion__content">
        <div className="objectives__filter">
          <DropdownWithLabel
            isSearchable
            containerStyle="dropdownsCommon-width250"
            label="Subject and Grade"
            options={subAndGradeList || []}
            selectStyle="admin-selects__marginTop0"
            placeHolder="Subject and Grade"
            onSelect={(option) => {
              option && setSelectedSubAndGrade(option);
            }}
            open={openDropdown}
            setOpen={setOpenDropdown}
            onChangeHandler={(option) => {
              setOpenDropdown(undefined)
              setSelectedSubAndGrade(option);
            }}
            noOptionsMessage={"No Subject and Grade Found"}
            value={selectedSubAndGrade}
          ></DropdownWithLabel>
          <div className="searchSection">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
              <path d="M2.1174 2.77292C3.48395 1.43179 5.3222 0.68045 7.2369 0.68045C9.15161 0.68045 10.9899 1.43179 12.3564 2.77292C13.6522 4.04397 14.4099 5.76451 14.4726 7.57857C14.5353 9.39263 13.8983 11.1614 12.6934 12.5189L16.7144 16.4859C16.7603 16.5309 16.7968 16.5846 16.8217 16.6438C16.8466 16.703 16.8594 16.7667 16.8594 16.8309C16.8594 16.8952 16.8466 16.9588 16.8217 17.018C16.7968 17.0773 16.7603 17.1309 16.7144 17.1759C16.6209 17.2675 16.4953 17.3188 16.3644 17.3188C16.2335 17.3188 16.1079 17.2675 16.0144 17.1759L11.9944 13.2089C10.605 14.3994 8.81974 15.0256 6.99112 14.964C5.1625 14.9023 3.42346 14.1573 2.1174 12.8759C1.44641 12.2172 0.913431 11.4313 0.549597 10.5642C0.185762 9.69713 -0.00162888 8.76624 -0.00162888 7.82592C-0.00162888 6.8856 0.185762 5.95471 0.549597 5.08764C0.913431 4.22056 1.44641 3.43468 2.1174 2.77592V2.77292ZM2.8174 12.1859C3.99819 13.342 5.58491 13.9894 7.2374 13.9894C8.8899 13.9894 10.4766 13.342 11.6574 12.1859C12.2362 11.6166 12.6958 10.9379 13.0096 10.1891C13.3234 9.44042 13.485 8.63673 13.485 7.82492C13.485 7.01311 13.3234 6.20942 13.0096 5.46069C12.6958 4.71197 12.2362 4.03319 11.6574 3.46392C10.4766 2.30785 8.8899 1.66042 7.2374 1.66042C5.58491 1.66042 3.99819 2.30785 2.8174 3.46392C2.23873 4.03311 1.77911 4.71177 1.46535 5.46037C1.15159 6.20896 0.989955 7.01252 0.989861 7.82421C0.989768 8.6359 1.15122 9.43949 1.46481 10.1882C1.7784 10.9368 2.23786 11.6156 2.8164 12.1849L2.8174 12.1859Z" fill="white"/>
            </svg>
            <input onChange={e => setSearch(e.target.value)} type="search" placeholder="Search Schedule" />
          </div>
        </div>

        <div className="management-table-container">
          <div className="table-responsive">
            {objectives?.allObjectivesError ? (
              <Alert variant="danger">
                <p>{JSON.stringify(objectives?.allObjectivesError)}</p>
              </Alert>
            ) : (
              ""
            )}
            {objectives.allObjectives || skeletonLoad ? (
              <table className="management__table mt-0">
                <thead>
                  <tr className="objectives__table-row">
                    <th>
                      PS
                    </th>
                    <th
                      tabIndex={0}
                      role="button"
                      className="text-right objectives__objective"

                      onKeyDown={(e) => {
                        setSortBy("name");
                        if (OrderByColumn === "DESC" && sortBy === "name") {
                          setOrderBy("ASC");
                        } else if (
                          OrderByColumn === "ASC" &&
                          sortBy === "name"
                        ) {
                          setOrderBy("DESC");
                        }
                      }}
                      onClick={() => {
                        setSortBy("name");
                        if (OrderByColumn === "DESC" && sortBy === "name") {
                          setOrderBy("ASC");
                        } else if (
                          OrderByColumn === "ASC" &&
                          sortBy === "name"
                        ) {
                          setOrderBy("DESC");
                        }
                      }}
                    >
                      Objective
                      <img
                        src={ArrowDownImg}
                        alt="sort icon"
                        className={`${
                          OrderByColumn === "ASC"
                            ? "arrow-up-classname"
                            : "arrow-down-classname"
                        }`}
                      />
                    </th>
                    <th className="objectives__description">
                    <TableSelectCommon
                        isOpen={showDropDown}
                        toggleSelect={() => setShowDropDown(!showDropDown)}
                        options={
                          dropDownLabel === "EN Description"
                            ? [{ label: "ES Description", value: "ES Description" }]
                            : [{ label: "EN Description", value: "EN Description" }]
                        }
                        onChange={(value: string) => {
                            setDropDownLabel(value)
                            setShowDropDown(false)
                          }
                        }
                        name={dropDownLabel}
                    />
                    </th>
                    <th className="objectives__summative">
                      Has <br /> Formative/ <br /> Summative
                    </th>
                    <th className="objectives__options">Options</th>
                  </tr>
                </thead>
                <tbody>
                  {skeletonLoad ? (
                    <TableSkeleton baseColor="#2a539143" highlightColor="#5371b732" columns={5} count={10} height={36} />
                  ) : (
                    ""
                  )}
                  {filteredObjectives.length > 0 && !skeletonLoad ? (
                    filteredObjectives.map((item: any, i: number) => {
                      const pdfCount = fileKeys.reduce((fileCount, key) => {
                        if(key !== 'power_standard' && item[key]){
                          fileCount++;
                        }
                        return fileCount
                      },0)
                      return (
                        <tr
                          key={i}
                          className="management__table-row"
                        >
                          <td>
                            <div tabIndex={0} role="button" 
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  onClickPowerStandard(item)
                                }
                            }}
                            
                            onClick={() => onClickPowerStandard(item)} className={`cursor-pointer ${item.power_standard ? '' : 'ps-disabled'}`}>
                              {item.power_standard ? (
                                <ToolTip top={-30} content="This objective is a Power Standard">
                                  <PowerStandard />
                                </ToolTip>
                              ):(
                                <PowerStandard />
                              )}
                              
                            </div>
                          </td>
                          <td
                            className={`objectives__objective-data`}
                          >
                            <span className="objectives__objective-data-name text-right">
                              {item?.objective_number?.trim()} - {item?.name}
                            </span>
                            {/* {item.alignment_label && (
                              <p
                              >
                                <span
                                  className="objectives__objective-data-highlight"
                                >
                                  {item.alignment_label}
                                </span>
                              </p>
                            )} */}
                          </td>
                          <td className="objectives__description-data">
                            { dropDownLabel === "EN Description" ? item?.description : item?.description_es}
                          </td>
                          <td className="objectives__summative-data fw-bold">
                            {item.has_formative ? (
                              <span 
                              tabIndex={0}
                              role="button"
                                className="objectives__summative__formative_click" 
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    setCurrentItem(item); 
                                    setDeleteFormativeModal(true);
                                  }}}
                                  
                                onClick={() => { 
                                  setCurrentItem(item); 
                                  setDeleteFormativeModal(true);
                                }}
                              >
                                Y
                              </span>
                            ) : (
                              <span 
                                className="objectives__summative__formative_click" 
                                  tabIndex={0}
                              role="button"
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    setCurrentItem(item);
                                    setCreateFormativeHeader(item?.objective_number?.trim() || ''); 
                                    setCreateFormativeModal(true)
                                  }}}
                                onClick={() => { 
                                  setCurrentItem(item);
                                  setCreateFormativeHeader(item?.objective_number?.trim() || ''); 
                                  setCreateFormativeModal(true)}
                                }
                              >
                                N
                              </span>
                            )} /{" "}
                            {item?.has_summative ? "Y" : "N"}
                          </td>
                          <td className="objectives__options-data">
                            <div>
                              <button
                               tabIndex={0}
                               type="button"
                               onKeyDown={(e: any) => {
                                if (e.key === "Enter") {
                                  setUpdateModal(true);
                                  setCurrentItem(item);
                                }
                              }}
                                onClick={() => {
                                  setUpdateModal(true);
                                  setCurrentItem(item);
                                }}
                                className="btn management__table-editBtn"
                              >
                                <img
                                  src={EditIcon}
                                  alt="Edit"
                                />
                              </button>
                              <button 
                               tabIndex={0}
                               type="button"
                               onKeyDown={(e: any) => {
                                if (e.key === "Enter") {
                                  setDeleteModal(true);
                                  setCurrentItem(item);
                                }
                              }}
                              className="btn management__table-editBtn">
                                <img
                                  onClick={() => {
                                    setDeleteModal(true);
                                    setCurrentItem(item);
                                  }}
                                  src={DeleteIcon}
                                  alt="Delete"
                                />
                              </button>
                              <button 
                                onClick={() => {
                                  setOpenFilesModal(true);
                                  setCurrentItem(item);
                                  
                                }}
                                type="button" 
                                tabIndex={0}
                                onKeyDown={(e: any) => {
                                  if (e.key === "Enter") {
                                    setOpenFilesModal(true);
                                  setCurrentItem(item);
                                  }
                                }}
                                className="btn management__table-editBtn fileBtn" 
                                style={{display: "inline-flex", alignItems: "center", gap: "8px"}}
                              >
                                <PdfIcon />
                                <span>{pdfCount}</span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      {!skeletonLoad && (
                        <td colSpan={5} className="no-admin">
                          No Objectives
                        </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <div className="objectives__empty">
                {!skeletonLoad && (
                  <div className="objectives__empty-content">
                    <p className="objectives__empty-paragraph">
                      Select Subject and Grade to view objectives
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {(addModal || updateModal || openFilesModal) && (
        <div 
          className="modalBackdrop" 
          onClick={() => {setAddModal(false); setUpdateModal(false); setOpenFilesModal(false)}}
        ></div>
      )}
      {addModal && (
        <AddObjectiveModal
          isShow={addModal}
          loading={objectives.addObjectiveLoading}
          onAdd={(data) => onAddObjective(data)}
          onCancel={() => setAddModal(false)}
        />
      )}
      {currentItem && updateModal && (
        <UpdateObjectiveModal
          isShow={updateModal}
          loading={objectives.updateObjectiveLoading}
          onUpdate={(data) => onUpdateObjective(data)}
          onCancel={() => {
            setCurrentItem(null);
            setUpdateModal(false);
          }}
          currentObjective={currentItem}
        />
      )}
      {openFilesModal && currentItem && (
        <ObjectiveFilesModal 
          currentObjective={currentItem}
          isShow
          onCancel={() => {
            setCurrentItem(null);
            setOpenFilesModal(false);
          }}
          onClickEdit={() => {
            setOpenFilesModal(false);
            setUpdateModal(true);
          }}
        />
      )}
      {deleteModal &&
        <DeleteObjectiveModal
            loading={objectives.deleteObjectiveLoading}
            isShow={deleteModal}
            headerText={`Delete Objective`}
            bodyText={`Are you sure you want to delete this objective?`}
            onCancel={() => setDeleteModal(false)}
            onDelete={onDeleteObjective}
        />
     }
     {createFormativeModal &&
        <CreateFormativeModal
            loading={objectives.deleteObjectiveLoading}
            isShow={createFormativeModal}
            headerText={`Create Formative for ${createFormativeModalHeader}?`}
            bodyText={`If you create a formative for this objective, you will need to create assessment content using the CMS.`}
            onCancel={() => { setCurrentItem(null);setCreateFormativeHeader('');setCreateFormativeModal(false);}}
            onConfirm={() => {onUpdateObjective({check_formative: true});}}
        />
     }
    {deleteFormativeModal &&
        <DeleteFormativeOnlyModal
            loading={objectives.deleteObjectiveLoading}
            isShow={deleteFormativeModal}
            headerText={`Delete Formative?`}
            bodyText={`Are you sure you want to delete this formative?`}
            onCancel={() => { setCurrentItem(null);setDeleteFormativeModal(false);}}
            onDelete={onDeleteFormative}
        />
     }
    </>
  );
};

export default EditObjectives;
