/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from "react"
import Collapsible from "react-collapsible"
import ChevronIcon from "assets/img/back-arrow.png";
import moment from "moment";


interface Props {
    student: any;
    index: number;
    questionId: number;
    expandedStudents: any
    onClickExpand: Function,
    dropDownLabel: string;
    setIsolatedStudent: Function;
}

const AnswerMap = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D'
}

const Student = ({ student, index, questionId, expandedStudents, onClickExpand, dropDownLabel, setIsolatedStudent }: Props) => {
    const isHideRow = student.isShowIsolated === false; // dont use not sign (!) with student.isShowIsolated

    const studentContent = useMemo(() => {
        const { attempts } = student;

        let records = attempts.map((attempt: any, attemptIndex: number) => {
            const { answers, calculation, test_date } = attempt;

            return <tr className={index % 2 !== 0 ? "dark-gray" : ''}>
                <td onClick={() => setIsolatedStudent(student)} className="report__itemTable-name report__data-height radius">
                    {!attemptIndex ? <span className="ellipsis-animated">
                        <p className={`cursor-pointer ellipsis-animated-text ${student.student_name.length > 16 ? 'animated ellipsis-animation' : ''} ${student.isShowIsolated ? 'font-bold' : isHideRow ? 'objective-isolation' : ''}`}>{student.student_name}</p>
                    </span>
                        : ''
                    }
                </td>
                <div className="d-flex">
                    {answers
                        .map((s: any, i: number) => {

                            if (s.answer) {
                                return <td className="report__itemTable-numbers report__data-height" key={i}>
                                    <span className={`${questionId ? (s.question_id !== questionId || isHideRow) ? 'visibility-hidden' : '' : isHideRow ? 'visibility-hidden' : ''} ${s.correct ? 'high' : 'low'} report__itemTable-rectangle`}>
                                        {!s.correct && AnswerMap[s.answer.trim() as keyof typeof AnswerMap]}
                                    </span>
                                </td>
                            }
                            return <td className="report__itemTable-numbers  report__data-height" key={i}>
                                <span className={`${questionId ? (isHideRow || s.question_id !== questionId) ? 'visibility-hidden' : '' : isHideRow ? 'visibility-hidden' : ''} report__itemTable-rectangle unattempted`}>

                                </span>
                            </td>
                        })
                    }
                </div>
                <td className="report__itemTable-attempt formative-attempt report__data-height  ">
                    {/*  */}
                    <div className={`d-flex align-items-center justify-content-center position-relative`}>
                        <span className={`${isHideRow ? 'visibility-hidden' : ''}`}>{
                            dropDownLabel === "Attempt" ? `${calculation?.attempt}` : (test_date && test_date.length && moment(test_date).format('M-D-YY')) || ''
                        }</span>
                        {attempts.length > 1 && <div style={{ display: 'inline-block', position:'absolute', right: `${dropDownLabel === 'Date' ? '-20px' :'0px'}` }} className="irr-summative-collapse-icon">{
                            <span onClick={() => onClickExpand(student.student_id)}>
                                {!attemptIndex ? <img
                                    src={ChevronIcon}
                                    className={`${expandedStudents[student.student_id] ? 'rotate' : 'rotate-90'} `}
                                    alt="arrow"
                                /> : ''}
                            </span>}
                        </div>
                        }
                    </div>
                </td>

                <td className={`${isHideRow ? 'hidden' : ''} report__itemTable-score score-align report__data-height radius`}>
                    <span>{`(${calculation.total_correct || 0}) `}</span>
                    <span>
                        {`${calculation.percentage || 0}%`}
                    </span>
                </td>

            </tr >
        })

        if (records.length > 1) {
            return <Collapsible
                tabIndex={1}
                handleTriggerClick={() => { }}
                open={!!expandedStudents[student.student_id]}
                trigger={records[0]}>
                {records.slice(1)}
            </Collapsible>
        } else {
            return <div > {records[0]} </div>
        }

    }, [isHideRow, student, index, expandedStudents, dropDownLabel, questionId])

    return (<>{studentContent}</>
    )
}

export default Student