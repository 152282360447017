import { FC, useContext, useEffect, useState } from "react";
// import ChatIcon from "assets/img/new-chat-icon.png";
// import ChatIconRed from "assets/img/chat-support-strip-red.png";
// import ChatIconGrey from 'assets/img/chat-support-strip-gray.png';
import ChatBox from "./content/ChatBox";
import AdminChatBox from "./content/AdminChatBox";
import ChatBoxProvider from "contexts/chatBox";
import SuperAdminChatboxProvider from "contexts/superAdminChatbox";
import Cookie from "js-cookie";
import { ChatSocketContext } from "contexts/socketContext";
import { getActiveChats } from "services/supportTicketSuperAdmin";
import { getSupportChats } from "services/suppot-ticket";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const SupportChatIcon: FC<any> = () => {
  const { chatNotification, setChatNotification, currentStatus, setCurrentStatus, chatResolved, chatAssigned, emitSuperAdminChatSubscribe } =
  useContext(ChatSocketContext);
  const {tooltip} = useContext(ClassroomTooltipContext);
  const [showChatBox, setShowChatBox] = useState<boolean>(false);
  const isSuperAdmin = Cookie.get("role") === "Super Admin";
  const [active, setActive] = useState<any>(null!);
  const [isActiveChat, setIsActiveChat] = useState(null!);

  const { unread_message, new_chats } = chatNotification || {};
  const {pathname} = useLocation();

  const onCloseChatBox = () => {
    setShowChatBox(false);
    const html = document.querySelector("html");
    if(html){
      html.style.overflow = ""
    }
  }

  const Component = isSuperAdmin ? (
    <SuperAdminChatboxProvider>
      <AdminChatBox onClose={onCloseChatBox} />
    </SuperAdminChatboxProvider>
  ) : (
    <ChatBoxProvider>
      <ChatBox onClose={onCloseChatBox} />
    </ChatBoxProvider>
  );

  const onClickChatIcon = () => {
    setShowChatBox(true);
    setChatNotification((prev: any) => {
      if(prev && !prev?.assigned){
        const {unread_message, ...otherData} = prev;

        return {
          ...otherData
        }
      }
      return null!;
    });
    setActive(null!);

    document.body.style.overflow = 'hidden';
    const html = document.querySelector("html");
    if(html){
      html.style.overflow = "hidden"
    }

  };

  const fetchSuperAdminStatus = async () => {
    const activeChats = await getActiveChats(false);
    if(!activeChats){
      return;
    }

    const status = activeChats.chat_statuses.find((item: any) => item.status.toLowerCase() === activeChats.status.toLowerCase());
    status?.id && setCurrentStatus(status.id);

    const isactive = activeChats.chats.some((item: any) => !!item.respondent);
    setIsActiveChat(isactive);
  }

  const fetchAdminStatus = async () => {
    const chat = await getSupportChats(10, 0);

    if(!chat){
      return;
    }

    (chat.messages?.length || 0) > 0 && emitSuperAdminChatSubscribe(chat.thread_id);
    setCurrentStatus(chat.status_id || 0);
  }

  useEffect(() => {
    if(isActiveChat === null){
      return;
    }

    const handleTabClose = (event: any) => {
      event.preventDefault();

      console.log('beforeunload event triggered');

      return (event.returnValue =
      'My own message?');
      // return 'My own message!'
    };

    const addBeforeUnloadFunction = async () => {
      if((isActiveChat || chatAssigned) && isSuperAdmin && !chatResolved){
        window.addEventListener("beforeunload", handleTabClose);
      }else{
        window.removeEventListener("beforeunload", handleTabClose);
      } 
    }
    
    addBeforeUnloadFunction();

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    }
  },[isActiveChat, chatResolved, isSuperAdmin, chatAssigned])

  useEffect(() => {
    const token = Cookies.get("super-admin-token") || Cookies.get("token");
    if(!token){
      return;
    }
    isSuperAdmin ? fetchSuperAdminStatus() : fetchAdminStatus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (!chatNotification || (!chatNotification.unread_message && !chatNotification.new_chats)) {
      // Set this to null so in the case when some other admin has claimed the chat, we can return back to original shape and color of chat icon and also stopping the blinking.
      setActive(null);
      return;
    }

    let intervalId: any;
    intervalId = setInterval(() => {
      setActive((prev: any) => {
        const newState = {
          ...prev,
          color: "#C44538",
          activeState: ((prev?.activeState || 0) + 1) % 2,
        };

        if (newState.activeState) {
          newState.color = "#979797";
        }

        if (!newState.activeState && !!unread_message && prev.newChat) {
          newState.newChat = false;
        }
        if (!newState.activeState && !!new_chats && !prev.newChat) {
          newState.newChat = true;
        }

        if (newState.activeState && !!new_chats && !prev) {
          newState.newChat = true;
        }

        return newState;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatNotification]);
  
  const isTransparent = (pathname === '/activity' || pathname === '/pacing-calendar') && (tooltip === 1 || tooltip === 2 || tooltip === 3 || tooltip === 4 || tooltip === 5 || tooltip === 6 || tooltip === 7)
  
  return (
    <div className="chatIcon-container">
      {showChatBox ? (
        <>
          {Component}
          <div className="modalBackdrop"></div>
        </>
      ):
        <div
        role="button"
        tabIndex={0}
          onClick={() => !isTransparent && onClickChatIcon()}
          className={`SupportCenter__ChatImage cursor-pointer ${isTransparent ? 'toolTip-bg toolTip-bg-transparent transparent-fix' : ''}`}
        > 
          {/* <span className={`${currentStatus === 1 ? 'active' : (currentStatus === 2 || currentStatus === 3) ? 'yellow' : 'inactive'} chatDot`}></span> */}
          {/* {status === 1 ? <span></span> : (status === 2 || status === 3) ? <p className="yellowCircle"></p> : <p className="redCircle"></p>} */}

            {true ? (
              // <img src={ChatIcon} alt="chat" />
              <button style={{backgroundColor: active?.color || ''}} type="button" className="chat-box-btn btn">
                {active === null && (
                  <span className={`${currentStatus === 1 ? 'active' : (currentStatus === 2 || currentStatus === 3) ? 'yellow' : 'inactive'} chatDot`}></span>
                )}
                <span className="chat-btn-text">Help Chat</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13" fill="none">
                  <path d="M17.1146 12.3008L19.6719 9.74266L10.23 0.300781L0.787228 9.74266L3.34446 12.2999L10.23 5.41614L17.1146 12.3008Z" fill="white"/>
                </svg>
              </button>
              
            ) : (
              <></>
              // <div style={{...(isTransparent && {top: 0})}} className="request">
              //   {active.newChat && !!new_chats ? (
              //     <>
              //       <img src={active.color === '#C44538' ? ChatIconRed : ChatIconGrey} alt="chat" />
              //       <span className="req-number">{new_chats}</span>
              //       <span className="req-text">Req</span>
              //     </>
              //   ):(
              //     <>
              //       <img src={active.color === '#C44538' ? ChatIconRed : ChatIconGrey} alt="chat" />
              //       <span className="req-number">{unread_message}</span>
              //       <span className="req-text">New</span>
              //     </>
              //   )}
              // </div>
            )}
          
        </div>
      }

      {/* {tooltip === 5 && <ClassroomTooltip message="Have a question? <br /> Click here to ask Alpha Plus." className="question-tooltip" arrowDirection="bottom" />} */}
      
    </div>
  );
};
export default SupportChatIcon;
