/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// @ts-nocheck

import React, { useRef, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
// import SearchIcon from "assets/img/search-icon.svg";
import Warning from "assets/img/warning-icon.png";
import {
  getActivityPDProgress,
  getActivityProgress,
  getActivityProgressExport,
  setActivityReportIndicator,
} from "redux/actionCreators/activity";
import Pagination from "components/pagination/pagination";
import {
  TableSkeleton,
} from "components/common/skeleton/TableSkeleton";
import { ProgressLimitSelect } from "components/common/selectComponent";
import { TableSelectCommon } from "components/common/selectComponent";
import { setFilterValues } from "redux/actionCreators/classroom";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDownImg from "assets/img/table-class-before.svg";
import moment from "moment-timezone";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import ClassroomTooltip from "components/common/classroomTooltip";
import Tabs from "components/common/Tabs/Tabs";
import ActivityPDProgress from "./activity-pd-progress";
import { ACTIVITY_TABS } from "pages/activity";
import FormativeProgressChart from "./formativeProgressChart";
import FormativeProgressChartPrint from "./formativeProgressPrint";
import { useReactToPrint } from "react-to-print";
import momentDate from "moment";
import SuccessModal from "components/common/successModal";
import { getFeedProgressData } from "services/common";
import { ProgressbarCore } from "components/theme/activityBar/progressBar";
import ActivitySkeleton from "./activity-table-skeleton";
import { FORMATIVE_PROFICIENY } from "util/constants";
import ClassroomName from "./classroomName";
import SearchIcon from "components/common/svgs/search-icon";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import { getLegendPadding } from "util/index";
import useSortBy from "hooks/useSortBy";


interface Props {
  currentTab: string;
  setCurrentTab: Function;
}

interface RootState {
  auth: any;
  activity: any;
  classroom: any;
}

const options = [
  { value: "Master Report", label: "Master Report" },
  { value: "OAG", label: "OAG" },
  { value: "To Classroom", label: "To Classroom" },
  { value: "Manage", label: "Manage" },
];

const sortByOptions = [
  { value: "last_test_time", label: "Last Assessment" },
  { value: "objectives", label: "Objectives" },
  { value: "name", label: "Class Name" },
  { value: "proficiency", label: "Proficiency" },
];

const CHART_PAGINATION_LIMIT = 11;

const ActivityProgress: React.FC<Props> = ({currentTab, setCurrentTab}) => {
  const componentRef = useRef(null);

  const {tooltip} = useContext(ClassroomTooltipContext);
  const {pathname} = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const app = useSelector((state: RootState) => state);
  const [classrooms, setClassrooms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [schoolId, setSchoolId] = useState(0);
  const [gradeId, setGradeId] = useState(0);
  const [teacherId, setTeacherId] = useState(0);
  const [districtId, setDistrictId] = useState(0);
  const [subjectId, setSubjectId] = useState(0);
  const [schoolIds, setSchoolIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [chartData, setChartData] = useState<any>(null);
  const [printData, setPrintData] = useState<any>([]);
  const [printLoading, setPrintLoading] = useState(false);

  // const [sortBy, setSortBy] = useState("last_test_time");
  // const [OrderByColumn, setOrderBy] = useState("DESC");

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(null);
  const [chartPagination, setChartPagination] = useState({
    offset: 0,
    search: ''
  });
  const [chartSortBy, setChartSortBy] = useState("");
  const paginationCount = currentTab === ACTIVITY_TABS.Chart ? chartData?.count : count;
  const paginationCurrentPage = currentTab === ACTIVITY_TABS.Chart ? Math.ceil((chartPagination.offset || 0)/CHART_PAGINATION_LIMIT)+1 : currentPage;
  const paginationLimit = currentTab === ACTIVITY_TABS.Chart ? CHART_PAGINATION_LIMIT : limit;

  const [animatedClassName, setAnimatedClassname] =
    useState(false);

  const [isExportBtnDisabled, setIsExportBtnDisabled] =
    useState(false);

  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [skeletonCount, setSkeletonCount] = useState(10);
  const [selectedId, setSelectedId] = useState(0);
  const [open, setOpen] = useState(false);
  const [assessmentType, setAssessmentType] = useState('Formative');

  const {sortBy, OrderByColumn, sortDataBy}=useSortBy("last_test_time", "DESC", "activity_progress");

  useEffect(() => {
    if (
      app.activity.type === "SET_FILTERS" &&
      app.activity.applyFilters === true
    ) {
      setIsLoading(true);
      setSchoolId(parseInt(app.activity.school_id));
      setGradeId(parseInt(app.activity.grade_id));
      setTeacherId(parseInt(app.activity.teacher_id));
      setDistrictId(parseInt(app.activity.district_id));
      setSubjectId(parseInt(app.activity.subject_id));
      let data = {
        offset,
        limit,
        sort_by: sortBy,
        order_by: OrderByColumn,
      };
      data.district_id = parseInt(app.activity.district_id);
      data.school_id = parseInt(app.activity.school_id);
      data.teacher_id = parseInt(app.activity.teacher_id);
      data.grade_id = parseInt(app.activity.grade_id);
      data.subject_id = parseInt(app.activity.subject_id);
      data.classroom_id = parseInt(
        app.activity.classroom_id
      );
      if (currentPage > 1) onPageChange(1);
      else dispatch(getActivityProgress({ ...data }));
    } else if (
      app.activity.type === "GET_ACTIVITY_PROGRESS"
    ) {
      if (app.activity.payload?.data?.data?.count > 0) {
        setClassrooms(
          app.activity.payload.data.data
            .activity_progress_info.classrooms
        );
        setChartData(app.activity.payload.data.data);
        const school_ids =
          app.activity.payload.data.data.activity_progress_info.classrooms.map(
            (x: any) => x.school_id
          );
        setSchoolIds(school_ids);
        setCount(app.activity.payload.data.data.count);
      } else {
        setClassrooms([]);
        setCount(0);
      }
      setIsLoading(false);
    } else if (
      app.activity.type === "GET_ACTIVITY_PROGRESS_FAIL"
    ) {
      setIsLoading(false);
      setError(true);
      setMessage("No formatives found");
    }
  }, [app.activity]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimatedClassname(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimatedClassname(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [animatedClassName]);

  const onPageChange = (pageNo: number) => {
    if(ACTIVITY_TABS.Chart === currentTab){
      setChartPagination(prev => ({
        ...prev,
        offset: (pageNo-1) * CHART_PAGINATION_LIMIT
      }));
      fetchChartData({offset: (pageNo-1) * CHART_PAGINATION_LIMIT})
    }else{
      const newOffset = limit * pageNo - limit;
      setCurrentPage(pageNo);
      setOffset(newOffset);
    }
  };

  useEffect(() => {
    let delayDebounceFn = null;
    if(searchTerm !== null){
      if((currentTab === ACTIVITY_TABS.Assessments || currentTab === ACTIVITY_TABS.Chart)){
        delayDebounceFn = setTimeout(() => {
          setCurrentPage(1);
          setOffset(0)
          getFeedProgress(10, 0);
        }, 400);
      }else{
        delayDebounceFn = setTimeout(() => {
          setCurrentPage(1);
          setOffset(0);
        }, 400);
      }
    }

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const ExportActivityProgress = async () => {
    dispatch(setActivityReportIndicator(true, false));
    localStorage.setItem("active", "true");
    setIsExportBtnDisabled(true);
    const exportProgress = await getActivityProgressExport({
      search: searchTerm,
      offset,
      limit,
      district_id: districtId,
      school_id: schoolId,
      grade_id: gradeId,
      teacher_id: teacherId,
      subject_id: subjectId,
    });

    // const link = document.createElement("a");
    // link.target = "_blank";
    // link.href = exportProgress.data.data.link;

    const downloadLink = exportProgress?.data?.data?.link;

    dispatch(
      setActivityReportIndicator(true, true, downloadLink)
    );
    setIsExportBtnDisabled(false);
    localStorage.setItem("active", "true");
    localStorage.setItem("completed", "true");
    localStorage.setItem("link", downloadLink);
  };

  const getFeedProgress = (
    limit: number = 10,
    newOffset?: number
  ) => {
    setIsLoading(true);
    dispatch(
      getActivityProgress({
        search: searchTerm,
        offset: newOffset ?? offset,
        limit,
        district_id: districtId,
        school_id: schoolId,
        grade_id: gradeId,
        teacher_id: teacherId,
        sort_by: sortBy,
        subject_id: subjectId,
        order_by: OrderByColumn,
      })
    );
  };

  const checkDuplicates = (a: any) => {
    return new Set(a).size !== 1;
  };

  useEffect(() => {
    if (count !== 0 && (currentTab === ACTIVITY_TABS.Assessments || currentTab === ACTIVITY_TABS.Chart)) {
      getFeedProgress(Number(limit));
    }
  }, [limit, offset, OrderByColumn, sortBy]);

  const calculateHeight = (
    classrooms,
    currentSchoolName
  ) => {
    let total = classrooms.length;
    let count = 0;
    classrooms.map((eachClass) => {
      if (eachClass.school_name === currentSchoolName) {
        count += 1;
      }
    });
    let height = Math.abs((count / total) * 100);
    return { height: height, rows: count };
  };

  const setClassroomFilters = (x) => {
    const { selectedOption } = x;
    sessionStorage.setItem(
      "c_school_id",
      JSON.stringify(x.school_id)
    );
    sessionStorage.setItem(
      "c_grade_id",
      JSON.stringify(x.fk_grade_id)
    );
    sessionStorage.setItem(
      "c_subject_id",
      JSON.stringify(x.fk_subject_id)
    );
    sessionStorage.setItem(
      "c_district_id",
      JSON.stringify(x.district_id)
    );
    sessionStorage.setItem(
      "c_classroom_id",
      JSON.stringify(x.classroom_id)
    );
    dispatch(
      setFilterValues({
        filters: x,
        classroomSchoolId: x.school_id,
        classroomId: x.classroom_id,
        subject: x.fk_subject_id,
        grade: x.fk_grade_id,
      })
    );

    if (selectedOption === "OAG")
      navigate(
        `/classroom/report/oag-report/${x.classroom_id}`,
        {state: {classroomId: x.classroom_id}}
      );
    else if (selectedOption === "Master Report")
      navigate(
        `/classroom/report/master-report/${x.classroom_id}`,
        {state: {classroomId: x.classroom_id}}
      );
    else if (selectedOption === "Manage")
      navigate(
        `/management/class/${x.classroom_id}?schoolId=${x?.school_id}`
      );
    else navigate("/classroom");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSkeletonLoad(false);
    }, 800);

    // if(count < limit) {
    //   setSkeletonCount(count);
    // }
    // if ((limit - ((currentPage * limit) - count)) < limit) {
    //   setSkeletonCount(limit - ((currentPage * limit) - count));
    // } else {
    //   setSkeletonCount(limit);
    // }

    return () => {
      clearTimeout(timer);
      setSkeletonLoad(true);
    };
  }, [isLoading]);

  useEffect(() => {
    if (
      currentPage > 1 &&
      limit - (currentPage * limit - count) < limit
    ) {
      setSkeletonCount(
        limit - (currentPage * limit - count)
      );
    } else {
      setSkeletonCount(limit);
    }
  }, [currentPage, limit]);

  // const tableBodayHeight = ((skeletonCount * 60) + 31) + 'px';
  const schoolID = Number(
    sessionStorage.getItem("school_id") || "0"
  );

  const fetchChartData = async (data: any) => {
    const sort_by = isChartTab ? chartSortBy : sortBy
    setIsLoading(true)
    const chartParams = {
      limit: CHART_PAGINATION_LIMIT,
      district_id: districtId,
      school_id: schoolId,
      grade_id: gradeId,
      teacher_id: teacherId,
      sort_by: sort_by,
      subject_id: subjectId,
      order_by: OrderByColumn,
      search: chartPagination.search,
      offset: chartPagination.offset,
      ...data
    }
    try {
        const res = await getFeedProgressData(chartParams);
        const legendPadding = getLegendPadding(res.data?.data?.activity_progress_info?.classrooms || []);
        setChartData({
          ...res.data.data,
          legendPadding
        });
    } catch (error) {
      console.log('error', error);
    }finally {
      setIsLoading(false);
    }
  }

  const onTabChange = (option: string) => {
    setCurrentTab(option);
    if(option === ACTIVITY_TABS.Assessments){
      setIsLoading(true);
      dispatch(
        getActivityProgress({
          search: searchTerm,
          offset,
          limit,
          district_id: districtId,
          school_id: schoolId,
          grade_id: gradeId,
          teacher_id: teacherId,
          sort_by: sortBy,
          subject_id: subjectId,
          order_by: OrderByColumn,
        })
      );
    }else if(option === ACTIVITY_TABS.PD){
      setIsLoading(true);
      dispatch(
        getActivityPDProgress({
          search: searchTerm,
          offset,
          limit,
          district_id: districtId,
          school_id: schoolId,
          grade_id: gradeId,
          teacher_id: teacherId,
          sort_by: 'last_test_time',
          subject_id: subjectId,
          order_by: 'DESC',
        })
      );
    }else{
      
      const sort_by = ["last_test_time", "objectives", "name", "proficiency"].indexOf(sortBy) > -1 ? sortBy : "name";
      setChartSortBy(sort_by);
      const order_by_column = ["last_test_time", "objectives", "name", "proficiency"].indexOf(sortBy) > -1 ? OrderByColumn : "ASC";
      const data = {
        limit: limit+1,
        district_id: districtId,
        school_id: schoolId,
        grade_id: gradeId,
        teacher_id: teacherId,
        sort_by: sort_by,
        subject_id: subjectId,
        order_by: order_by_column,
        offset: chartPagination.offset,
        search: chartPagination.search
      }
      fetchChartData(data);
    }
  }

  const onSearchChart = async (search: string) => {
    setChartPagination(prev => ({...prev, search}));
    const data = {
      offset: 0,
      search: search
    }
    fetchChartData(data);

  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `FormativeProgressChart_${new Date()}_${momentDate(
      new Date()
    ).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      window.close()
    }
  });

  const onClickPrint = async () => {
    setPrintLoading(true);
    const chartParams = {
      limit: 100000,
      district_id: districtId,
      school_id: schoolId,
      grade_id: gradeId,
      teacher_id: teacherId,
      sort_by: 'last_test_time',
      subject_id: subjectId,
      order_by: 'DESC',
      offset: 0
    }
    try {
      const res = await getFeedProgressData(chartParams);
      if(res.data.data?.activity_progress_info?.classrooms?.length){
        setPrintData(res.data.data?.activity_progress_info?.classrooms);
      }
    } catch (error) {
      console.log('error', error);
    }finally {
      setPrintLoading(false);
    }
    
  }

  useEffect(() => {
    if(printData?.length){
      handlePrint();
    }
  },[printData]);

  const onChangeAssessmentType = (type: string)  => {
    setAssessmentType(type);
  }

  const onChartSortByChange = (sortBy: string)=>{
    setChartSortBy(sortBy);
    const data = {
      limit:limit+1,
      district_id: districtId,
      school_id: schoolId,
      grade_id: gradeId,
      teacher_id: teacherId,
      sort_by: sortBy,
      subject_id: subjectId,
      order_by: "ASC",
      offset: chartPagination.offset,
      search: chartPagination.search
    }
    fetchChartData(data);
  }


  useEffect(() => {
    if(app.activity.school_id === 0){
      console.log('school unselected');
      currentTab === ACTIVITY_TABS.Chart && onTabChange(ACTIVITY_TABS.Assessments);
    }
  },[app.activity.school_id])

  const isBlur = pathname === '/activity' && (tooltip === 1 || tooltip === 2 || tooltip === 3 || tooltip === 4);
  const isShowTooltip = tooltip === 4 && pathname === '/activity';

  const isAssessmentsTab = currentTab === ACTIVITY_TABS.Assessments;
  const isChartTab = currentTab === ACTIVITY_TABS.Chart;
  const isShowProgressReport = schoolID && app.auth.currentUser?.role !== "Classroom Admin" && !isChartTab;
  const isChartDisabled = !schoolID || app.auth.currentUser?.role === "Classroom Admin";

  const loading = isLoading || skeletonLoad;

  const isLimitDisabled = isAssessmentsTab 
  ? classrooms && classrooms.length < 10
  : (!app.activity?.payload?.data?.data?.activity_pd_progress_info?.classrooms?.length || 
    app.activity?.payload?.data?.data?.activity_pd_progress_info?.classrooms?.length < 10)


  

const selectedSortByOption = sortByOptions.find((option: any)=>option.value === sortBy);
const defaultSorted = selectedSortByOption?.label || sortByOptions[2].label;
    
  return (
    <>
      <div className='activity__progress-wrapper text-white'>
        {isShowTooltip && (
            <ClassroomTooltip 
              message="Have a question? <br /> Click here to ask Alpha Plus." 
              className="question-tooltip chat-tooltip-position" 
              arrowDirection="bottom" 
            />
          )}
        <div style={{...(isBlur && {position: 'relative'})}} className='section'>
          
          {isBlur && (
            <div className="toolTip-bg"></div>
          )}
          <div className={` ${isShowProgressReport ? "showProgressReportButton" : ""} section-header sm-column position-relative`}>
            <div className='section-header-title'>
              <h3>Progress</h3>
            </div>
            {app?.auth?.colorProfile?.pd_enabled && (
              <div className="activity-progress-tab">
                <Tabs
                  options={['Assessments', 'Chart', 'PD']}
                  currentTab={currentTab}
                  onChangeTab={onTabChange}
                  customClass='custom-padding'
                  disabledTabs={isChartDisabled ? ['Chart'] : []}
                  onClickDisabledTab={() => setShowModal(true)}
                />
              </div>
            )}
            <div className='activity-progress-btns position-relative'>
              {isShowProgressReport ? (
                <button
                  type="button"
                  tabIndex={0}
                  className='btn outline-btn outline-btn-lg shl-progress-report-btn me-3'
                  onClick={() =>
                    navigate(
                      `/activity/report/formative-progress/${schoolID}`
                    )
                  }>
                  Formative Progress Report
                </button>
              ) : null}
              {isChartTab ? (
                <button
                tyoe="button"
                      tabIndex={0}
                  className='btn outline-btn'
                  onClick={onClickPrint}
                  disabled={printLoading}
                >
                  {printLoading ? 'Printing...' : 'Print'}
                </button>
              ):(
                <button
                type="button"
                      tabIndex={0}
                  className='btn outline-btn outline-btn-lg exportCSVBtn'
                  onClick={ExportActivityProgress}
                  disabled={isExportBtnDisabled}>
                  Export CSV
                </button>
              )}
            </div>
          </div>
          <div className="activity-assessments light-bg-gray">
          <div className='section-search'>
            <div className="d-flex">
              <div className='section-search-field' style={{marginRight: 14}}>
                <div className='icon'>
                  
                  <SearchIcon fill="#323233" title="Search Classes" />
                
                </div>
                <div key={currentPage}>
                  <input
                    value={isChartTab ? chartPagination.search : searchTerm}
                    type='text'
                    className='form-control'
                    placeholder="Search Classes"
                    onChange={(e: any) => {
                      setIsLoading(true);
                      const inputValue = e.target.value;
                      isChartTab 
                      ? onSearchChart(inputValue)
                      : setSearchTerm(inputValue);
                    }}
                  />
                </div>
              </div>
             
            </div>

            <div className='d-flex align-items-center show-classes-dropdown'>
              <span style={{marginRight:"14px"}} className={"d-block"}> {isChartTab ? "Order By" :"Show"}</span>
             
              { isChartTab ? 
                <TableSelectCommon
                options={sortByOptions }
                onChange = {onChartSortByChange}
                defaultSorted={defaultSorted || ""}
                
                
            />:
                <ProgressLimitSelect 
                disableHandler={isLimitDisabled}
                onChangeHandler={(e: any) =>
                  setLimit(e?.value)
                }
              />}
              <span className={""}>{isChartTab? "":"Classes"}</span>
            </div>
          </div>

          {currentTab === 'Assessments' ? (<div className='progress-table-container'>
            <div className='table-responsive'>
              <table className='progress__table'>
                <thead>
                  <tr className='progress__table-headerRow'>
                    <th
                      colSpan={3}
                      className='progress__table-headerLast'
                      onKeyDown={(e:any) => e.key === "Enter" && sortDataBy("last_test_time")}
                      onClick={() => sortDataBy("last_test_time")}>
                      <p className='text-right'>Last</p>
                    </th>
                    <th
                      colSpan={2}
                      className='progress__table-headerFormative'>
                      <p className='font-medium'>
                        Formative Data
                      </p>
                    </th>
                    <th colSpan={3}>
                      <p className='font-medium'>
                        Summative Data
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody className='progress__table-body'>
                  <tr className='progress__table-row'>
                    <td
                      className='row-data-title className cursor-pointer'
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e:any) => e.key === "Enter" && sortDataBy("name")}
                      onClick={() => sortDataBy("name")}>
                      Class Name
                      {sortBy === "name" && (
                        <SortIndicatorIcon OrderByColumn = {OrderByColumn}  />
                      )}
                    </td>
                    <td className="row-data-title hour-column">
                      Hour
                    </td>
                    <td
                      className='row-data-title assesment cursor-pointer'
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e:any) => e.key === "Enter" && sortDataBy("last_test_time")}
                      onClick={() => sortDataBy("last_test_time")}>
                      Assessment
                      {sortBy === "last_test_time" && (  
                        <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e:any) => e.key === "Enter" && sortDataBy("objectives")}
                      onClick={() => sortDataBy("objectives")}>
                      Objectives
                      {sortBy === "objectives" && (
                     <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                   
                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e:any) => e.key === "Enter" && sortDataBy("proficiency")}
                      onClick={() => sortDataBy("proficiency")}>
                      Proficiency
                      {sortBy === "proficiency" && (
                     
                     <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e:any) => e.key === "Enter" && sortDataBy("a1_proficiency")}
                      onClick={() => sortDataBy("a1_proficiency")}>
                      A1 Proficiency
                      {sortBy === "a1_proficiency" && (
                         <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e:any) => e.key === "Enter" && sortDataBy("b1_proficiency")}
                      onClick={() => sortDataBy("b1_proficiency")}>
                      B Proficiency
                      {sortBy === "b1_proficiency" && (
                        <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e:any) => e.key === "Enter" && sortDataBy("a2_proficiency")}
                      onClick={() => sortDataBy("a2_proficiency")}>
                      A2 Proficiency
                      {sortBy === "a2_proficiency" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                      )}
                    </td>
                    <td className='row-data-title options'>
                      Options
                    </td>
                  </tr>

                  {(
                    <>
                      {loading || classrooms?.length ? (
                        <>
                          {loading && [1,2,3,4,5,6,7,8,9,10].map(id => {
                            return (
                              <ActivitySkeleton
                                showSecondLastCol
                                i={id-1}
                              />
                            )
                          })}
                          {!loading && classrooms &&
                            classrooms?.map((x: any, i) => {
                              let countIndex = 0;
                              let calculateRows =
                                calculateHeight(
                                  classrooms,
                                  x.school_name
                                ).rows;
                              let notYet =
                                x.formative_test_data
                                  .total_tests_taken ===
                                  0 &&
                                !x.formative_test_data
                                  .proficiency &&
                                !x.summative_test_data
                                  .A1_proficient &&
                                !x.summative_test_data
                                  .A2_proficient &&
                                !x.summative_test_data
                                  .B_proficient;

                              let enableMasterAndClassroom = notYet;

                              if (
                                x.last_test_time != null &&
                                x.last_test_time_warning ===
                                  false
                              ) {
                                notYet = false;
                              } else if (
                                x.last_test_time != null &&
                                x.last_test_time_warning ===
                                  true
                              ) {
                                notYet = true;
                              }

                              return (
                                <>
                                  {(checkDuplicates(
                                    schoolIds
                                  ) === false ||
                                    sortBy === "name") &&
                                    sortBy !==
                                      "last_test_time" &&
                                    sortBy !==
                                      "objectives" &&
                                    sortBy !==
                                      "proficiency" &&
                                    sortBy !==
                                      "a1_proficiency" &&
                                    sortBy !==
                                      "b1_proficiency" &&
                                    sortBy !==
                                      "a2_proficiency" && (
                                      <tr className='progress__table-badge'>
                                        {classrooms?.map(
                                          (
                                            eachClassRoom: any,
                                            insideIndex
                                          ) => {
                                            let schoolNameHeight =
                                              null;
                                            if (
                                              insideIndex ===
                                                0 ||
                                              classrooms[
                                                insideIndex
                                              ]
                                                .school_name !==
                                                classrooms[
                                                  insideIndex -
                                                    1
                                                ]
                                                  .school_name
                                            ) {
                                              schoolNameHeight =
                                                calculateHeight(
                                                  classrooms,
                                                  eachClassRoom.school_name
                                                );
                                              countIndex += 1;
                                            }
                                            if (
                                              !schoolNameHeight
                                            ) {
                                              return <></>;
                                            }

                                            return (
                                              <td
                                                className={`progress__table-badgeContent`}
                                                style={{
                                                  height: `${schoolNameHeight?.height}%`,
                                                  maxHeight:
                                                    "none",
                                                }}>
                                                {schoolNameHeight.rows >=
                                                  3 && (
                                                  <p className='animated-fade'>
                                                    {
                                                      eachClassRoom.school_name
                                                    }
                                                  </p>
                                                )}
                                              </td>
                                            );
                                          }
                                        )}
                                      </tr>
                                    )}
                                  <tr
                                    className={
                                      i % 2 === 0
                                        ? "progress__table-row"
                                        : "progress__table-row"
                                    }>
                                    <td className='className-data'>
                                      <div className='className-data-wrapper'>
                                        {loading ? (
                                          <></>
                                        ):(
                                          <ClassroomName
                                            setClassroomFilters={() => setClassroomFilters(x)}
                                            animatedClassName={animatedClassName}
                                            classroom_name={x.classroom_name}
                                          />
                                        )}
                                        {sortBy !==
                                        "name" ? (
                                          <>
                                            {loading ? (
                                              <></>
                                            ):(
                                              <span
                                                className={`className-data-badge ${
                                                  animatedClassName
                                                    ? "animtated-title"
                                                    : ""
                                                } ${
                                                  x
                                                    .school_name
                                                    .length >
                                                  27
                                                    ? "animated"
                                                    : ""
                                                }`}>
                                                {
                                                  x.school_name
                                                }
                                              </span>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {(checkDuplicates(
                                              schoolIds
                                            ) === true ||
                                              sortBy ===
                                                "name") &&
                                              calculateRows <=
                                                2 && (
                                                <>
                                                  {loading ? (
                                                    <></>
                                                  ):(
                                                    <span
                                                      className={`className-data-badge ${
                                                        animatedClassName
                                                          ? "animtated-title"
                                                          : ""
                                                      } ${
                                                        x
                                                          .school_name
                                                          .length >
                                                        27
                                                          ? "animated"
                                                          : ""
                                                      }`}>
                                                      {
                                                        x.school_name
                                                      }
                                                    </span>
                                                  )}
                                                </>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </td>
                                    <td className="hour-column">
                                      {loading ? (
                                        <></>
                                      ): x?.hour ? (
                                        <span className="chip">{x?.hour}</span>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td className='assesment-data'>
                                      {loading && (
                                        <></>
                                      )}
                                      {!loading && notYet ? (
                                        <p className='warning-flag'>
                                          <img
                                            src={Warning}
                                            alt='warning icon'
                                          />
                                            {x.last_test_time ? (moment(
                                            x.last_test_time
                                            )
                                            .tz(
                                            "America/Chicago"
                                            )
                                            .format("M-D-YY")) : 'Not Yet'}
                                        </p>
                                      ) : !loading && x.last_test_time ? (
                                        moment(
                                          x.last_test_time
                                        )
                                          .tz(
                                            "America/Chicago"
                                          )
                                          .format("M-D-YY")
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      <ProgressbarCore
                                        isLoading={loading}
                                        width={x
                                          .formative_test_data
                                          .total_tests_taken ===
                                        0
                                          ? x.last_test_time ===
                                            null || x.formative_test_data.proficiency === null
                                            ? 0
                                            : Math.ceil((x.formative_test_data.total_tests_taken / x.formative_test_data.total)*100)
                                          : Math.ceil((x.formative_test_data.total_tests_taken / x.formative_test_data.total)*100)
                                        }
                                        isDisabled={x
                                          .formative_test_data
                                          .total_tests_taken === 0 && (
                                            x.last_test_time ===
                                            null || x.formative_test_data.proficiency === null
                                          ) ? true : false
                                        }
                                      >
                                      <p>
                                        {x
                                        .formative_test_data
                                        .total_tests_taken ===
                                      0
                                        ? x.last_test_time ===
                                          null || x.formative_test_data.proficiency === null
                                          ? "-"
                                          : `${x.formative_test_data.total_tests_taken} / ${x.formative_test_data.total}`
                                        : `${x.formative_test_data.total_tests_taken} / ${x.formative_test_data.total}`}
                                      </p>
                                      </ProgressbarCore>
                                    </td>
                                    <td>
                                      <ProgressbarCore
                                        isProficient={parseInt(
                                          x
                                            .formative_test_data
                                            .proficiency
                                        ) >= FORMATIVE_PROFICIENY}
                                        color="#9080FF"
                                        width={x
                                          .formative_test_data
                                          .proficiency
                                          ? parseInt(
                                              x
                                                .formative_test_data
                                                .proficiency
                                            )
                                          : x.last_test_time === null || x.formative_test_data.proficiency === null
                                          ? 0
                                          : 0
                                        }
                                        isDisabled={x
                                          .formative_test_data
                                          .proficiency === null && (
                                            x.last_test_time === null
                                          ) ? true : false
                                        }
                                        isLoading={loading}
                                      >
                                      <p>
                                        {x
                                          .formative_test_data
                                          .proficiency
                                          ? parseInt(
                                              x
                                                .formative_test_data
                                                .proficiency
                                            ) + "%"
                                          : x.last_test_time === null || x.formative_test_data
                                          .proficiency ===
                                            null
                                          ? "-"
                                          : "0%"
                                        }
                                      </p>
                                      </ProgressbarCore>
                                    </td>
                                    <td>
                                      <ProgressbarCore
                                        isLoading={loading}
                                        color="#9080FF"
                                        width={x
                                          .summative_test_data
                                          .A1_proficient != null
                                          ? parseInt(
                                              x
                                                .summative_test_data
                                                .A1_proficient
                                            )
                                          : x.last_test_time ===
                                            null
                                          ? 0
                                          : 0
                                        }
                                        isDisabled={(x
                                          .summative_test_data
                                          .A1_proficient != null
                                          ? false
                                          : x.last_test_time === null
                                          ? true
                                          : true)
                                        }
                                      >
                                          <p>
                                            {
                                              x
                                              .summative_test_data
                                              .A1_proficient != null
                                              ? parseInt(
                                                  x
                                                    .summative_test_data
                                                    .A1_proficient
                                                ) + "%"
                                              : x.last_test_time ===
                                                null
                                              ? "-"
                                              : "-"
                                            }
                                          </p>
                                        </ProgressbarCore>
                                    </td>

                                    <td>
                                      <ProgressbarCore
                                        isLoading={loading}
                                        color="#9080FF"
                                        width={x
                                          .summative_test_data
                                          .B_proficient != null
                                          ? parseInt(
                                              x
                                                .summative_test_data
                                                .B_proficient
                                            )
                                          : x.last_test_time ===
                                            null
                                          ? 0
                                          : 0
                                        }
                                        isDisabled={(x
                                          .summative_test_data
                                          .B_proficient != null
                                          ? false
                                          : x.last_test_time === null
                                          ? true
                                          : true)
                                        }
                                      >
                                          <p>
                                            {
                                              x
                                              .summative_test_data
                                              .B_proficient != null
                                              ? parseInt(
                                                  x
                                                    .summative_test_data
                                                    .B_proficient
                                                ) + "%"
                                              : x.last_test_time ===
                                                null
                                              ? "-"
                                              : "-"
                                            }
                                          </p>
                                        </ProgressbarCore>
                                    </td>
                                    <td>
                                      <ProgressbarCore
                                        isLoading={loading}
                                        color="#9080FF"
                                        width={x
                                          .summative_test_data
                                          .A2_proficient != null
                                          ? parseInt(
                                              x
                                                .summative_test_data
                                                .A2_proficient
                                            )
                                          : x.last_test_time ===
                                            null
                                          ? 0
                                          : 0
                                        }
                                        isDisabled={(x.summative_test_data.A2_proficient != null
                                          ? false
                                          : x.last_test_time === null
                                          ? true
                                          : true)
                                        }
                                      >
                                        <p>
                                          {
                                            x
                                            .summative_test_data
                                            .A2_proficient != null
                                            ? parseInt(
                                                x
                                                  .summative_test_data
                                                  .A2_proficient
                                              ) + "%"
                                            : x.last_test_time ===
                                              null
                                            ? "-"
                                            : "-"
                                          }
                                        </p>
                                      </ProgressbarCore>
                                    </td>
                                    <td
                                      
                                      className={`options ${isLoading || skeletonLoad ? 'disabled' : ''}`}
                                    >
                                      <div>
                                        <TableSelectCommon
                                        
                                          customClassName={`${
                                            classrooms.length === 2
                                              ? `center-start-${i}`
                                              : ""
                                          } ${
                                            classrooms.length ===
                                            1
                                              ? "single-select"
                                              : ""
                                          }`}
                                          isOpen={
                                            selectedId ===
                                            i + 1
                                          }
                                          toggleSelect={() => {
                                            if(isLoading || skeletonLoad) return;
                                            if (
                                              selectedId !==
                                              i + 1
                                            ) {
                                              setSelectedId(
                                                i + 1
                                              );
                                            } else {
                                              setSelectedId(
                                                0
                                              );
                                            }
                                          }}
                                          options={_.cloneDeep(
                                            options.map(
                                              (
                                                item: any
                                              ) => {
                                                item.disabled =
                                                    enableMasterAndClassroom &&
                                                  item.value !==
                                                    "To Classroom" &&
                                                  item.value !==
                                                    "Manage";
                                                return item;
                                              }
                                            )
                                          )}
                                          onChange={(
                                            value
                                          ) => {
                                            setClassroomFilters(
                                              {
                                                ...x,
                                                selectedOption:
                                                  value,
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          <tr className='progress__table-row text-center'>
                            <td
                              colSpan={10}
                              className='no-data mt-4'>
                              <strong className=' p-2 mb-2 text-center'>
                                No records found
                              </strong>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>): currentTab === ACTIVITY_TABS.PD ? (
            <ActivityPDProgress 
              setOffset={setOffset} 
              limit={limit} offset={offset} 
              currentPage={currentPage} 
              search={searchTerm}
              setSchoolId={setSchoolId}
              schoolId={schoolId}
              districtId={districtId}
              setDistrictId={setDistrictId}
              subjectId={subjectId}
              setSubjectId={setSubjectId}
              gradeId={gradeId}
              setGradeId={setGradeId}
              teacherId={teacherId}
              setTeacherId={setTeacherId}
              setCurrentPage={setCurrentPage}
              setCount={setCount}
              count={count}
            />
          ):(
            <>
              <FormativeProgressChart 
                classrooms={chartData?.activity_progress_info?.classrooms || []}
                isLoading={isLoading}
                assessmentType={assessmentType}
                sortBy= {sortBy}
                orderBy = {OrderByColumn}
                legendPadding={chartData?.legendPadding}
              />
              <div ref={componentRef} className="printData">
                  <FormativeProgressChartPrint animations={false} data={printData} schoolId={schoolId} />
              </div>
            </>
            
          )}
          </div>
        </div>
        {(
          <Pagination
            totalCount={paginationCount}
            currentPage={paginationCurrentPage}
            pageSize={paginationLimit}
            onPageChange={onPageChange}
          />
        )}
      </div>
      {showModal && (
        <SuccessModal 
          isShow
          headerText="Activity Progress Chart"
          bodyText="Select a school above to enable the Activity Progress Chart."
          closeModal={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default ActivityProgress;
