import { TYPE } from "interfaces/supportTicketSuperAdmin";
import Cookies from "js-cookie";

const DEFAULT_COLOR_HEX = '#079CD5';

export const createAdminMessageObject = (messages: any[], message: string, colorCode: string) => {
    const first_name = Cookies.get("first_name");
    const last_name = Cookies.get("last_name");
    const name = first_name ? `${first_name}${last_name ? ` ${last_name}` : ''}` : '';

    const messageData = messages.find(item => item.name === name);

    const messagePayload = messageData 
    ? {
        color_hex: colorCode || messageData.color_hex,
        name,
        name_initials: messageData.name_initials,
        type: TYPE.MESSAGE,
        date: new Date().toISOString(),
        message
    }
    : {
        color_hex: colorCode || DEFAULT_COLOR_HEX,
        name,
        name_initials: `${first_name ? first_name[0] : ''}${last_name ? last_name[0] : ''}`,
        type: TYPE.MESSAGE,
        date: new Date().toISOString(),
        message
    }
    
    return messagePayload;
}


export const createMessageObject = (messages: any[], message: string) => {
    const first_name = Cookies.get("first_name");
    const last_name = Cookies.get("last_name");
    const color_hex = Cookies.get("profile_color_code");
    const name = first_name ? `${first_name}${last_name ? ` ${last_name}` : ''}` : '';



    const messageData = messages.find(item => item.name === name);

    const messagePayload = {
        sent_by: name,
        sent_by_initials: messageData?.sent_by_initials || `${first_name ? first_name[0] : ''}${last_name ? last_name[0] : ''}`,
        date: new Date().toISOString(),
        message,
        color_hex
    }
    
    return messagePayload;
}