import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers/combine';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus } from "@fortawesome/free-solid-svg-icons";
import { isFirefox, isSafari } from 'util/index';
import moment from 'moment';

const AnswerMap = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D'
}

const threshold = (isFirefox || isSafari) ? 20 : 30;

const PrintReport = ({ indx , dropDownLabel }: { indx: number , dropDownLabel:string }) => {

    const report = useSelector((state: RootState) => state.classroom.irrFormative);

    const Students = useMemo(() => {

        const students = report?.data?.response?.students
        if (!students) {
            return
        }

        return students?.sort((a: any, b: any) => {
            return a.last_name.localeCompare(b.last_name)
        })
    }, [report])



    return (
        <div className="report-item-container lineHeight1">
            <div className="report__numbers lineHeight1" >

                <div className="report__numbers-name lineHeight1" >
                    <p className='lineHeight1'>Student Name</p>
                </div>

                <div className="report__numbers-container formative-irr-print lineHeight1">
                    {report?.data?.answers.map((answer: any, i: number) => (
                        <span
                            key={i}
                            className={`report__numbers-count lineHeight1`}
                        >
                            {i + 1}
                        </span>
                    ))}
                </div>

                <div className="report__numbers-attempt lineHeight1">
                    <span className='lineHeight1'>{dropDownLabel}</span>
                </div>

                <div className="report__numbers-score lineHeight1 paddingRight-0">
                    <span className='lineHeight1'>(# Correct) <br /> Score</span>
                </div>
            </div>
            <table className="report__itemTable lineHeight1">
                <tbody className='lineHeight1'>
                    {
                        Students?.slice(indx * threshold, (indx + 1) * threshold).map((student: any, index: number) => {
                            const { attempts, student_name } = student
                            // const { answers, calculation , test_date } = attempts[0]
                            return attempts.map(({ answers, calculation , test_date }: any, attemptIndex: number) => {

                                return (

                                    <tr key={index} className='lineHeight1'>
                                        <td className="report__itemTable-name lineHeight1 height10px">{!attemptIndex ? student_name : ''}</td>
                                        <div className="d-flex lineHeight1 height10px">
                                            {answers
                                                .map((s: any, i: number) => {
    
                                                    if (s.answer) {
                                                        return <td className="report__itemTable-numbers lineHeight1" key={i} >
                                                            {!s.correct &&
                                                                <span className={`print__itemTable-rectangle report__itemTable-rectangle lineHeight1 width15px-height15px`}>
                                                                    {AnswerMap[s.answer.trim() as keyof typeof AnswerMap]}
                                                                </span>
                                                            }
                                                            {s.correct &&
                                                                <span className={`print__itemTable-check report__itemTable-rectangle lineHeight1 height10px width5px`}>
                                                                    <FontAwesomeIcon className="font-icon-tick" name="Check" icon={faCheck} />
                                                                </span>
                                                            }
                                                        </td>
                                                    }
                                                    return <td className="report__itemTable-numbers lineHeight1 height10px" key={i} >
                                                        <span className={`print__itemTable-dash report__itemTable-rectangle lineHeight1 height10px`} >
                                                            <FontAwesomeIcon name="Minus" icon={faMinus} />
                                                        </span>
                                                    </td>;
                                                })
                                            }
                                        </div>
                                        <td className="report__itemTable-attempt lineHeight1 height10px" >
                                            <span className='lineHeight1 height10px' > {dropDownLabel === "Attempt"
                                                ? `${calculation?.attempt}`
                                                : (test_date && test_date.length && moment(test_date).format("M-D-YY")) || ''
                                            }</span>
                                        </td>
    
                                        <td className="report__itemTable-score score-align lineHeight1 height10px paddingLeft-10" >
                                            <span className='lineHeight1 height10px'>{`(${calculation.total_correct || 0})`}</span>
                                            <span
                                                className='lineHeight1 height10px' style={{ marginLeft: 7, }}
                                            >
                                                {`${calculation.percentage || 0}%`}
                                            </span>
                                        </td>
    
                                    </tr>
    
                                )
                            })
                        })
                    }

                </tbody>
            </table>

            <div className="report__total lineHeight1">
                <div className="report__total-title lineHeight1 footer-left-div" >Correct Answer</div>
                <div className="report__total-count lineHeight1">
                    {report?.data?.answers.map((answer: any, index: number) => {
                        return <span key={index} className={`report__total-total answer lineHeight1`}>
                            {answer.info}
                        </span>
                    })}
                </div>

            </div>

            <div className="report__total lineHeight1" >
                <div className="report__total-title lineHeight1 footer-left-div" >*Total Students</div>
                <div className="report__total-count lineHeight1" >
                    {Array.isArray(report?.data?.total_students) && report?.data?.total_students?.map((count: any, index: number) => {
                        return (
                            <span key={index} className={`report__total-total lineHeight1`} >
                                {count.correct}
                            </span>
                        )
                    })}
                </div>

            </div>

            <div className="report__total lineHeight1" >

                <div className="report__total-title lineHeight1 footer-left-div" >
                    <div className="d-flex align-items-center justify-content-between lineHeight1" >
                        <span className="report__total-rectangle print__itemTable-check">
                            <FontAwesomeIcon className="font-icon-tick correcticon-tick" name="Check" icon={faCheck} />
                        </span>
                        <span>Total Correct</span>
                    </div>
                </div>
                <div className="report__total-count">
                    {report?.data?.total_correct?.map((count: any, index: number) => {
                        return (
                            <span key={index} className={`report__total-total`}>
                                {count.correct}
                            </span>
                        )
                    })}
                </div>

            </div>

            <div className="report__total">
                <div className="report__total-title incorrect footer-left-div" >
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="report__total-rectangle print__itemTable-rectangle width15px-height15px"></span>
                        <span>Total Incorrect</span>
                    </div>
                </div>
                <div className="report__total-incorrect">
                    {report?.data?.total_incorrect?.map((count: any, index: number) => {
                        return (
                            <div key={index} className="report__total-incorrectContainer">
                                <span className={`report__total-total`}>
                                    {count.correct}
                                </span>
                                <span className={`report__total-objective 'footerTransformOnSingleColumn'`} >
                                    <span className={`report__total-objectiveData`}>
                                        {report?.data?.response.objective_number}
                                    </span>
                                </span>
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    )

}

export default PrintReport;