import moment from "moment";
import { useContext, useState } from "react";
import _ from 'lodash';
import { SCROLL_VALUE_SUMMATIVE } from "util/constants";
import { useNavigate } from "react-router-dom";
import useWindowScrollPosition from "hooks/useWindowScrollPosition";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import Cookies from "js-cookie";
import ActionType from "redux/actionTypes";
import { useDispatch } from "react-redux";
import { assignSummatives } from "redux/actionCreators/classroom";
// import ReportClickAlertModal from "components/classroom/modals/ReportClickAlertModal";
// import PreviewHelpModel from "components/classroom/modals/previewHelpModel";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core";
import ScheduledAssessment from "./scheduledAssessment";
import NotScheduledAssessment from "./notScheduledAssessment";

library.add(faCaretLeft);

const options = [
    { value: "Item Response Report", label: "Item Response Report" },
    { value: "Standards at Risk", label: "Standards at Risk" },
    { value: "Classroom Summative Report", label: "Classroom Summative Report" }
];

const SummativeAssessment = ({
    assessment,
    index
}: any) => {
    const dispatch = useDispatch();
    const { fetchSummatives, markAssessmentCompleted, fetchAssessmentMonitorData ,summatives, setSummatives, schoolId, isLoading, classroomId } = useContext(ProfessionalDevelopmentContext);
    const navigate = useNavigate();
    const {
        id,
        name, 
        last_activity,
        students_assessed_count,
        proficiency,
        scheduled_date,
        is_past_window,
        can_be_enabled
    } = assessment;

    const isScheduled = !!scheduled_date && scheduled_date !== "Not Scheduled";
    const isExpanded = isScheduled || is_past_window || can_be_enabled;
    const scrollValue = useWindowScrollPosition(SCROLL_VALUE_SUMMATIVE, !isLoading);
    const enableAccess = !!students_assessed_count;

    const [showReviewHelpModel, setShowReviewHelpModel] = useState(false);
    const [isFlag, setFlag] = useState(false);
    const [helpTitle, setHelpTitle] = useState<any>(null);
    const [showReportAlertModal, setShowReportAlertModal] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [openNoStudentsModal, setOpenNoStudentsModal] = useState(false);
    const [openRandomModal, setOpenRandomModal] = useState(false);

    const lastActivityFormatted = last_activity ? moment(last_activity).format("M-D-YY") : '';

    const isFirstRow = index === 0;
    const reportOptions = _.cloneDeep(options).map(
        (item: any) => {
          item.disabled = !enableAccess && !proficiency;
          return item;
        }
    );

    const switchFlag = () => {
        setFlag(!isFlag);
    };
    
    const onCancelChooseQuestionModal = () => {
        // const { id } = checkboxRef.current as any
        const reports = summatives
        const reportIndex = reports.findIndex(
            (repo: any) => repo.id === id
        );
        if (reportIndex > -1) {
            const tempReports = [...reports];
            tempReports[reportIndex].enabled = false;
            setSummatives(tempReports);
        }
        setOpenRandomModal(false);
    };

    const onChangeRandom = async (x: any) => {
      await handleAvaibilityToggle(x, true);
      setOpenRandomModal(false);
    };

    const handleSummativeAvailable = (e: any, x: any) => {
        if (!summatives[0].students_total_count) {
          setOpenNoStudentsModal(true);
          return;
        }
        const currentToggleState = e.target.checked;
    
        const updatedSummatives = summatives.map((summ: any) => {
          if (summ.id === x.id) {
            return {
              ...summ,
              enabled: currentToggleState
            };
          }
          return summ;
        });
        setSummatives(updatedSummatives)
        
    
        if (x.show_random_option && e.target.checked) {
          setOpenRandomModal(true);
        } else {
          handleAvaibilityToggle(x, e.target.checked);
        }
    }

    const handleAvaibilityToggle = async (x: any, checked: any) => {
        await assignSummatives(
            {
              classroom_id: classroomId,
              summative_id: x.id,
              is_enabled: checked,
              is_random: x.show_random_option,
            },
            switchFlag
        );
        fetchAssessmentMonitorData();
        fetchSummatives(schoolId, classroomId);
    
        if (checked) {
          if(document.getElementById(`monitor-$${x.id}`) as HTMLInputElement){
            (
              document.getElementById(`monitor-$${x.id}`) as HTMLInputElement
            ).disabled = false;
          }
        } else {
          if(document.getElementById(`monitor-$${x.id}`) as HTMLInputElement){
            (
              document.getElementById(`monitor-$${x.id}`) as HTMLInputElement
            ).disabled = true;
          }
        }
    };

    const onClickAvailableStudents = () => {
        setModalShow(true);
        dispatch({
          type: ActionType.FORMATIVE_NAME,
          payload: name,
        });
    }

    const onChangeAvailable = (e: any) => {
        // checkboxRef.current = { ...assessment }
        handleSummativeAvailable(e, assessment);
    
    };

    const selectReport = (
        reportName: string,
        summativeId: number,
        assessmentName: string
    ) => {
        sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`)
    
        if (reportName === "Classroom Summative Report") {
          Cookies.set("test_name", assessmentName);
          navigate(`report/summative/${summativeId}/${classroomId}`);
        }
        // if (reportName === "Grade Frequency and Distribution") {
        //   navigate(`/classroom/report/grade-frequency-distribution/${classroomId}`);
        // }
    
        if (reportName === "Item Response Report") {
          Cookies.set("test_name", assessmentName);
    
          navigate(
            `/classroom/report/item-response-report-summative/${summativeId}/${classroomId}`
          );
        }
    
        if (reportName === "Standards at Risk") {
          Cookies.set("test_name", assessmentName);
    
          navigate(
            `/classroom/report/standard-at-risk/${classroomId}/${summativeId}`
          );
        }
    
        // if (reportName === "Student Summative Report") {
        //   navigate(
        //     `/classroom/report/summative/student/${classroomId}/${summativeId}`
        //   );
        // }
    
    };
    
    const onCloseNoStudents = () => {
      setOpenNoStudentsModal(false);
    };

    const onMarkCompleted = (e: any) => {
      markAssessmentCompleted({
        isSummative: true,
        status: e.target.checked,
        summative_id: id,
        classroom_id: classroomId
      });
    }

    return (
        <>
            {isFirstRow && (
              <table className="table-main not-scheduled-table mb-0">
                <thead>
                  <th className="assessment-date"></th>
                  <th className="name">Assessment Name</th>
                  <th className="last-active">Last <br /> Activity</th>
                  <th className="student-assessed">Students <br /> Assessed</th>
                  <th className="proficiency">Proficiency</th>
                  <th className="guide">Guide</th>
                  <th className="answer">Add/Edit <br /> Answers</th>
                  <th className="reports-dropdown">Reports</th>
                  <th className="available-student">Available to <br /> Students</th>
                  <th className="monitor-button">Monitor</th>
                </thead>
                <tbody></tbody>
              </table>
            )}
            {isExpanded ? (
              <ScheduledAssessment
                assessment={assessment}
                lastActivityFormatted={lastActivityFormatted}
                enableAccess={enableAccess}
                totalAssessments={summatives.length}
                index={index}
                setToggle={setToggle}
                setHelpTitle={setHelpTitle}
                selectReport={selectReport}
                onChangeAvailable={onChangeAvailable}
                onClickAvailableStudents={onClickAvailableStudents}
                modalShow={modalShow}
                openRandomModal={openRandomModal}
                openNoStudentsModal={openNoStudentsModal}
                showReportAlertModal={showReportAlertModal}
                setShowReportAlertModal={setShowReportAlertModal}
                helpTitle={helpTitle}
                showReviewHelpModel={showReviewHelpModel}
                setShowReviewHelpModel={setShowReviewHelpModel}
                toggle={toggle}
                reportOptions={reportOptions}
                setModalShow={setModalShow}
                onCancelChooseQuestionModal={onCancelChooseQuestionModal}
                onChangeRandom={onChangeRandom}
                onCloseNoStudents={onCloseNoStudents}
                onMarkCompleted={onMarkCompleted}
                isScheduled={isScheduled}
              />
            ):(
              <NotScheduledAssessment
                assessment={assessment}
              />
            )}
        </>
    )
};

export default SummativeAssessment;
