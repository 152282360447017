/* eslint-disable array-callback-return*/
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import Header from "components/common/report/Print/header";
import PrintFooter from "components/common/report/Print/footer";
import moment from "moment";
import classNames from "util/classNames";

const StudentPerformanceDownload: React.FC<{
  classrooms: any;
  performance: any;
  studentData: any;
  performanceHeaderData: any;
  showLastThreeAttempts: any;
  subject: number;
  isPrev: boolean;
  school_name: string;
  className?: string;
}> = ({
  classrooms,
  performance,
  studentData,
  performanceHeaderData,
  showLastThreeAttempts,
  subject,
  isPrev,
  school_name,
  className,
}) => {
  const studentPrintContent = useMemo(() => {
    const tableContent = (key: string, item: any) => {
      return (
        <div className="testResults-table-container non-repeated-table-header">
          <div className="table-responsive">
            <table className="reportCardPerformanceDetails__table testResults__table-print non-repeating-table-header-print">
              <thead className="reportCardPerformanceDetails__table-header">
                <tr className="reportCardPerformanceDetails__table-row">
                  <th className="reportCardPerformanceDetails__table-completed">
                    Completed {key.replace(key[0], key[0].toUpperCase())}
                  </th>
                  <th className="reportCardPerformanceDetails__table-date-attempts">
                    Date
                  </th>
                  <th className="reportCardPerformanceDetails__table-score">
                    Attempts
                  </th>
                  {!showLastThreeAttempts && (
                    <th className="reportCardPerformanceDetails__table-attempt">
                      Last Score
                    </th>
                  )}
                  {showLastThreeAttempts && (
                    <>
                      <th className="reportCardPerformanceDetails__table-score">
                        Attempt 1
                      </th>
                      <th className="reportCardPerformanceDetails__table-score">
                        Attempt 2
                      </th>
                      <th className="reportCardPerformanceDetails__table-score">
                        Attempt 3
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {classrooms[item][key]?.map((test: any, i: number) => {
                  const attempts =
                    key === "summatives"
                      ? test?.scored_summatives
                      : test?.scored_formatives;
                  return (
                    <tr
                      key={i}
                      className={`reportCardPerformanceDetails__table-row`}
                    >
                      <td className="completed-data">{test?.name}</td>
                      <td>{test?.date ? test?.date : "-"}</td>
                      <td className="reportCardPerformanceDetails__table-attempts">
                        {test?.attempts}
                      </td>
                      {showLastThreeAttempts ? (
                        <>
                          <td>
                            {attempts[0]?.score_percentage && (
                              <span
                                className={`level-box level-box-print-${attempts[0]?.score_percentage}`}
                              ></span>
                            )}
                            {attempts[0]?.score_percentage}
                          </td>
                          <td>
                            {attempts[1]?.score_percentage && (
                              <span
                                className={`level-box level-box-print-${attempts[1]?.score_percentage}`}
                              ></span>
                            )}
                            {attempts[1]?.score_percentage}
                          </td>
                          <td>
                            {attempts[2]?.score_percentage && (
                              <span
                                className={`level-box level-box-print-${attempts[2]?.score_percentage}`}
                              ></span>
                            )}
                            {attempts[2]?.score_percentage}
                          </td>
                        </>
                      ) : (
                        <td colSpan={3}>
                          <span
                            className={`level-box level-box-print-${test?.last_score}`}
                          ></span>
                          {test?.last_score}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    };

    let performanceLoaded = performanceHeaderData?.level;

    if (subject === 1) {
      performanceLoaded = performanceHeaderData?.level_math;
    } else if (subject === 3) {
      performanceLoaded = performanceHeaderData?.level_science;
    }

    let subjectSet = "ELA";

    if (subject === 1) {
      subjectSet = "Math";
    } else if (subject === 3) {
      subjectSet = "Science";
    }

    if (isPrev) {
      performanceLoaded = performanceHeaderData.level_previous;
      subjectSet = "";
    }

    return (
      <div className={`accordion`}>
        <div style={{ marginTop: "5px" }}>
          <Header
            title={`Student: ${
              studentData?.first_name + " " + studentData?.last_name
            } `}
            test_name={`Student ID: ${studentData?.school_student_id}`}
            class_name={`Last Login: ${
              studentData?.last_login
                ? moment(studentData?.last_login).format("M/D/YY")
                : ""
            }`}
            school_name={school_name}
          />
          <div className={`accordion__content pb-2 print`}>
            <section className="performance print">
              <div className="performance__perecentage print">
                <div className="performance__perecentage-contentainer">
                  <h6 className="performance__perecentage-title print">
                    {subjectSet} Performance Level (Percentage Score Range)
                  </h6>
                  <div className="">
                    {performanceLoaded?.map((item: any, i: number) => {
                      return (
                        <div className="performance__perecentage-score print">
                          <div
                            key={i}
                            className={classNames(
                              "reportCards__student-performance-score",
                              i === 4 ? "add-top-margin" : ""
                            )}
                          >
                            <div className="summ_form_label">
                              {i === 0 && (subject === 1 || subject === 2) && (
                                <p>Summatives</p>
                              )}
                              {i === 4 && (subject === 1 || subject === 2) && (
                                <p>Formatives</p>
                              )}
                            </div>
                            {subject === 3 && i > 3 ? (
                              <></>
                            ) : (
                              <div className="summ_form">
                                <p>
                                  {item?.type === "Proficient" && (
                                    <span
                                      className={`level-box level-box-print-P`}
                                    ></span>
                                  )}
                                  {item?.type === "Non-Proficient" && (
                                    <span
                                      className={`level-box level-box-print-B`}
                                    ></span>
                                  )}
                                  <span
                                    className={`level-box level-box-print-${item?.type}`}
                                  ></span>{" "}
                                  {item.type}
                                  {item.type === "A" ||
                                  item.type === "P" ||
                                  item.type === "B" ||
                                  item.type === "BB" ||
                                  item.type === "NP"
                                    ? ":"
                                    : ""}{" "}
                                  {item.type === "A"
                                    ? "Advanced"
                                    : item.type === "P"
                                    ? "Proficient"
                                    : item.type === "B"
                                    ? "Basic"
                                    : item.type === "BB"
                                    ? "Below Basic"
                                    : item.type === "NP"
                                    ? "Non-Proficient"
                                    : ""}
                                </p>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: `( ${item?.string} )`,
                                  }}
                                ></p>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <p className="performance__perecentage-result print">
                    (***%) = Percentage of questions answered correctly
                  </p>
                </div>
              </div>
            </section>

            {performance && Object.keys(performance?.tests).length
              ? Object.keys(performance?.tests).map((item: any, i: number) => {
                  if (classrooms[item]) {
                    return (
                      <div key={i} className={`testResults print`}>
                        {
                          <div className="testResults__header print">
                            <section className="testResults__header-heading">
                              <h3 className="testResults__header-title print">
                                {item}
                              </h3>
                            </section>
                            <div className="d-flex align-items-center testResults__header-select"></div>
                          </div>
                        }

                        {classrooms[item]["summatives"]?.length > 0 &&
                          tableContent("summatives", item)}

                        {classrooms[item]["formatives"]?.length > 0 &&
                          tableContent("formatives", item)}
                        {!classrooms[item]["formatives"]?.length &&
                          !classrooms[item]["summatives"]?.length && (
                            <div style={{ marginLeft: "100px" }}>
                              {" "}
                              No Assessments Taken Yet
                            </div>
                          )}
                      </div>
                    );
                  }
                })
              : ""}
          </div>
          <PrintFooter />
        </div>
      </div>
    );
  }, [classrooms, performance, performanceHeaderData, showLastThreeAttempts]);

  return <div className={className}>{studentPrintContent}</div>;
};

export default StudentPerformanceDownload;
