/* eslint-disable react-hooks/exhaustive-deps */

import Tabs from "./tabs";
import Goals from "./goals";
import Tools from "./tools";
import AssessmentMonitor from "./assessment-monitor";
import Assessments from "./assessments";
import { useContext, useEffect } from "react";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import PlayIcon from "assets/img/play_btn.svg";
import moment from "moment";
import StlSchoolButton from "components/common/stlSchoolAdminButton";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import Cookies from "js-cookie";
import StlClassButton from "components/common/stlClassAdminButton";
import { useNavigate } from "react-router-dom";
import { useSelectedClassroom } from "hooks/useSelectedClassroom";

const ProfessionalDevelopment = () => {
  const { management } = useSelector((state: RootState) => state);
  const app = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const selectedClassroom = useSelectedClassroom();
  const { advanceWeek, pdClassroom, classroomId } = useContext(
    ProfessionalDevelopmentContext
  );

  const { week_date } = pdClassroom;
  // const {tooltip, setTooltip, showNoGoalTooltip, showNoScheduledTooltip} = useContext(ClassroomTooltipContext);
  // const isBlur = !pdClassroom.loading && (tooltip === 4 || tooltip === 5);
  // const isTransparent = !!classroomId && (tooltip === 1 || tooltip === 2 || tooltip === 3 || isGoalTooltip || showNoGoalTooltip || showNoScheduledTooltip);

  const userRole = Cookies.get("role");


  useEffect(() => {
    if (app.classroom?.grade === 9 || selectedClassroom?.students_count === 0) {
      navigate(`/classroom/resources`);
    }
  }, [app.classroom]);

  return (
    <div>
      <div className="summative-report-download-btn">
        {management.stlSchoolLoading &&
          management?.schoolStlPeriod &&
          userRole === "School Admin" && (
            <StlSchoolButton
              previous_year={
                management?.schoolStlPeriod === "2023-24" ? "true" : ""
              }
            />
          )}
        {management.stlClassLoading && userRole === "Classroom Admin" && (
          <StlClassButton />
        )}
      </div>
      {!!week_date && (
        <div className="ProfessionalDev__weekHeaderBlock">
          <p className="ProfessionalDev__weekHeaderBlock__weekHeaderDate">
            Demo Week of {moment(week_date).format("dddd, MMM D")}
          </p>

          <p className="ProfessionalDev__weekHeaderBlock__weekHeaderAdvance">
            Advance
          </p>
          <img
            src={PlayIcon}
            role="button"
            tabIndex={0}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                advanceWeek(classroomId);
              }
            }}
            onClick={(e: any) => {
              e.preventDefault();
              advanceWeek(classroomId);
            }}
            className="ProfessionalDev__weekHeaderBlock__weekHeaderPlayIcon cursor-pointer"
            alt="Advance"
          />
          <div></div>
          {/* {isBlur && (
            <div className="toolTip-bg"></div>
          )}

          {isTransparent && (
            <div className="toolTip-bg toolTip-bg-transparent"></div>
          )} */}
        </div>
      )}
      <Tabs />
      <Goals />
      <Tools />
      <AssessmentMonitor />
      <Assessments />
    </div>
  );
};
export default ProfessionalDevelopment;
