/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback, useRef } from "react";
import cryptoRandomString from "crypto-random-string";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "assets/img/edit-icon.svg";
import RPIcon from "assets/img/cross-icon.svg";
import DeleteIcon from "assets/img/trash-icon.svg";
import Pagination from "../pagination/pagination";
import {
  SchoolAdminAddModal,
  ResetPasswordModal,
} from "./management-add-modal";
import { SchoolAdminDeleteModal } from "./management-delete-modal";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import {
  SchoolAdminUpdateModal,
  SchoolUpdateModal,
} from "./management-update-modal";
import {
  addSchoolAdmin,
  getSingleSchool,
  getSchoolAdmins,
  deleteSchoolAdmin,
  updateSchoolAdmin,
  updateSchool,
  resetPasswordAdmin,
  updateIsPrincipalForAdmin,
  setFlagForFetch,
  sendWelcomeEmails,
  requestStlReport,
} from "../../redux/actionCreators/management";
import cookie from "js-cookie";
import SendEmailConfirmationModal from "./modals/sendEmailConfirmModal";
import PermissionHintModal from "./modals/permissionHintModal";
import ActionType from "redux/actionTypes";
import StlSchoolButton from "components/common/stlSchoolAdminButton";
import {
  isPrevYearDistrictSTLExists,
  isPrevYearSTLExists,
} from "services/common";
import StlSingleButton from "components/common/stlSingleDistrictButton";
import classNames from "util/classNames";
import ButtonDropDown from "components/common/buttonDropDown";
import BackButton from "components/common/backButton";
import Toggle from "components/common/Toggle";

interface RootState {
  management: any;
  auth: any;
  activity: any;
}

const SchoolDetails: React.FC<{ schoolId: string }> = ({ schoolId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { management, auth, activity } = useSelector(
    (state: RootState) => state
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [schoolUpdateModal, setSchoolUpdateModal] = useState(false);
  const [resetPassModdal, setResetPassModdal] = useState(false);
  const [randomPass, setRandomPass] = useState("");
  // const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [schoolInfoLoad, setSchoolInfoLoad] = useState(true);
  const [lastAddedId, setLastAddedId] = useState(0);
  const [sortBy, setSortBy] = useState("last_name");
  const [OrderByColumn, setOrderBy] = useState("ASC");
  const [emailConfirmMessage, setEmailConfirmMessage] = useState("");
  const [emailConfirmModal, setEmailConfirmModal] = useState(false);
  const [openHintModal, setHintModal] = useState(false);
  const [textAfterAnimation, setTextAfterAnimation] = useState(false);
  const [districtPrevExists, setDistrictPrevExists] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState<any>("");

  const userRole = cookie.get("role");
  useEffect(() => {
    let params = {
      offSet,
      limit,
      searchTerm,
      schoolId,
      sortBy,
      OrderByColumn,
    };
    dispatch(getSingleSchool(Number(schoolId)));
    dispatch(getSchoolAdmins(params));
  }, [
    offSet,
    limit,
    management.schoolAdminFlag,
    management.schoolFlag,
    management.studentFlag,
    management.studentAddLoading,
    dispatch,
    sortBy,
    OrderByColumn,
    schoolId,
  ]);

  const checkDistrictPrevStlExists = async () => {
    console.log("test", management.currentSchool?.district_id);
    const isExists = await isPrevYearDistrictSTLExists(
      management.currentSchool?.district_id
    );
    isExists && setDistrictPrevExists(isExists.previous_year_exists);
  };

  useEffect(() => {
    if (
      userRole === "District Admin" &&
      management?.currentSchool?.district_id
    ) {
      checkDistrictPrevStlExists();
    }
  }, [userRole, management?.currentSchool]);

  const setPreviousPage = () => {
    if (currentPage > 1 && management.schoolAdmins.length === 1) {
      doChangePage(currentPage - 1);
    } else {
      dispatch(setFlagForFetch("SET_SCHOOL_ADMIN_FLAG"));
    }
  };

  console.log(management.currentSchool, "CURRENT SCHOOL.............");
  const onDeleteSchoolAdmin = () => {
    let data = {
      school_id: currentItem?.school_id,
      admin_id: currentItem.id,
    };
    dispatch(deleteSchoolAdmin(data, setDeleteModal, setPreviousPage, false));
  };

  const onUpdateSchoolAdmin = (data: any) => {
    dispatch(
      updateSchoolAdmin(
        currentItem?.id,
        currentItem?.school_id,
        data,
        setUpdateModal
      )
    );
  };
  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };
  const doChangePage = (pageNo: number) => {
    let newOffset = pageNo * limit - limit;
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };
  const doSetUpdateModal = (item: any) => {
    setUpdateModal(true);
    setCurrentItem(item);
  };

  const onAddSchoolAdmin = (data: any) => {
    dispatch(
      addSchoolAdmin({ ...data, school_id: Number(schoolId) }, setAddModal)
    );
  };
  const onUpdateSchool = (data: any) => {
    console.log(currentItem.id, data);
    dispatch(updateSchool(currentItem.id, data, setSchoolUpdateModal));
  };
  const doSetSchoolUpdateModal = (item: any) => {
    setSchoolUpdateModal(true);
    setCurrentItem(item);
    // dispatch(getSingleSchool(item.id));
  };
  const doSetPasModal = (item: any) => {
    setCurrentItem(item);
    let newPass = cryptoRandomString({ length: 10, type: "url-safe" });
    setRandomPass(newPass);
    setResetPassModdal(true);
  };
  const onCreatePassword = () => {
    let data = {
      admin_id: currentItem?.id,
      password: randomPass,
    };
    console.log(currentItem);
    dispatch(resetPasswordAdmin(data, setResetPassModdal));
    // setCurrentItem(null)
  };

  const getSTL = async () => {
    if (userRole === "School Admin") {
      const res = await isPrevYearSTLExists(schoolId);
      res &&
        dispatch({
          type: ActionType.SET_SCHOOL_STL_EXISTS,
          payload: res.previous_year_exists,
        });
    }
  };

  useEffect(() => {
    getSTL();
  }, [userRole]);

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setSkeletonLoad(false);
    // }, 800);

    return () => {
      // clearTimeout(timer);
      // setSkeletonLoad(true);
    };
  }, [management.schoolAdminsLoading]);

  useEffect(() => {
    if (management.lastAddedSchoolAdminId > 0) {
      setLastAddedId(management.lastAddedSchoolAdminId);
      setSearchTerm("");
      let params = {
        offSet: 0,
        limit: 10,
        searchTerm: "",
        schoolId,
        sortBy,
        OrderByColumn,
        new_admin_id: management.lastAddedSchoolAdminId,
      };
      dispatch(getSchoolAdmins(params));
    }
    if (management.schoolAdminPageNumber > 0) {
      setLimit(10);
      setCurrentPage(management.schoolAdminPageNumber);
      setOffSet(management.schoolAdminPageNumber * 10 - 10);
      management.schoolAdminPageNumber === currentPage &&
        dispatch(setFlagForFetch("SET_SCHOOL_ADMIN_FLAG"));
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedSchoolAdminId, management.schoolAdminPageNumber]);

  useEffect(() => {
    if (lastAddedId > 0 && !management.schoolAdminsLoading) {
      const element = document.getElementById("school-admin-data");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [management.schoolAdmins, management.schoolAdminsLoading]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSchoolInfoLoad(false);
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  const sendEmail = async (send: boolean) => {
    const res = await sendWelcomeEmails("schools", Number(schoolId), send);
    setEmailConfirmMessage(send ? "" : res.message);
    setEmailConfirmModal(!send);
  };

  const requestStl = (period: string) => {
    if (management?.stlSchoolLoading && period === "2023-24") {
      setTextAfterAnimation(true);
    } else {
      setTextAfterAnimation(false);
    }
    dispatch({
      type: ActionType.SET_STL_SCHOOL_LOADING,
      payload: true,
    });
  };

  const requestStlDistrict = async (data: string) => {
    const isPrevYear = data === "2023-24";
    dispatch({ type: ActionType.SET_SINGLE_STL_LOADING, payload: true });
    dispatch({ type: ActionType.SET_SINGLE_STL_PERIOD, payload: data });
    localStorage.setItem("stlSingleLoading", "true");
    const request = await requestStlReport(
      management.currentSchool?.district_id,
      isPrevYear
    );
    localStorage.setItem("singleId", request.data.data.id);
    if (management.currentSchool?.district_id) {
      localStorage.setItem(
        "district_id",
        management.currentSchool?.district_id
      );
    }
    dispatch({
      type: ActionType.SET_SINGLE_STL_ID,
      payload: request.data.data.id,
    });
    //
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.SET_STL_SCHOOL_LOADING,
        payload: false,
      });
    };
  }, []);

  return (
    <>
      {management?.stlSchoolLoading && userRole === "School Admin" && (
        <StlSchoolButton
          previous_year={
            management?.schoolStlPeriod === "2023-24" ? "true" : ""
          }
        />
      )}

      {management?.stlSingleLoading && userRole === "District Admin" && (
        <StlSingleButton />
      )}

      <div
        className="accordion__header school-detail-header open cursor-default"
        style={{ overflow: "unset" }}
      >
        <div className="accordion__header-heading">
          {(userRole === "Super Admin" || userRole === "District Admin") && (
            <BackButton onClick={() => navigate("/management")} />
          )}
          <h6>School: {management?.currentSchool?.school_name}</h6>
          {(userRole === "Super Admin" || userRole === "District Admin") && (
            <img
              onClick={() => doSetSchoolUpdateModal(management?.currentSchool)}
              src={EditIcon}
              alt="arrow"
              className="mx-3 mb-1"
            />
          )}
        </div>
        <div className="school-detail-btns">
          <div className="accordion__header-buttons">
            {(userRole === "School Admin" || userRole === "District Admin") && (
              <>
                {userRole === "School Admin" && (
                  <div className="accordion__header-btnContainer">
                    <button
                      className={`btn outline-btn outline-btn-lg`}
                      disabled={
                        !parseInt(management.currentSchool?.classrooms) ||
                        !management.currentSchool?.has_data
                      }
                      onClick={() =>
                        navigate(
                          `/management/report/summative-objective-analysis/${management.currentSchool?.district_id}?school_id=${management.currentSchool?.id}`
                        )
                      }
                    >
                      SOA Report
                    </button>
                  </div>
                )}
                {(userRole === "District Admin" ||
                  (userRole === "School Admin" &&
                    activity.schoolsList.length === 1)) && (
                  <>
                    {(management?.schoolStlExists &&
                      userRole === "School Admin") ||
                    (districtPrevExists && userRole === "District Admin") ? (
                      <div className="d-sm-flex align-items-center headerBtnContainer ms-3">
                        <ButtonDropDown
                          options={[
                            {
                              label: "Summative Test Log",
                              value: "Current",
                            },
                            {
                              label: "2023-24",
                              value: "2023-24",
                            },
                          ]}
                          onChange={(selectedValue: any) => {
                            if (userRole === "School Admin") {
                              dispatch({
                                type: ActionType.SET_SCHOOL_STL_PERIOD,
                                payload: selectedValue,
                              });
                              requestStl(selectedValue);
                            } else {
                              requestStlDistrict(selectedValue);
                            }
                          }}
                          biggerWidth={true}
                        />
                      </div>
                    ) : (
                      <div className="accordion__header-btnContainer">
                        <button
                          className="btn outline-btn outline-btn-lg"
                          onClick={() => {
                            if (userRole === "District Admin") {
                              requestStlDistrict("Current");
                            } else {
                              requestStl("Current");
                            }
                          }}
                        >
                          Summative Test Log
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {auth.currentUser?.role === "Super Admin" && (
              <div
                className="accordion__header-btnContainer"
                onClick={() => sendEmail(false)}
              >
                <button className="btn outline-btn outline-btn-lg">
                  Send Welcome Emails
                </button>
              </div>
            )}
            <div className="accordion__header-btnContainer">
              <button
                onClick={() => setAddModal(true)}
                className="btn outline-btn outline-btn-lg"
              >
                Add Admin
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="assessment">
        <div className="assessment__content">
          <section className="assessment__details">
            <div className="assessment__details-header">
              <p>Details</p>
            </div>
            <div className="assessment__details-wrapper-50">
              <div className="assessment__details-item">
                <p>
                  <b>Available Student Licenses:</b>
                </p>
                <p className="mx-2">
                  {management.currentSchool?.student_licenses -
                    management.currentSchool?.student_licenses_in_use}{" "}
                  / {management.currentSchool?.student_licenses}
                </p>
              </div>
              <div className="assessment__details-item">
                <p className="assessment__details-address">
                  <b>Address 1:</b>
                </p>
                <p className="mx-2"> {management.currentSchool?.address_1}</p>
              </div>
              <div className="assessment__details-item">
                <p className="assessment__details-address">
                  <b>Address 2:</b>
                </p>
                <p className="mx-2">{management.currentSchool?.address_2}</p>
              </div>
              <div className="assessment__details-item">
                <p className="assessment__details-address">
                  <b>City:</b>
                </p>
                <p className="mx-2">{management.currentSchool?.city}</p>
              </div>
              <div className="assessment__details-item">
                <p className="assessment__details-address">
                  <b>State:</b>
                </p>
                <p className="mx-2">{management.currentSchool?.state}</p>
              </div>
              <div className="d-flex justify-content-between zip-phone-wrapper">
                <div className="assessment__details-item mb-0">
                  <p className="assessment__details-address">
                    <b>Zip:</b>
                  </p>
                  <p className="mx-2">{management.currentSchool?.zip}</p>
                </div>
                <div className="assessment__details-item  mb-0">
                  <p className="assessment__details-phone-num">
                    <b>Phone:</b>
                  </p>
                  <p className="mx-2">{management.currentSchool?.phone}</p>
                </div>
              </div>
            </div>
          </section>
          <section className="assessment__table-wrapper">
            <div className="assessment__header-availablite">
              <p>Assessment Availability</p>
              {management.currentSchool &&
              management.currentSchool?.summatives_info?.filter(
                (item: any) =>
                  (item?.edit_date_type &&
                    item?.edit_date_type !== "DEFAULT") ||
                  (item?.monitor_date_type &&
                    item?.monitor_date_type !== "DEFAULT") ||
                  (item?.start_date_type && item?.start_date_type !== "DEFAULT")
              ).length
                ? userRole === "Super Admin" && (
                    <span className="pill-warning">Custom</span>
                  )
                : ""}
            </div>

            <div className="assessment__table-content-50">
              <table className="assessment__table">
                <thead>
                  <tr className="assessment__table-row">
                    <th className="assessment__table-name">Assessment Name</th>
                    <th className="assessment__table-monitor">Monitor</th>
                    <th className="assessment__table-edit">Edit Button</th>
                  </tr>
                </thead>
                <tbody>
                  {schoolInfoLoad ? (
                    <TableSkeleton
                      baseColor="#2a539143"
                      highlightColor="#5371b732"
                      columns={5}
                      count={limit}
                      height={36}
                    />
                  ) : (
                    ""
                  )}

                  {management.currentSchool &&
                  !schoolInfoLoad &&
                  management.currentSchool?.summatives_info ? (
                    management.currentSchool?.summatives_info.map(
                      (item: any, i: number) => {
                        return (
                          <tr
                            key={i}
                            className={"assessment__table-row"}
                            id={
                              lastAddedId === item.id ? "school-admin-data" : ""
                            }
                          >
                            <td
                              className={classNames(
                                i % 2 !== 0
                                  ? ""
                                  : "darker-row-border-first-child dark-row-blue"
                              )}
                            >
                              <div className="name-data-assessment">
                                {(item?.edit_date_type &&
                                  item?.edit_date_type !== "DEFAULT") ||
                                (item?.monitor_date_type &&
                                  item?.monitor_date_type !== "DEFAULT") ||
                                (item?.start_date_type &&
                                  item?.start_date_type !== "DEFAULT")
                                  ? userRole === "Super Admin" && (
                                      <span className="pill-circle"></span>
                                    )
                                  : ""}
                                <p>{item?.display_label}</p>
                              </div>
                            </td>
                            <td
                              className={classNames(
                                i % 2 !== 0 ? "" : "dark-row-blue"
                              )}
                            >
                              <div className="monitor-data-assessment-date">
                                <span className="pill-success">From</span>
                                <p className="text-center">
                                  {item?.start_date ? item?.start_date : "-"}
                                </p>
                                <span className="pill-until">Until</span>
                              </div>
                            </td>
                            <td
                              className={classNames(
                                i % 2 !== 0
                                  ? ""
                                  : "darker-row-border-last-child dark-row-blue"
                              )}
                            >
                              <div className="edit-data-assessment-dates">
                                <p className="text-center">
                                  {item?.monitor_end_date
                                    ? item?.monitor_end_date
                                    : "-"}
                                </p>
                                <span className="pill-until">Until</span>
                                <p className="text-center">
                                  {item?.edit_end_date
                                    ? item?.edit_end_date
                                    : "-"}
                                </p>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <tr>
                      {management.currentSchool?.summatives_info === 0 && (
                        <td colSpan={3} className="no-admin">
                          No Schools
                        </td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </section>
        </div>

        <div className="accordion__subheader accordion__border-top-bottom">
          <p className="shadow-text">School Admins</p>
        </div>

        <div className="management-table-container district-admin-table-container">
          <div className="table-responsive">
            <table className="management__table management__table_no-margin">
              <thead>
                <tr className="management__table-row">
                  <th className="management__table-admin-no-sort">
                    Admin Name
                  </th>
                  <th className="management__table-email">Email</th>
                  <th className="management__table-title">Title</th>
                  <th className="management__table-login">Last Login </th>
                  <th className="admins__table-admin">
                    Admin is <br /> Principal
                  </th>
                  {auth && auth.currentUser?.role === "Super Admin" && (
                    <th className="management__table-login">PIN</th>
                  )}
                  <th className="management__table-options">Options</th>
                </tr>
              </thead>
              <tbody>
                {management.schoolAdminsLoading ? (
                  <TableSkeleton
                    baseColor="#2a539143"
                    highlightColor="#5371b732"
                    columns={7}
                    count={limit}
                    height={36}
                  />
                ) : management.schoolAdmins &&
                  management?.schoolAdmins?.length ? (
                  management.schoolAdmins.map((item: any, i: number) => {
                    return (
                      <tr
                        key={i}
                        className={classNames(
                          "management__table-row",
                          i % 2 !== 0 ? "" : "dark-row-blue"
                        )}
                      >
                        <td
                          className="admin-data"
                          // className={classNames(
                          //   "admin-data",
                          //   i % 2 !== 0
                          //     ? ""
                          //     : "darker-row-border-first-child dark-row-blue"
                          // )}
                        >
                          {item?.last_name}, {item?.first_name}
                        </td>
                        <td
                          className={classNames(
                            "email-data",
                            i % 2 !== 0 ? "" : "dark-row-blue"
                          )}
                        >
                          {item?.email}
                        </td>
                        <td
                          className={classNames(
                            "title-data",
                            i % 2 !== 0 ? "" : "dark-row-blue"
                          )}
                        >
                          {item?.title}
                        </td>
                        <td
                          className={classNames(
                            "login-data",
                            i % 2 !== 0 ? "" : "dark-row-blue"
                          )}
                        >
                          {item?.last_login
                            ? moment(item?.last_login).format("M-D-YY")
                            : "-"}
                        </td>
                        <td
                          className={classNames(
                            "text-center",
                            i % 2 !== 0 ? "" : "dark-row-blue"
                          )}
                        >
                          <Toggle 
                             inputName="Is Principal"
                             ariaLabel="Is Principal"
                             isSmall= {true}
                             tabIndex={0} 
                             activeDisable = {userRole === "School Admin"}
                             labelClassName={`mb-0`} 
                             checked={item?.is_principal ? true : false}
                             onChange={(e) => {
                              userRole === "School Admin"
                                ? setHintModal(true)
                                : dispatch(
                                    updateIsPrincipalForAdmin(
                                      e.target.checked,
                                      item.id,
                                      management?.currentSchool?.id
                                    )
                                  );
                            }}
                          />
                        </td>
                        {auth && auth.currentUser?.role === "Super Admin" && (
                          <td
                            className={classNames(
                              "login-data",
                              i % 2 !== 0 ? "" : "dark-row-blue"
                            )}
                          >
                            {item?.support_pin || ""}
                          </td>
                        )}

                        <td
                          className={classNames(
                            i % 2 !== 0
                              ? ""
                              : "darker-row-border-last-child dark-row-blue"
                          )}
                        >
                          <div className="d-flex justify-content-start align-items-center">
                            <img
                              tabIndex={0}
                              onClick={() => doSetUpdateModal(item)}
                              src={EditIcon}
                              alt="Edit"
                              className="management__table-editBtn"
                            />
                            {auth &&
                            auth.currentUser?.role === "Super Admin" ? (
                              <img 
                                tabIndex={0}
                                onClick={() => doSetPasModal(item)}
                                src={RPIcon}
                                alt="Report"
                                className="management__table-editBtn"
                              />
                            ) : (
                              ""
                            )}
                            <img
                              tabIndex={0}
                              onClick={() => doSetDelModal(item)}
                              src={DeleteIcon}
                              alt="Delete"
                              className="management__table-editBtn"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    {management?.schoolAdmins?.length === 0 &&
                      !management.schoolAdminsLoading && (
                        <td colSpan={12} className="no-admin dark-table">
                          No Admins
                        </td>
                      )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Pagination
          totalCount={management.schoolAdminsCount}
          currentPage={currentPage}
          pageSize={limit}
          onPageChange={doChangePage}
        />
      </div>
      {deleteModal && (
        <SchoolAdminDeleteModal
          loading={management.schoolAdminDeleteLoading}
          isShow={deleteModal}
          headerText={`Remove Admin: ${currentItem?.first_name} ${currentItem?.last_name}`}
          bodyText={`This admin will be removed from the school but will remain a school admin in the district. To delete them entirely from APO, delete them from the district.`}
          onCancel={() => setDeleteModal(false)}
          onDelete={onDeleteSchoolAdmin}
        />
      )}
      {updateModal && (
        <SchoolAdminUpdateModal
          isShow={updateModal}
          is_super_principal={currentItem?.is_principal || null}
          first_name={currentItem?.first_name}
          last_name={currentItem?.last_name}
          input_email={currentItem?.email}
          input_title={currentItem?.title}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateSchoolAdmin(data)}
          loading={management.schoolAdminUpdateLoading}
        />
      )}
      {addModal && (
        <SchoolAdminAddModal
          school_id={Number(schoolId)}
          isShow={addModal}
          loading={management.schoolAdminAddLoading}
          onCancel={() => setAddModal(false)}
          onAdd={(data) => onAddSchoolAdmin(data)}
        />
      )}
      {schoolUpdateModal && management.currentSchool && (
        <SchoolUpdateModal
          isShow={schoolUpdateModal}
          school={management.currentSchool}
          onCancel={() => setSchoolUpdateModal(false)}
          onUpdate={(data) => onUpdateSchool(data)}
          loading={management.schoolUpdateLoading}
          superAdmins={management.schoolSuperAdmins}
          isSummativeShow={
            auth && auth.currentUser?.role === "Super Admin" ? true : false
          }
        />
      )}
      {resetPassModdal && (
        <ResetPasswordModal
          isShow={resetPassModdal}
          loading={management?.resetPwLoading}
          onCancel={() => setResetPassModdal(false)}
          onAdd={onCreatePassword}
          password={randomPass}
          title={`${currentItem?.first_name} ${currentItem?.last_name}`}
        />
      )}
      {emailConfirmModal && (
        <SendEmailConfirmationModal
          isShow={emailConfirmModal}
          onClose={() => {
            setEmailConfirmMessage("");
            setEmailConfirmModal(false);
          }}
          message={emailConfirmMessage}
          onYes={() => sendEmail(true)}
        />
      )}
      {openHintModal && (
        <PermissionHintModal
          isShow={openHintModal}
          onClose={() => setHintModal(false)}
          onYes={() => setHintModal(false)}
          message="Please contact the superintendent or Alpha Plus support to perfome this action."
        />
      )}
    </>
  );
};

export default SchoolDetails;
