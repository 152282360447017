import React from "react";

const ManagementIconActive = () => {
    const titleId = "managementIcon"
  return (
    <svg
      className="active"
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      aria-labelledby={titleId}
    >
        <title id={titleId}>Management</title>
      <path
        d="M18.2503 2.22864V17.606C18.2524 17.8476 18.2102 18.0872 18.1261 18.3109C18.042 18.5345 17.9178 18.7376 17.7607 18.9083C17.6036 19.079 17.4167 19.2139 17.2111 19.305C17.0055 19.396 16.7852 19.4415 16.5632 19.4389H2.43821C2.21602 19.4408 1.99568 19.3947 1.79004 19.3031C1.5844 19.2115 1.39757 19.0762 1.24045 18.9052C1.08334 18.7342 0.959069 18.5309 0.8749 18.3071C0.790731 18.0833 0.748344 17.8435 0.750101 17.6016V2.22864C0.747661 1.98666 0.789589 1.74658 0.873529 1.52249C0.957469 1.29841 1.08173 1.09485 1.239 0.923785C1.39627 0.752722 1.58337 0.617606 1.78931 0.526383C1.99526 0.435159 2.21588 0.389669 2.43821 0.392455H16.5662C16.7882 0.390105 17.0085 0.435925 17.214 0.527357C17.4196 0.618789 17.6063 0.753991 17.7631 0.925025C17.92 1.09606 18.0439 1.29948 18.1275 1.52334C18.2112 1.7472 18.2529 1.98699 18.2503 2.22864ZM16.7932 17.6016V2.22864C16.7919 2.16355 16.7676 2.10151 16.7253 2.05548C16.683 2.00945 16.626 1.98297 16.5662 1.98158H16.2032L11.8952 5.15413L9.50021 3.03293L7.10921 5.15413L2.80121 1.97832H2.43821C2.37841 1.97971 2.32141 2.00618 2.27911 2.05222C2.23681 2.09825 2.21249 2.16029 2.21121 2.22537V17.6016C2.21249 17.6667 2.23681 17.7288 2.27911 17.7748C2.32141 17.8208 2.37841 17.8473 2.43821 17.8487H16.5662C16.6264 17.8487 16.6842 17.8227 16.7267 17.7763C16.7693 17.73 16.7932 17.6672 16.7932 17.6016ZM6.61721 6.94012V8.51279H3.75021V6.94012H6.61721ZM6.61721 10.1029V11.6886H3.75021V10.1029H6.61721ZM7.05021 3.84049L9.16021 1.98267H4.53121L7.05021 3.84049ZM15.2502 6.94012V8.51279H7.59821V6.94012H15.2502ZM15.2502 10.1029V11.6886H7.59821V10.1029H15.2502ZM11.9502 3.84049L14.4702 1.98267H9.84421L11.9502 3.84049ZM15.2502 13.2743V14.8601H11.3712V13.2743H15.2502Z"
        fill="white"
      />
    </svg>
  );
};

export default ManagementIconActive;
