import { useSelector } from "react-redux";
import { ActivityResponse } from ".";
import AssessmentStats from "./assessmentStats";
import { RootState } from "redux/reducers/combine";
import LoadingSkeleton from "./loadingSkeleton";
import {  useEffect,  useState } from "react";
import moment from "moment";
import BackDrop from "components/common/backDrop";
import ScrollingText from "components/common/ScrollingText";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

const ActivityModal = ({
  activityData,
  loading,
  closeModal,
  onChangeClassroom,
  fieldName,
}: {
  closeModal: () => void;
  activityData: ActivityResponse[];
  loading?: boolean;
  onChangeClassroom: (val: any) => void;
  fieldName: any
}) => {
  const app = useSelector((state: RootState) => state);
  const districtSchools = app.activity.schoolsList;
  const classroomId = app.classroom.classroomId;
  const school_id = app.classroom.classroomSchoolId;
  const district_id = app.classroom.districtId;
  const districts = app.activity.districtsList;
  const [idToAnimate, setIdToAnimate] = useState<any>(0);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!app.classroom?.classrooms?.length) {
      return;
    }

    if (
      districts.length &&
      districtSchools.length &&
      district_id > 0 &&
      school_id > 0
    ) {
      const isOnlyOneClass = classrooms.length === 1;
      if (isOnlyOneClass && classroomId === 0) {
        sessionStorage.setItem(
          "c_classroom_id",
          JSON.stringify(classrooms[0].id)
        );
        onChangeClassroom({
          id: classrooms[0].id,
          fk_grade_id: classrooms[0].fk_grade_id,
          fk_subject_id: classrooms[0].fk_subject_id,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.classroom.classrooms]);

  useEffect(() => {
    const htmlTag = document.querySelector("html");
    if(htmlTag){
      htmlTag.style.overflow = "hidden";
    }
    document.body.style.overflow = 'hidden';

    const keyHandler = (e: any) => {
      e.key === 'Escape' && closeModal();
    }
    document.addEventListener("keydown", keyHandler);

    return () => {
      const htmlTag = document.querySelector("html");
      if(htmlTag){
        htmlTag.removeAttribute("style")
      }
      document.body.style.overflow = 'unset';
      document.removeEventListener("keydown", keyHandler);
    }
  },[closeModal])

  const classrooms = app.classroom.classrooms || [];

  const testType = Cookies.get("test_name")?.trim().split("-")[1].trim();
  const isMasterReport = pathname.includes("master-report");
  
  return (
    <>
      <div className={`activity-modal-container`}>
        <h2 className="modal-heading">Select a Class</h2>
        <div className={`activity-table`}>
          <div className="class-row-wrapper">
            {loading ? (
              <>
                <LoadingSkeleton />
                <LoadingSkeleton />
                <LoadingSkeleton />
              </>
            ) : (
              activityData.map((data: any, index: number) => {
                const animateClassName: boolean =
                  idToAnimate === data.classroom_id;
                const currClassroom: any = classrooms.find(
                  (item: any) => item.id === data.classroom_id
                );         
                if(Array.isArray(fieldName)){
                  if(!isMasterReport) {
                    if(testType==="A1")
                      currClassroom.isDisable = currClassroom[fieldName[0]]===false;
                    if(testType === "A2")
                        currClassroom.isDisable = currClassroom[fieldName[1]]===false
                    if(testType === "B")
                      currClassroom.isDisable = currClassroom[fieldName[2]]===false      
                }
                }else {
                  currClassroom.isDisable = currClassroom[fieldName]===false
                    }
                return (
                  <>
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        !currClassroom.isDisable && onChangeClassroom({
                          id: currClassroom.id,
                          fk_grade_id: currClassroom.fk_grade_id,
                          fk_subject_id: currClassroom.fk_subject_id,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          !currClassroom.isDisable && onChangeClassroom({
                            id: currClassroom.id,
                            fk_grade_id: currClassroom.fk_grade_id,
                            fk_subject_id: currClassroom.fk_subject_id,
                          });
                        }
                      }}
                      onMouseEnter={() => {
                        setIdToAnimate(currClassroom.id);
                      }}
                      onMouseLeave={() => {
                        setIdToAnimate(0);
                      }}
                      className={`select-class-row cursor-pointer accordion-classroom-animation ${currClassroom.isDisable ? "selectClassDisabledRow":""}`}
                    >
                      <div className="classRoom">
                        <p className="subHeader-fieldLabel">Classroom</p>
                        <ScrollingText
                          tag="p"
                          elementClassName={"class_name"}
                          animateFirstTime={true}
                          id={currClassroom.id}
                          animateName={animateClassName}
                        >
                          {currClassroom?.name || ""}
                        </ScrollingText>
                        {/* <p className="class_name">{currClassroom?.name || ""}</p> */}
                      </div>

                      <div className="hour-column">
                        {index === 0 ? (
                          <p className="subHeader-fieldLabel">Hour</p>
                        ) : (
                          <></>
                        )}
                        {currClassroom.hour ? (
                          <div className="chip">{currClassroom.hour}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="last-assessment">
                        <div className="subHeader-fieldLabel">
                          Last Assessment
                        </div>
                        <p className="lastAssessment-date">
                          {data.last_test_time
                            ? moment(data.last_test_time).format("M-D-YY")
                            : "-"}
                        </p>
                      </div>
                      <AssessmentStats
                        loading={loading || false}
                        isThreeDropdowns={false}
                        activityData={data}
                      />
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      </div>
      {/* <div onClick={() => closeModal()} className="activity-modal"></div> */}
      <BackDrop onClick={closeModal} />
    </>
  );
};

export default ActivityModal;
