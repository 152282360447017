import config from "config/config"
import { DiscussionsContextProvider } from "interfaces/discussions"
import { createContext } from "react"
import { useLocation } from "react-router-dom"
import Cookie from "js-cookie";


const defaultDiscussionsProvider: DiscussionsContextProvider = {
    isSuperAdmin: false, 
    urlToLoad: ''
}

export const DiscussionsContext = createContext<DiscussionsContextProvider>(defaultDiscussionsProvider);

const DiscussionsProvider = ({ children }: any) => {

    const location = useLocation();

    let isSuperAdmin = Cookie.get("role") === "Super Admin";

    if (isSuperAdmin &&  location.pathname !== "/discussions/admin") {
        isSuperAdmin = false;
    }

    let urlToLoad = config.defaults.discussions_url;

    if (isSuperAdmin) {
        urlToLoad = `${config.defaults.discussions_url}/admin`;
    }

    return <DiscussionsContext.Provider value={{
        isSuperAdmin,
        urlToLoad
    }}>
        {children}
    </DiscussionsContext.Provider>

}
export default DiscussionsProvider;