import config from "config/config";
import { io } from "socket.io-client";
import Cookies from "js-cookie";

let instance: any;


const SingletonFactory = (function(){
    const SingletonClass = () => {
        const token = Cookies.get('super-admin-token');
        const io_object = {
            transports: ["websocket"],
            auth: { 
                token 
            }
        }

        return io(config.defaults.api_url, io_object);
    }
  
    return {
      getInstance: () => {
        if (!instance) {
          instance = SingletonClass();
        }

        return instance;
      }
    };
})();



export default SingletonFactory;