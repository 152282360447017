/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import OldHeader from "./old_header";
import Footer from "./footer";
import cookie from "js-cookie";
import { useNavigate } from "react-router";
import SocketChatProvider from "contexts/socketContext";
import { getDistrictsAndSchools } from "redux/actionCreators/activity";
import ChangePasswordModal from "components/change-password/change-password-modal";
import jwtDecode from "jwt-decode";
import { clearDataOnLogout } from "redux/actionCreators/auth";
import { RootState } from "redux/reducers/combine";
import { useLocation } from "react-router-dom";
import { useShowSurvey, useTooltipSeen } from "hooks/useShowSurvey";
import Cookies from "js-cookie";
import FeedbackSurvey from "components/satisfaction-survey";

let timeoutId: any = null;

const Theme: React.FC<any> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { getShowSurvey } = useShowSurvey();
  const {isTooltipSeen} = useTooltipSeen();

  const userRole = cookie.get("role");
  const token = cookie.get("super-admin-token");
  const [changeModal, setChangeModal] = useState(false);
  const [satisfactionSurvey, setSatisfactionSurvey] = useState(false);
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const init = async () => {
      const callDistrictsApi =
        window.location.href.includes("management") &&
        !window.location.href.includes("import/class") &&
        userRole === "Super Admin";
      if (token && !callDistrictsApi) {
        await dispatch(getDistrictsAndSchools());
      }
    };
    init();
  }, []);

  const handleScroll = (e: any) => {
    if (e?.target?.body?.classList?.contains("on-scrollbar")) {
      e.target.body.classList.remove("on-scrollbar");
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        document.body.classList.add("on-scrollbar");
        // setScrollBar(false);
      }, 1000);
    }
  };

  useEffect(() => {
    document.body.classList.remove("on-scrollbar");
  }, [window.location]);

  useEffect(() => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      document.body.classList.add("on-scrollbar");
    }, 1000);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    if (!token) {
      dispatch(clearDataOnLogout());
      navigate("/");
    } else {
      const decodedToken: any = jwtDecode(token);
      if (decodedToken?.exp * 1000 < Date.now()) {
        dispatch(clearDataOnLogout());
        navigate("/");
      }
    }
  }, [token, navigate]);

  useEffect(() => {
    if (!token) {
      return;
    }

    const decodedToken: any = jwtDecode(token);
    const forgot = decodedToken?.forgot_password;

    if (forgot) {
      //! show change password modal and force
      setChangeModal(true);
    }

    const isAllTooltipsSeen =
      isTooltipSeen() && Cookies.get("showSurvey") !== "false";
    const isSatisfactionSurvey = getShowSurvey(isAllTooltipsSeen);

    if (isSatisfactionSurvey) {
      setSatisfactionSurvey(true);
    }
  }, [token, auth]);

  const isOld = location.pathname.includes("_old");
  return (
    <div className="container-xl page-wrapper">
      <SocketChatProvider>
        {isOld ? <OldHeader /> : <Header />}
        {props.children}
        <Footer />
      </SocketChatProvider>
      {changeModal && (
        <ChangePasswordModal
          isShow={changeModal}
          closeModal={() => setChangeModal(false)}
          force={true}
        />
      )}
      {satisfactionSurvey && (
        <FeedbackSurvey
          satisfactionSurvey={satisfactionSurvey}
          closeModal={() => setSatisfactionSurvey(false)}
        />
      )}
    </div>
  );
};

export default Theme;
