import { useContext, useEffect, useRef, useState } from "react";
import cookie from "js-cookie";
// import SelectDropdown from "assets/img/profile-dropdown-icon.svg";
import { ChatSocketContext } from "contexts/socketContext";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import UserDropdownMenu from "./UserDropdownMenu";
// import ActionType from "redux/actionTypes";
// import { useShowSurvey } from "hooks/useShowSurvey";

interface Props {
    userLogout?: (event: any) => void;
    containerClassName?: string;
    setProfileColor?: (color: any) => void;
}

interface RootState {
    auth: any;
  }

const UserDropDown: React.FC<Props> = (props) => {
    const app = useSelector((state: RootState) => state);
    const menuRef = useRef<any>();
    // const dispatch = useDispatch();

    const {headerNotification,settingHeaderState} = useContext(ChatSocketContext) || {};
    // const { userLogout, containerClassName='header__user', setProfileColor} = props;
    const [openDropdown, setOpenDropdown] = useState(false);
    const fullName: any = `${cookie.get("first_name")} ${cookie.get(
        "last_name"
    )}`;

    // const supportPin: any = `${cookie.get("support_pin")}`;

    const profileColorCode: any = `${cookie.get("profile_color_code")}`;
    

    const colorProfile = app.auth.colorProfile;
    const colorIndex = colorProfile?.colors?.findIndex((color: any) => color.id === colorProfile.selected.fk_color_code_id);
    const selectedColor = colorProfile?.colors?.length > 0 && colorProfile.colors[colorIndex]?.code ? colorProfile.colors[colorIndex].code : profileColorCode

    // const [setSelectedColor] = useState(0);

    const initials = fullName.match(/\b(\w)/g).join("");
    const userRole = cookie.get("role");

    // const onClickSurveyModal = () => {
    //     setSatisfactionSurvey(true);
    // }

    useEffect(() => {
        if (colorProfile) {
            // setSelectedColor(colorProfile.selected.fk_color_code_id);
            
        }
    }, [colorProfile]);

    const onClickDropdown = () => {
        setOpenDropdown(prev => !prev)
    }

    // const headerNotificationToDisplay = headerNotification ? headerNotification : app?.auth?.colorProfile?.support_ticket_notification_count;

    useEffect(() => {
        settingHeaderState(app?.auth?.colorProfile?.support_ticket_notification_count || null);
    }, [app?.auth?.colorProfile?.support_ticket_notification_count, settingHeaderState]);

    useEffect(() => {
        const handler = (event: any) => {
            if(menuRef.current?.contains(event.target)){
                return;
            }

            setOpenDropdown(false);
        }

        window.addEventListener("click", handler, false);

        return () => {
            window.removeEventListener("click", handler, false);
        }
    },[]);

    return (
        <div ref={menuRef} className="dropDown-container">
            <button type="button" tabIndex={0} onClick={onClickDropdown} className="header-dropDown">
                <div className="user-name-initials">
                    <div className="initials" style={{ 'backgroundColor': selectedColor }}>
                        {initials}
                        {headerNotification && <span className={`notification-count`}>{headerNotification > 99 ? '99+': headerNotification}</span>}
                    </div>
                    <div className="userName-classroomAdmin">
                        <p className="user-name">{fullName}</p>
                        <p className="admin">{userRole}</p>
                    </div>
                </div>
                <div className="dropDown-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="11" viewBox="0 0 19 11" fill="none">
                        <path d="M18.455 0.279569C18.3873 0.205888 18.307 0.147441 18.2185 0.107564C18.1301 0.0676879 18.0353 0.0471637 17.9395 0.0471637C17.8438 0.0471637 17.749 0.0676879 17.6605 0.107564C17.5721 0.147441 17.4917 0.205888 17.424 0.279569L9.84903 8.50426C9.71214 8.65313 9.52654 8.73675 9.33302 8.73675C9.13951 8.73675 8.95392 8.65313 8.81703 8.50426L1.24403 0.27848C1.10718 0.129826 0.921668 0.0464275 0.728318 0.0466312C0.534968 0.0468357 0.349615 0.130626 0.213028 0.279569C0.0764423 0.428512 -0.000186659 0.630407 3.41454e-07 0.84084C0.000188341 1.05127 0.0771691 1.25301 0.21402 1.40166L7.78703 9.62417C8.19757 10.0698 8.75371 10.32 9.33353 10.32C9.91334 10.32 10.4695 10.0698 10.88 9.62417L18.453 1.3984C18.5891 1.25002 18.6657 1.04907 18.6661 0.839431C18.6665 0.629794 18.5906 0.428516 18.455 0.279569Z" fill="#4788FF"/>
                    </svg>
                </div>
            </button>
            
            <UserDropdownMenu
                openDropdown={openDropdown}
                closeMenu={() => setOpenDropdown(false)}
            />
        </div>
    )
}

export default UserDropDown;