/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import SchoolDetails from "components/management/school-details";
import ClassAdmins from "components/management/class-admins";
import Classes from "components/management/classes";
import Students from "components/management/students";
import { getSchoolBreadcrumb } from "redux/actionCreators/management";
import cookie from "js-cookie";

const SchoolPage: React.FunctionComponent<IPage> = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [first, setfirst] = useState(false);
  const { auth } = useSelector((state: any) => state);
  const userRole = cookie.get("role");
  const { management, activity } = useSelector(
    (state: any) => state
  );
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0);
  },[]);
 
  useEffect(() => {
    dispatch(getSchoolBreadcrumb(Number(id)));
    logging.info(`Loading ${props.name}`);
  }, [props.name, dispatch, id]);

  useEffect(() => {
    if(userRole === 'School Admin' && management.school_m_id > 0 && activity.schoolsList.length ){
      const selectedSchool = activity.schoolsList.find(((item: any) => item.id  === management.school_m_id))
      navigate(`/management/school/${selectedSchool?.id}`)
     }
  }, [management.school_m_id,activity.schoolsList]);

  return (
    <>
      <Theme>
        <div className="management-st-wrap management-accordian text-white">
          {auth.currentUser?.role !== 'School Admin'&& <Breadcrumbs page="school" /> }
          {id && <SchoolDetails schoolId={id || ""} />}
          {id && (
            <ClassAdmins schoolId={id || ""} page="school" first={first} />
          )}
          {id && (
            <Classes
              schoolId={id || ""}
              page="school"
              setfirst={() => setfirst(!first)}
            />
          )}
          {id && <Students page="school" schoolId={id || ""} />}
        </div>
      </Theme>
    </>
  );
};

export default SchoolPage;
