import { HeaderSkeleton, FooterSkeleton } from "components/common/skeleton/ReportsSkeleton";
import ToolTip, { ObjectiveTooltipContent } from 'components/common/Tooltip';

interface Props{
    answers: any;
    questionId: number;
    totalCorrect: any;
    totalIncorrect: any;
    objectiveNumber: string;
    isLoading: boolean;
    totalStudents: any
    total_students: any;
    objective_description: string,
    objective_name: string
}

const Footer = ({ objective_description, objective_name, answers, questionId, totalCorrect, totalIncorrect, totalStudents, isLoading, objectiveNumber, total_students}: Props) => {

    return (
        <div id="footer" className={`report__footer formative-footer ${totalStudents.length > 15 ? 'move-up' : ''}`}>
            <div className={`report__total justify-content-center`}>
                <div className="report__total-title formative-title">Correct Answer</div>
                <div className="report__total-count">
                    {!isLoading ? answers.map((answer:any) => {
                        return <span className={`${questionId ? answer.id === questionId ? '' : 'hidden' : ''} report__total-total answer`}>
                            {answer.info}
                        </span>
                    })
                    :<div style={{transform: 'translateX(-5.5px)'}}>
                       <FooterSkeleton rowColumns={5} />
                    </div>
                }
                </div>
                <div className="report__numbers-attempt"></div>
                <div className="report__numbers-score formative-empty"></div>
            </div>

            <div className={`report__total justify-content-center radius ${isLoading ? '' : 'dark-gray'}`}>
                <div className="report__total-title formative-title">Total Students</div>
                <div className="report__total-count">
                    {!isLoading && Array.isArray(total_students) ? total_students?.map((count: any) => {
                        return (
                            <span className={`${questionId ? count.question_id === questionId ? '' : 'hidden' : ''} report__total-total`}>
                                {count.correct}
                            </span>
                            
                        )
                    })
                    :<div style={{transform: 'translateX(-5.5px)'}}>
                        <FooterSkeleton rowColumns={5} />
                    </div>
                }
                </div>
                <div className="report__numbers-attempt"></div>
                <div className="report__numbers-score formative-empty"></div>
                
            </div>

            <div className={`report__total justify-content-center`}>
                
                <div className="report__total-title formative-title">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="report__total-rectangle high"></span>
                        <span>Total Correct</span>
                    </div>
                </div>
                <div className="report__total-count">
                    {!isLoading ? totalCorrect?.map((count: any) => {
                        return (
                            <span className={`${questionId ? count.question_id === questionId ? '' : 'hidden' : ''} report__total-total`}>
                                {count.correct}
                            </span>
                        )
                    })
                    :<div style={{transform: 'translateX(-5.5px)'}}>
                        <FooterSkeleton rowColumns={5} />
                    </div>
                }
                </div>
                <div className="report__numbers-attempt"></div>
                <div className="report__numbers-score formative-empty"></div>
                
            </div>
            
            <div className={`report__total justify-content-center ${isLoading ? '' : 'dark-after'}`}>
                <div className="report__total-title formative-title incorrect">
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="report__total-rectangle low"></span>
                        <span>Total Incorrect</span>
                    </div>
                </div>
                <div className="report__total-incorrect">
                    {!isLoading ? totalIncorrect?.map((count: any) => {
                        return (
                            <div className="report__total-incorrectContainer">
                                <span className={`${questionId ? count.question_id === questionId ? '' : 'hidden' : ''} report__total-total`}>
                                    {count.correct}
                                </span>
                                <span className={`report__total-empty ${questionId ? count.question_id === questionId ? '' : 'd-none' : ''}`}></span>
                                <span className={`${questionId ? count.question_id === questionId ? '' : 'hidden' : ''} report__total-objective`}>
                                    <ToolTip
                                        content={<ObjectiveTooltipContent
                                            name={objective_name}
                                            number={objectiveNumber}
                                            description={objective_description}
                                        />
                                    }>
                                        <span className={`${questionId ? count.question_id === questionId ? 'font-bold' : '' : ''} report__total-objectiveData`}>
                                            {objectiveNumber}
                                        </span>
                                    </ToolTip>
                                </span>
                            </div>
                        )
                    })
                    : <div style={{transform: 'translateX(-5.5px)'}}>
                        <HeaderSkeleton rowColumns={5} height={104} />
                    </div>
                }
                </div>
                <div className="report__numbers-attempt"></div>
                <div className="report__numbers-score formative-empty"></div>
            </div>
        </div>
    )
}

export default Footer;