import React, { useEffect } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import CsvFileUploader from "components/ClassImport/CsvFileUploader/CsvFileUploader";
import CsvResult from "components/ClassImport/CsvResults/CsvResult";
import cookie from "js-cookie";

const ClassBulkUpload: React.FunctionComponent<IPage> = (props) => {
  const userRole = cookie.get("role");

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      {userRole === "Super Admin" && (
        <Theme>
          <div className="management-st-wrap management-accordian text-white">
            <CsvFileUploader />
            <CsvResult />
          </div>
        </Theme>
      )}
    </>
  );
};

export default ClassBulkUpload;
