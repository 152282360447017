/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { LimitSelect } from "components/common/selectComponent";
import { useDispatch, useSelector } from "react-redux";
import { SuperAdminAddModal } from "./management-add-modal";
import { SuperAdminUpdateModal } from "./management-update-modal";
import { SuperAdminDeleteModal } from "./management-delete-modal";
import WelcomeModal from "./modals/welcomeModal";
import {
  getSuperAdmins,
  addSuperAdmin,
  deleteSuperAdmin,
  updateSuperAdmin,
  setFlagForFetch,
  downloadAccountsExcel
} from "../../redux/actionCreators/management";
import Pagination from "../pagination/pagination";
import SearchIcon from "components/common/svgs/search-icon";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import PencilIconManagement from "components/common/svgs/pencil-icon-management";
import TrashIcon from "components/common/svgs/trash-icon";
import useSortBy from "hooks/useSortBy";

interface RootState {
  management: any;
}

const SuperAdmins: React.FC<{}> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const management = useSelector((state: RootState) => state.management);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [showWelcomeModal, setWelcomeModal] = useState(false);
  // const [skeletonLoad, setSkeletonLoad] = useState(true);
  
  const {sortBy, OrderByColumn, sortDataBy} = useSortBy("last_name", "ASC");
  
  // const [sortBy, setSortBy] = useState("last_name");
  // const [OrderByColumn, setOrderBy] = useState("ASC");
  const [lastAddedId, setLastAddedId] = useState(0);

  useEffect(() => {
    dispatch(getSuperAdmins(offSet, limit, searchTerm, sortBy, OrderByColumn));
  }, [
    offSet,
    limit,
    management.superAdminsFlag,
    dispatch,
    sortBy,
    OrderByColumn,
  ]);

  const handleSearch = useCallback(
    _.debounce((val: any) => {
      console.log(val);
      setCurrentPage(1);
      setOffSet(0);
      dispatch(getSuperAdmins(0, limit, val, sortBy, OrderByColumn));
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (management.lastAddedSuperAdminId > 0) {
      setLastAddedId(management.lastAddedSuperAdminId);
      setSearchTerm("");
      dispatch(
        getSuperAdmins(
          0,
          10,
          "",
          sortBy,
          OrderByColumn,
          management.lastAddedSuperAdminId
        )
      );
    }
    if (management.superAdminPageNumber > 0) {
      setLimit(10);
      setCurrentPage(management.superAdminPageNumber);
      setOffSet(management.superAdminPageNumber * 10 - 10);
      management.superAdminPageNumber === currentPage &&
        dispatch(setFlagForFetch("SET_SUPER_ADMIN_FLAG"));
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedSuperAdminId, management.superAdminPageNumber]);

  useEffect(() => {
    if (lastAddedId > 0 && !management.superAdminsLoading) {
      const element = document.getElementById("super-admin-data");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [management.superAdmins, management.superAdminsLoading]);

  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const doSetUpdateModal = (item: any) => {
    setCurrentItem(item);
    setUpdateModal(true);
  };

  const setPreviousPage = () => {
    if (currentPage > 1 && management.superAdmins.length === 1) {
      doChangePage(currentPage - 1);
    } else {
      dispatch(setFlagForFetch("SET_SUPER_ADMIN_FLAG"));
    }
  };

  const onDeleteSuperAdmin = () => {
    dispatch(deleteSuperAdmin(currentItem.id, setDeleteModal, setPreviousPage));
  };

  const onUpdateSuperAdmin = (data: any) => {
    dispatch(updateSuperAdmin(currentItem.id, data, setUpdateModal));
  };

  const onAddSuperAdmin = (data: any) => {
    dispatch(addSuperAdmin(data, setAddModal));
  };

  const doChangePage = (pageNo: number) => {
    let newOffset = pageNo * limit - limit;
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };

  
  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setSkeletonLoad(false);
    // }, 800);

    return () => {
      // clearTimeout(timer);
      // setSkeletonLoad(true);
    };
  }, [management.superAdminsLoading]);

  return (
    <div className="management">
      <div className="management-wrap">
        <div className="section-header super-admin">
          <div className="section-header-title">
            <h3>Super Admins</h3>
          </div>

          <div className="section-header-buttons super-admin">
            <div className="section-header-btnContainer super-admin">
              <button
                type="button"
                tabIndex={0}
                onClick={()=> navigate('/super-admin/professional-development')}
                className="btn outline-btn outline-btn-lg"
              >
                PD Admin
              </button>
            </div>
            <div className="accordion__header-btnContainer section-header-btnContainer super-admin">
              <button
               type="button"
               tabIndex={0}
                  onClick={()=>dispatch(downloadAccountsExcel())}
                  className="btn outline-btn outline-btn-lg"
                >
                  Export Accounts
                </button>
            </div>
            <div className="section-header-btnContainer super-admin">
              <button
               type="button"
               tabIndex={0}
                className="btn outline-btn outline-btn-lg"
                onClick={() => setWelcomeModal(true)}
              >
                Edit Welcome
              </button>
            </div>
            <div className="section-header-btnContainer super-admin">
              <button
               type="button"
               tabIndex={0}
                onClick={() => navigate("/management/objectives")}
                className="btn outline-btn outline-btn-lg"
              >
                Objectives
              </button>
            </div>
            <div className="section-header-btnContainer super-admin">
              <a
                target="_blank"
                tabIndex={0}
                className="btn outline-btn outline-btn-lg"
                href="/cms/select-assessment"
              >
                CMS
              </a>
            </div>
            <div className="section-header-btnContainer super-admin">
              <button
               type="button"
               tabIndex={0}
                onClick={() => setAddModal(true)}
                className="btn outline-btn outline-btn-lg"
  
              >
                Add Admin
              </button>
            </div>
          </div>
        </div>
        <div className="section-search">
          <div className="section-search-field animated">
            <div className="icon">
             
              <SearchIcon title={"Search Admins"} />
            </div>
              
            <input
              ref={inputRef}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch(e.target.value);
              }}
              type="text"
              className="form-control"
              placeholder="Search Admins"
            />
          </div>

          <div className="d-flex align-items-center">
            <span>Show</span>
            <LimitSelect
              disableHandler={management?.superAdmins?.length < 10}
              onChangeHandler={(e: any) => setLimit(Number(e?.value))}
            />
            <span>Admins</span>
          </div>
        </div>
        <div className="management-table-container">
          <div className="table-responsive">
            <table className="management__table">
              <thead>
                <tr className="management__table-row">
                  <th
                    className="management__table-admin"
                    role="button"
                    tabIndex={0}
                    onClick={() => sortDataBy("last_name")}
                    onKeyDown={(e) => e.key === "Enter" && sortDataBy("last_name")}
                  >
                    Admin Name
                    {sortBy === "last_name" && (
                      <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#fff"/>
                    )}
                  </th>
                  <th
                    className="management__table-email"
                    role="button"
                    tabIndex={0}
                    onClick={() => sortDataBy("email")}
                    onKeyDown={(e) => e.key === "Enter" && sortDataBy("email")}
                  >
                    Email
                    {sortBy === "email" && (
                      <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#fff"/>
                    )}
                  </th>
                  <th className="management__table-title">Title</th>
                  <th
                    role="button"
                    tabIndex={0}
                    className="management__table-login"
                    onClick={() => sortDataBy("last_login")}
                    onKeyDown={(e) => e.key === "Enter" && sortDataBy("last_login")}
                  >
                    Last Login{" "}
                    {sortBy === "last_login" && (
                   
                      <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#fff"/>
                    )}
                  </th>
                  <th className="management__table-options">Options</th>
                </tr>
              </thead>
              <tbody>
                {/* {skeletonLoad ? (
                  <TableSkeleton columns={5} count={limit} height={36} />
                ) : (
                  ""
                )} */}
                {
                
                management.superAdminsLoading ? 
                <TableSkeleton baseColor="#2a539143" highlightColor="#5371b732" columns={5} count={limit} height={36} />
                : management?.superAdmins?.length ? (
                  management.superAdmins.map((item: any, i: number) => {
                    return (
                      <tr
                        key={i}
                        className={
                          lastAddedId === item.id
                            ? "management__table-row highlight-row"
                            : i % 2 !== 0
                            ? "management__table-row "
                            : "management__table-row dark-row-blue"
                        }
                        id={lastAddedId === item.id ? "super-admin-data" : ""}
                      >
                        <td className="admin-data">
                          {item?.last_name}, {item?.first_name}
                        </td>
                        <td className="email-data">{item?.email}</td>
                        <td className="title-data">{item?.title}</td>
                        <td className="login-data">
                          {item?.last_login
                            ? moment(item?.last_login).format("M-D-YY")
                            : "-"}
                        </td>
                        <td className="option-data-col">
                          <div>
                            <button 
                             tabIndex={0}
                             type="button"
                             onKeyDown={(e: any) => {
                              if (e.key === "Enter") {
                                doSetUpdateModal(item)
                              }
                            }}
                            onClick={()=>doSetUpdateModal(item)}
                              className="btn management__table-editBtn">
                              <PencilIconManagement fill="#fff"  />
                            </button>
                           
                            
                            <button  
                              tabIndex={0}
                             type="button"
                             onKeyDown={(e: any) => {
                              if (e.key === "Enter") {
                                doSetDelModal(item)
                              }
                            }}
                            onClick={() => doSetDelModal(item)}
                              className="btn management__table-editBtn">
                              <TrashIcon fill="#fff" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    {management?.superAdmins?.length === 0 && !management.superAdminsLoading && (
                      <td colSpan={5} className="no-admin dark-table">
                        No Admins
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        totalCount={management.superAdminsCount}
        currentPage={currentPage}
        pageSize={limit}
        onPageChange={doChangePage}
      />
      {deleteModal && <SuperAdminDeleteModal
        loading={management.superAdminsDeleteLoading}
        isShow={deleteModal}
        headerText={`Delete ${currentItem?.first_name} ${currentItem?.last_name}`}
        bodyText={`Are you sure you want to delete this admin ?`}
        onCancel={() => setDeleteModal(false)}
        onDelete={onDeleteSuperAdmin}
      />
      }
      {addModal && (
        <SuperAdminAddModal
          isShow={addModal}
          loading={management.superAdminsAddLoading}
          onCancel={() => setAddModal(false)}
          onAdd={(data) => onAddSuperAdmin(data)}
        />
      )}
      {updateModal && (
        <SuperAdminUpdateModal
          isShow={updateModal}
          first_name={currentItem?.first_name}
          last_name={currentItem?.last_name}
          input_email={currentItem?.email}
          input_title={currentItem?.title}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateSuperAdmin(data)}
          loading={management.superAdminsUpdateLoading}
        />
      )}

      {showWelcomeModal && (
        <WelcomeModal show={showWelcomeModal} setModalShow={setWelcomeModal} />
      )}
    </div>
  );
};

export default SuperAdmins;
