/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Spinner} from "react-bootstrap";
import {useDropzone} from "react-dropzone";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import Warning from "assets/img/warning-icon.png";
import {cancelCsv, importData, importDataStatus, uploadCsv,} from "redux/actionCreators/class-csv-import";
import SuccessModal from "../SuccessModal";
import ManagementDropDowns from "components/theme/managementDropdowns";
import {
    adminDistricts,
    getDistrictsAndSchools,
} from "redux/actionCreators/activity";
import cookie from "js-cookie";
import ActionType from "redux/actionTypes";

interface RootState {
    classCsvImport: any;
    activity: any;
}

const CsvFileUploader = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {classCsvImport} = useSelector((state: RootState) => state);
    const [validationError, setValidationError] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [startInterval, setStartInterval] = useState<boolean>(false);
    const [pingInterval, setPingInterval] = useState<any>(null);
    const [classData, setData] = useState<any>(null);
    const [importStatus, setImportStatus] = useState<any>('');
    const [districtId, setDistrictId] = useState(0);
    const [districtName, setDistrictName] = useState('');
    const [selectDisabled, setSelectDisabled] = useState(false);

    const app = useSelector((state: RootState) => state);
    const districts = app.activity.districtsList;
    const userRole = cookie.get("role");
    const { pathname } = useLocation();
    const isOtherScreen =
        pathname.indexOf("monitor") > -1 ||
        pathname.indexOf("answer") > -1 ||
        pathname.indexOf("report") > -1;

    useEffect(() => {
        const init = async () => {
            if (!districts.length) {
                await dispatch(getDistrictsAndSchools());
            }
        };
        init();
        return (() => {
            dispatch(cancelCsv())
        })
    }, []);

    const onDrop = useCallback(
        (acceptedFiles: any) => {
            setValidationError("");
            // console.log(acceptedFiles[0], "ANDAR KA CHALA");
            dispatch(uploadCsv(acceptedFiles[0], districtId));
            setSelectDisabled(true);
        },
        [districtId]
    );
    console.log('++++', isSuccess, importStatus);

    useEffect(() => {
        let errorsData =
            classCsvImport &&
            classCsvImport?.csvResults &&
            classCsvImport?.csvResults?.filter(
                (result: any) =>
                    result.error ||
                    result.classrooms?.filter((classroom: any) => classroom.error).length
            );

        if (errorsData && errorsData.length && !classCsvImport.importDataSuccess) {
            setValidationError("Still some errors please resolve");
        } else {
            setValidationError("");
            setData(classCsvImport.csvResults);
            console.log(classData);
            setIsSuccess(classCsvImport.importDataSuccess);
        }
    }, [classCsvImport?.csvResults]);

    useEffect(() => {
        if (!classCsvImport.importDataSuccess) {
            setValidationError("Still some errors please resolve");
        } else {
            setImportStatus(classCsvImport.status);
            setValidationError("");
            setData(classCsvImport.csvResults);
            setIsSuccess(classCsvImport.importDataSuccess);

        }
    }, [classCsvImport?.importDataSuccess]);

    useEffect(() => {

        let intervel: any;
        console.log(startInterval, classCsvImport?.status, '++++');
        if (!startInterval && (classCsvImport?.status === 'success' || classCsvImport?.status === 'error')) {
            setImportStatus(classCsvImport.status);
            setIsSuccess(true);
        }

        if (classCsvImport?.statusId && !startInterval && classCsvImport?.status !== 'error' && classCsvImport?.status !== "success" && classCsvImport?.status !== "pending") {
            setStartInterval(true);
            intervel = setInterval(() => {
                dispatch(
                    importDataStatus(classCsvImport.statusId)
                );
            }, 3000);
            setPingInterval(intervel)
        }

        if (classCsvImport?.status === 'success' || classCsvImport?.status === 'error') {
          const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

            // Clear any timeout/interval up to that id
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }
            setSelectDisabled(false);
            setStartInterval(false);
            clearInterval(pingInterval)
            clearInterval(intervel)
        }
        return () => {
            if (classCsvImport?.status === 'success' || classCsvImport?.status === 'error') {
                setStartInterval(false);
                clearInterval(pingInterval)
                clearInterval(intervel)
            }
        }
    }, [classCsvImport?.status]);
    // useEffect(()=>{
    //   console.log('/*-/',classCsvImport?.status);

    //   if (classCsvImport?.status === 'success' || classCsvImport?.status === 'error') {
    //     setStartInterval(false);
    //     clearInterval(pingInterval)
    // }
    // })

    const {getRootProps, getInputProps, open} = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        multiple: false,
        onDrop,
    });

    const loadDistrictOptions = async (inputValue: string) => {
        const results = await adminDistricts({
            params: {
                search: inputValue,
            },
        });

        return results.data.data.districts.map((x: any, i: any) => {
            x.value = x.id;
            x.label = x.name;
            return x;
        });
    };

    const onDistrictChange = (e: any, apply: any) => {
        const inputValue = parseInt(e.id);
        if (!inputValue) {
            setDistrictId(0);
        } else {
            localStorage.setItem('import_district_name', e.district_name);
            localStorage.setItem('import_district_id', e.id);
            setDistrictName(e.district_name)
            setDistrictId(inputValue);
        }
    };

    const doImportData = () => {
        dispatch({
            type: ActionType.IMPORT_DATA_ERROR_STATUS,
            payload: { status:'',},
        });
        setValidationError("");
        console.log(
            classCsvImport.csvResults,
            "---------------------|||||||||||-------------"
        );
        let errorsData = classCsvImport.csvResults.filter(
            (school: any) =>
                school.classrooms.filter(
                    (classroom: any) =>
                        classroom.error ||
                        classroom.students.filter((student: any) => student.error).length
                ).length
        );
        console.log(errorsData, "CHECK..............");
        if (errorsData.length) {
            setValidationError("Still some errors please resolve");
        } else {
            dispatch(
                importData(classCsvImport.csvResults, classCsvImport.csvUrl, districtId, classCsvImport.statusId)
            );
        }
    };

    return (
        <>
            <div className="accordion__header student-scv open taller cursor-default">
                <div className="accordion__header-heading">
                    <span
                        tabIndex={0} role="button" onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                                navigate(-1)
                            }
                        }} className="accordion__header-button" onClick={() => navigate(-1)}>
                        <img

                            src={ArrowIcon}
                            alt="arrow"
                            className="rotate-90"
                        />
                    </span>
                    <h6>Class and Student CSV Upload</h6>
                </div>
                <div
                    className="header__dropdown-inner-csv"
                >
                    <div
                        className="d-flex flex-column flex-sm-row flex-md-row  justify-content-between align-items-center header__dropdown-list">
                        <ul className="d-flex flex-column flex-sm-row flex-md-row  justify-content-center align-items-center">
                            <ManagementDropDowns
                                districts={districts}
                                loadDistrictOptions={loadDistrictOptions}
                                onDistrictChange={onDistrictChange}
                                userRole={userRole}
                                isOtherScreen={isOtherScreen}
                                districtId={districtId}
                                isDisabled={selectDisabled}
                            />
                        </ul>
                    </div>
                </div>
                <div className="accordion__header-buttons csv-buttons">
                    <div className="accordion__header-btnContainer">
                        <a href="/students_template.csv" download>
                            <button className="btn outline-btn outline-btn-lg">
                                Download Template
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <div className="accordion__content border-radious-bottom">
                    {classCsvImport?.csvUploadError ? (
                        <Alert variant="danger" className="text-center">
                            {classCsvImport?.csvUploadError}
                        </Alert>
                    ) : (
                        ""
                    )}
                    {

                            classCsvImport.csvUploadSuccess || classCsvImport.status==='pending' ? (
                            <div className="uploads animated-fade">
                                <div className="uploads__content">
                                    <h6 className="uploads__content-title">
                                        {classCsvImport?.csvName}
                                    </h6>
                                    {validationError !== "" && classCsvImport.status !== "pending" ? (<p className="uploads__content-paragraph">
                                            <img src={Warning} alt="Warning icon"/>
                                        There are errors below. These must be resolved before Import
                                        is enabled.
                                    </p>) : ''}
                                    {classCsvImport.status === "pending" && (<p className="uploads__content-paragraph">
                                <img src={Warning} alt="Warning icon"/>
                                        {`${districtName ? districtName : localStorage.getItem('import_district_name')} CSV Import Processing. Do not navigate from the page.`}
                            </p>) }

                                    <div className="uploads__content-buttons">
                                        <button
                                            disabled={classCsvImport.importDataLoading || classCsvImport.status === "pending"}
                                            onClick={() => {
                                                dispatch(cancelCsv()) && setSelectDisabled(false);
                                                clearInterval(pingInterval)
                                            }
                                            }
                                            className="btn cancel-btn btn-medium"
                                            type="button"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            disabled={
                                                classCsvImport.importDataLoading || validationError !== "" || !classCsvImport.csvResults?.length || classCsvImport.status === "pending"
                                            }
                                            onClick={doImportData}
                                            className="btn success-btn btn-medium"
                                            type="button"
                                        >
                                            {/* {classCsvImport.importDataLoading ?
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> :  */}
                                            Import
                                            {/* } */}
                                        </button>
                                    </div>
                                    {/*<p className="text-danger">{validationError}</p>*/}
                                </div>
                            </div>
                        ) : (
                            <div className="uploads">
                                {districtId !== 0 ? (
                                    <div
                                            {...getRootProps({className: "uploads__content bordered"})}
                                    >
                                        <input {...getInputProps()} />
                                        <h6 className="uploads__content-title">
                                            Upload Class and Student CSV
                                        </h6>
                                        <p className="uploads__content-paragraph">
                                            Drag a file here or select Choose File below to upload CSV for
                                            review.
                                        </p>
                                        <button
                                            disabled={classCsvImport.csvLoading || districtId === 0}
                                            className={`btn success-btn btn-medium uploads__content-choose ${(classCsvImport.csvLoading || districtId === 0) ? 'disabled' : ''}`}
                                            type="button"
                                            onClick={open}
                                        >
                                            {classCsvImport.csvLoading ? (
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                "Choose File"
                                            )}
                                        </button>
                                    </div>

                                ) : (<div className="uploads__content bordered">
                                    <h6 className="uploads__content-title">
                                        Upload Class and Student CSV
                                    </h6>
                                    <p className="uploads__content-paragraph">
                                        Drag a file here or select Choose File below to upload CSV for
                                        review.
                                    </p>
                                    <button
                                        disabled={classCsvImport.csvLoading || districtId === 0}
                                        className={`btn success-btn btn-medium uploads__content-choose ${(classCsvImport.csvLoading || districtId === 0) ? 'disabled' : ''}`}
                                        type="button"
                                        onClick={open}
                                    >
                                        {classCsvImport.csvLoading ? (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            "Choose File"
                                        )}
                                    </button>
                                </div>)}

                            </div>

                        )}
                </div>
            </div>
            {isSuccess && (importStatus === 'error' || importStatus === 'success') && (
                <SuccessModal
                    districtName={districtName}
                    setIsSuccess={() => setIsSuccess(false)}
                    setImportStatus={() => setImportStatus(false)}
                    status={importStatus}
                    isShow={true}
                    id={districtId}
                // name={classData && classData[0]?.district_name}
                />
            )}
        </>
    );
};

export default CsvFileUploader;
