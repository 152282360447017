/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import logo from "assets/img/login-logo.svg";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { encryptPassword } from "util/index";
import Footer from "components/theme/footer";
import { useDispatch, useSelector } from "react-redux";
import { login, loginWithClever, requestResendPassword } from "redux/actionCreators/auth";
import { Alert, Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import config from "../config/config";
import { ReactComponent as ShowIconWhite } from "assets/img/show-icon.svg";
import { ReactComponent as HideIconWhite } from "assets/img/hide-icon.svg";
import { PromptModal } from "components/classroom/modals/prompt";
import SuccessModal from "components/common/successModal";
import ActionType from "redux/actionTypes";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";


interface RootState {
  auth: any;
}

const LoginPage: React.FunctionComponent<IPage> = (props) => {

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [isSubmittingClever, setIsSubmittingClever] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [searchParams] = useSearchParams();
  const cleverId = searchParams.get('clever_id');
  const errorMsg = searchParams.get('error_msg');
  const [modalShow, setModalShow] = useState(false);
  const [userCred, setUserCred] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const {pathname} = useLocation();
  const user = useSelector((state: RootState) => state);
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();
  const [initRender, setInitRender] = useState<boolean>(true)

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  useEffect(() => {
    const loginSuccess = async () => {
      setIsSubmitting(false);
      setErrors(false);
      setIsEmailSend(false);

      const token = Cookies.get("super-admin-token");
      if(token){
        const decodedToken: any = jwtDecode(token);
        if(decodedToken?.exp * 1000 >= Date.now()){
          navigate("/activity");
        }
      }
      
    }

    if (user.auth.type === "USER_FORGOT_PASSWORD_SUCCESS") {
      setIsEmailSend(true);
    }

    if (user.auth.type === "USER_LOGIN_SUCCESS") {
      loginSuccess()
    } else if (user.auth.type === "USER_LOGIN_FAIL") {
      setIsSubmitting(false);
      setErrors(true);
      setIsEmailSend(false);
        setIsSubmittingClever(false);
    } else if (errorMsg) {
      dispatch({
        type: ActionType.USER_LOGIN_FAIL,
        payload: {
            message: errorMsg || "Failed to Login with Clever",
        },
      });
    } else if (cleverId) {
      setIsSubmittingClever(true);
      dispatch(
        loginWithClever({ code: cleverId })
      );
    }

    setInitRender(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [user.auth]);

  const initialValues = { email: "", password: "" };

  const onSubmit = (values: any) => {
    setIsSubmitting(true);

    const email = values.email;
    const password = encryptPassword(values.password);
    setIsSubmitting(true);
    dispatch(
      login({
        email: email.trim(),
        password,
      }, setModalShow)
    );

    setUserCred({email: email, password: password});

  };

  const validationSchema = Yup.object({
    email: Yup.string().trim().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onRequestResendPassword = async () => {
    if (userCred.hasOwnProperty('email')) {
      const response = await requestResendPassword(userCred);
      if (response.data) {
        setModalShow(false);
        setShowSuccessModal(true);
      }
    }
  }

  if(initRender){
    return null
  }

  const isFixFooter = pathname === '/' || pathname === '/login';

  return (
    <>
      <section className={`vh-100 login ${isFixFooter ? 'footer-login-page' : ''}`}>
        <div className="login-background"></div>
        <div className="login-container h-100">
          <div className="row login__content">
            <div className="col-sm-12 col-md-9 col-lg-7 col-xl-6 login__content-column p-0">
              <div className="card login__card">
                <div className="login__card-header">
                  <div className="">
                    <div className="brand-logo login__card-logo">
                      <img alt="alpha" src={logo} />
                    </div>
                    <div className="login__card-text">
                      <p>Admin/Teacher Login</p>
                    </div>
                  </div>
                </div>
                <div className="card-body login__card-body text-center">
                  <div className="login__card-inputs">
                    {errors && (
                      <Alert
                        className="login-alert"
                        dismissible
                        onClose={() =>
                          setErrors(false)
                        }
                        variant="danger"
                      >
                        {user.auth.payload.message}
                      </Alert>
                    )}

                    {isEmailSend && (
                      <Alert
                        className="login-alert"
                        dismissible
                        onClose={() => setIsEmailSend(false)}
                        variant="success"
                      >
                        We have sent a temporary password, Please check your email.
                      </Alert>
                    )}

                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="login__card-form">
                          <div className="form-outline">
                            <label>Email</label>
                            <Field
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                          </div>
                          <div className="form-outline password">
                            <label>Password</label>
                            <Field
                              name="password"
                              type={passwordType}
                              className={
                                "form-control" +
                                (errors.password && touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                            />

                            <button className="password-wrap" type="button">
                              {passwordType === 'password' ?
                                <ShowIconWhite className="cursor-pointer" onClick={() => setPasswordType('text')} />
                                : <HideIconWhite className="cursor-pointer" onClick={() => setPasswordType('password')} />
                              }
                            </button>

                          </div>

                          <div className="login__buttons">
                            <label className="form-check-label">
                              <Link to="/reset-password" className="login__card-link">
                                Forgot Password?
                              </Link>
                            </label>
                            <div className="d-flex">
                              <a
                                href={`https://clever.com/oauth/authorize?response_type=code&redirect_uri=${config.defaults.redirect_url}&client_id=${config.defaults.clever_client_id}`}
                                className="clever-button login__buttons-clever"
                              >
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
                                    <mask id="mask0_1115_8157" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="17">
                                    <path d="M14.723 0.202148H0V16.7971H14.723V0.202148Z" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask0_1115_8157)">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.00140192 8.54721C-0.0287173 7.44591 0.167661 6.35051 0.575853 5.32728C0.984045 4.30405 1.59557 3.37427 2.37342 2.59417C3.15128 1.81407 4.0793 1.19987 5.10135 0.788736C6.12339 0.377598 7.21822 0.178066 8.3196 0.202209C9.48358 0.145242 10.6464 0.336456 11.7308 0.763164C12.8152 1.18987 13.7965 1.84232 14.6096 2.67721L12.3776 5.26921C11.3092 4.15212 9.84374 3.50079 8.2986 3.45621C7.66316 3.47069 7.03711 3.61268 6.45761 3.87376C5.8781 4.13484 5.35696 4.50969 4.92511 4.97605C4.49326 5.44241 4.1595 5.99078 3.94366 6.58861C3.72781 7.18644 3.63428 7.82154 3.6686 8.45621C3.6686 11.2562 5.5586 13.5472 8.2986 13.5472C9.08918 13.5399 9.86972 13.3694 10.5913 13.0462C11.3128 12.723 11.9598 12.2542 12.4916 11.6692L14.7236 13.9372C13.9225 14.8812 12.9165 15.6299 11.7823 16.1264C10.6481 16.6228 9.41561 16.854 8.1786 16.8022C7.09398 16.8169 6.01752 16.6128 5.01352 16.2022C4.00952 15.7916 3.09857 15.1828 2.33507 14.4123C1.57156 13.6418 0.971161 12.7253 0.569724 11.7176C0.168287 10.7099 -0.0259552 9.63165 -0.00140192 8.54721Z" fill="#4274F6"/>
                                    </g>
                                  </svg>
                                </span>
                                <hr />
                                <span>{isSubmittingClever ? "Logging..." : "Log In with Clever"}</span>
                              </a>
                              <button
                                className="btn login__card-btn"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Log In"}
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-9 col-lg-7 col-xl-6 login__content-column p-0">
              <div className="login__info">
                <p className="mb-0">
                  If your school hasn’t signed up for 2024-25, please call (405)
                  842-8408.
                </p>
              </div>
            </div>
          </div>

          <Footer showChatBox={false} />

        </div>
      </section>
      <div style={{marginRight: "35px"}}>
        <PromptModal
            bodyText="Would you like us to send you a new temp password?"
            headerText="Temp Password Expired"
            cancelText="No"
            okText="Yes"
            isShow={modalShow}
            setModal={setModalShow}
            action={onRequestResendPassword}
        />
        <SuccessModal
          isShow={showSuccessModal}
          headerText="Success"
          bodyText="A new Temporary password has been sent to your email. Please check your inbox and use the password."
          closeModal={() => setShowSuccessModal(false)}
        />
      </div>
    </>
  );
};

export default LoginPage;
