import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { setActivityReportIndicator } from "redux/actionCreators/activity";
import { getStlReport } from "redux/actionCreators/management";
import ActionType from "redux/actionTypes";
import logging from "./config/logging";
import routes from "./config/routes";
import cookie from "js-cookie";
import { clearAllData, clearSessions } from "./util/index";
import ReloadModal from "./components/common/Reload/reloadModal"
import ApiResponseModal from "components/common/Reload/ApiErrorModal";
import ChatIconWrapper from "components/common/ChatIconWrapper";
import ClassroomTooltipProvider from "contexts/classroomTooltip";
import Socket from "services/core/socket";
import socketKeys from "util/types/socket-keys";
import { setSocketDisconnected, setSocketPayload } from "redux/actionCreators/auth";
import Cookies from "js-cookie";

const {
    SOCKET_CLASS_BY_ID_CHANNEL,
    SOCKET_ONLINE_STUDENT,
    SOCKET_TEACHER_RERENDER_MONITOR,
    SOCKET_TEACHER_VIEW_UPDATE,
    SOCKET_SUPER_ADMIN_CHAT_SUBSCRIBE_NOTIFICATIONS,
    SOCKET_ADMIN_ACTIVE
} = socketKeys;


const Application: React.FunctionComponent<{}> = (props) => {
    const { management, activity, auth } = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const [isSocketDisconnected, setIsSocketDisconnected] = useState<Boolean>(false);

    const socketRef = useRef<any>();

    useEffect(() => {
        if(process.env.NODE_ENV !== 'development'){
            console.log = function () {};
        }
        logging.info("Loading application.");
    }, []);

    function listenCookieChange(callback: any, interval = 1000) {
        let lastCookie = document.cookie;
        setInterval(() => {
            let cookie = document.cookie;
            if (cookie !== lastCookie) {
                try {
                    callback({ oldValue: lastCookie, newValue: cookie });
                } finally {
                    lastCookie = cookie;
                }
            }
        }, interval);
    }

    listenCookieChange(() => {
        const role = cookie.get("role")
        if ((!role && auth.currentUser)) {
            clearAllData()
            window.location.href = '/'
        }
        else if (role && auth.currentUser && role !== auth.currentUser.role) {
            clearSessions()
            window.location.href = '/'
        }
    }, 1000);

    useEffect(() => {
        let interval: any;
        let loading = localStorage.getItem("stlLoading");
        dispatch({
            type: ActionType.SET_STL_LOADING,
            payload: loading === 'true' ? true : false,
        });


        if (management.stlLoading || loading === 'true') {
            const report = async () => {
                let id: any = localStorage.getItem("id");
                id = parseInt(id);
                const data = await getStlReport(id);
                if (data.data.data) {
                    dispatch({
                        type: ActionType.SET_STL_LOADED,
                        payload: data.data.data.url ? true : false,
                    });
                    dispatch({
                        type: ActionType.SET_STL_URL,
                        payload: data.data.data.url,
                    });
                    clearInterval(interval);
                }
            };

            interval = setInterval(() => {
                report();
            }, 3000);

            return () => clearInterval(interval);
        }
        if (!management.stlLoading && interval) {
            clearInterval(interval);
        }
    }, [management.stlLoading, dispatch]);

    useEffect(() => {
        let interval: any;
        let loading = localStorage.getItem("stlSingleLoading");
        dispatch({
            type: ActionType.SET_SINGLE_STL_LOADING,
            payload: loading === 'true'?true:false,
        });


        if (management.stlSingleLoading || loading==='true') {
            const report = async () => {
                let id: any = localStorage.getItem("singleId");
                let districtId: any = localStorage.getItem("district_id");
                id = parseInt(id);
                const data = await getStlReport(id,districtId);//-*-*-*-*-*-*-*
                if (data.data.data) {
                    dispatch({
                        type: ActionType.SET_SINGLE_STL_LOADED,
                        payload: data.data.data.url ? true : false,
                    });
                    dispatch({
                        type: ActionType.SET_SINGLE_STL_URL,
                        payload: data.data.data.url,
                    });
                    clearInterval(interval);
                }
            };

            interval = setInterval(() => {
                report();
            }, 3000);

            return () => clearInterval(interval);
        }
        if (!management.stlSingleLoading && interval) {
            clearInterval(interval);
        }
    }, [management.stlSingleLoading, dispatch]);

    useEffect(() => {
        const active = localStorage.getItem('active')
        const link = localStorage.getItem('link')
        const completed = localStorage.getItem('completed')
        if (activity.active || active === 'true') {
            dispatch(setActivityReportIndicator(true, false, link));
        }
        if (activity.completed || completed === 'true') {
            dispatch(setActivityReportIndicator(true, true, link));
        }

    }, [activity.active, dispatch, activity.completed]);

    const token = Cookies.get('super-admin-token');

    useEffect(() => {
        if(!token){
            return;
        }
        
        const socketInstance = Socket.getInstance();
    
        socketRef.current = socketInstance;

        socketInstance.on('connect', function () {
            console.log('socket connected');
            dispatch(setSocketDisconnected(false))

            const isSuperAdmin = Cookies.get("role") === "Super Admin";
        
            if(!isSuperAdmin){
                socketInstance.emit(SOCKET_ADMIN_ACTIVE, {});
            }else{
                socketInstance.emit(SOCKET_SUPER_ADMIN_CHAT_SUBSCRIBE_NOTIFICATIONS, {});
            }

            socketInstance.on(
                SOCKET_CLASS_BY_ID_CHANNEL,
                async (data: any) => {
                //   console.log("********************************");
                //   console.log("********************************");
                //   console.log("*****Teacher Event Received*****");
                //   console.log("********************************");
                //   console.log("********************************");
                    // console.log(
                    //     "event: socket-class-by-id-channel",
                    //     JSON.stringify(data)
                    // );
                    
                    dispatch(setSocketPayload(data.resource));
                }
            );

            socketInstance.on(
                SOCKET_ONLINE_STUDENT,
                async (data: any) => {
                //   console.log("********************************");
                //   console.log("********************************");
                //   console.log("*****Teacher Event Received*****");
                //   console.log("********************************");
                //   console.log("********************************");
                  console.log("event: socket-online-student", data);

                    dispatch(setSocketPayload({studentActiveNow: data.resource, type: SOCKET_ONLINE_STUDENT}))
                }
            );
        
            socketInstance.on(
                SOCKET_TEACHER_VIEW_UPDATE,
                async (data: any) => {
                //   console.log("********************************");
                //   console.log("********************************");
                //   console.log("*****Teacher Event Received*****");
                //   console.log("********************************");
                //   console.log("********************************");

                    console.log(
                        "event: SOCKET_TEACHER_VIEW_UPDATE",
                        JSON.stringify(data)
                    );

                    dispatch(setSocketPayload(data.resource));
                }
            );
        
            socketInstance.on(
                SOCKET_TEACHER_RERENDER_MONITOR,
                async (data: any) => {
                    // console.log("********************************");
                    // console.log("********************************");
                    // console.log("*****Rerender Event Received*****");
                    // console.log("********************************");
                    // console.log("********************************");

                    console.log(
                        "event: socket-teacher-rerender-monitor",
                        JSON.stringify(data)
                    );
                }
            );
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[token, isSocketDisconnected])
    

    useEffect(() => {
        if(!socketRef.current){
            return;
        }
        

        socketRef.current.on("connect_error", (reason: any) => {
            dispatch(setSocketDisconnected(true));
        });

        socketRef.current.on("disconnect", (reason: any) => {
            console.log('reason for disconnection: ', reason);
            if (reason.indexOf('disconnect') !== -1) {
                // the disconnection was initiated by the server, you need to reconnect manually
                socketRef.current.connect();
                setIsSocketDisconnected(true);
            }
            // else the socket will automatically try to reconnect
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Socket]);



    useEffect(() => {
        
        return () => {
            socketRef.current?.off(SOCKET_CLASS_BY_ID_CHANNEL);
            socketRef.current?.off(SOCKET_ONLINE_STUDENT);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        
        <ClassroomTooltipProvider>
            <BrowserRouter>
                
                    <Routes>
                    
                        {routes.map((route, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={<>
                                        <route.component
                                            name={route.name}
                                            {...props}
                                            {...route.props}
                                        />
                                        <ReloadModal />
                                        <ApiResponseModal />
                                    </>
                                    }
                                />
                            );
                        })}
                        <Route path="*" element={ <Navigate to="/" replace />} />
                    </Routes>
                    <ChatIconWrapper />
                
                
            </BrowserRouter >
        </ClassroomTooltipProvider>
        

    );
};

export default Application;
