import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import ArrowDownImg from "assets/img/drop-black-icon-down.svg";
import { LimitSelect } from "components/common/selectComponent";
import PerformanceSets from "components/common/studentInfoComponents/PerformanceSets";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import ReportCardSkeleton from "components/reportCard/ReportCardSkeleton";
import { useState } from "react";

const StudentPerformanceDetail = ({
  performance,
  handlEnroll,
  setClassId,
  handleSortClick,
  getClassName,
  skeletonLoad,
  classrooms,
  isPrev,
  showLastThreeAttempts,
}: any) => {
  const navigate = useNavigate();

  const initialState = Object.keys(performance?.tests || {})
    .map((_: any, index: number) => ({ [index]: 10 }))
    .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {});

  const [limits, setLimits] = useState<Record<number, number>>(initialState);

  const handleLimitChange = (id: number, newLimit: number) => {
    setLimits((prevLimits) => ({
      ...prevLimits,
      [id]: newLimit,
    }));
  };

  return (
    <>
      <div className="pb-2">
        {!skeletonLoad &&
        performance &&
        Object.keys(performance?.tests || {}).length ? (
          Object.keys(performance?.tests || {}).map((item: any, i: number) => {
            let subject = 2;

            if (item?.indexOf("Math") > -1) {
              subject = 1;
            } else if (item?.indexOf("Science") > -1) {
              subject = 3;
            }

            let attemptTwoExists = false;
            let attemptThreeExists = false;

            const formativeData = classrooms[item]?.formatives.slice(
              0,
              //@ts-ignore
              limits[i]
            );

            classrooms?.[item]?.["summatives"].forEach((test: any) => {
              if (test?.scored_summatives[1]) {
                attemptTwoExists = true;
              }

              if (test?.scored_summatives[2]) {
                attemptThreeExists = true;
              }
            });

            const classroom_id = performance.classroom_ids.find(
              (cls: any) => cls.classroom_name === item
            )?.classroom_id;

            return (
              <>
                {!isPrev && i > 0 && (
                  <div style={{ marginBottom: "20px" }}>
                    <PerformanceSets
                      performance={performance}
                      performanceEnabled={true}
                      subject={subject}
                    ></PerformanceSets>
                  </div>
                )}
                <div className="reportCardPerformanceDetails">
                  <div className="reportCardPerformanceDetails__header">
                    <section className="reportCardPerformanceDetails__header-heading">
                      <h3 className="reportCardPerformanceDetails__header-title">
                        {item}
                      </h3>
                      {!isPrev && (
                        <>
                          <button
                            className="btn unenroll-btn"
                            onClick={() => {
                              handlEnroll(
                                performance?.tests[item][0]?.classroom_id ??
                                  classroom_id
                              );
                              setClassId(
                                performance?.tests[item][0]?.classroom_id ??
                                  classroom_id
                              );
                            }}
                          >
                            Unenroll
                          </button>
                          <button
                            className="btn unenroll-btn to-classroom-btn"
                            onClick={() => {
                              navigate(
                                `/management/class/${
                                  performance?.tests[item][0]?.classroom_id ??
                                  classroom_id
                                }`
                              );
                            }}
                          >
                            To Classroom
                          </button>
                        </>
                      )}
                    </section>

                    <div className="d-flex align-items-center testResults__header-select">
                      <span>Show</span>
                      <LimitSelect
                        disableHandler={performance?.tests[item]?.length < 10}
                        onChangeHandler={(e: any) =>
                          handleLimitChange(i, Number(e?.value))
                        }
                      />
                      <span>Assessments</span>
                    </div>
                  </div>
                  <div className="reportCardPerformanceDetails-table-box">
                    <div className="reportCardPerformanceDetails-table-container">
                      <div className="table-responsive">
                        <table className="reportCardPerformanceDetails__table">
                          <thead className="reportCardPerformanceDetails__table-header">
                            <tr className="reportCardPerformanceDetails__table-row">
                              <th className="reportCardPerformanceDetails__table-completed">
                                Completed Summative
                              </th>
                              <th
                                tabIndex={0}
                                className="testResults__table-date"
                                onClick={() => {
                                  handleSortClick({
                                    className: item,
                                    isSummative: true,
                                  });
                                }}
                                onKeyDown={(e)=>{
                                  if(e.key==="Enter"){
                                    handleSortClick({
                                      className: item,
                                      isSummative: true,
                                    });
                                  }
                                }}
                              >
                                Date
                                <img
                                  src={ArrowDownImg}
                                  alt="arrow icon"
                                  className={getClassName({
                                    className: item,
                                    isSummative: true,
                                  })}
                                />
                              </th>
                              <th className="reportCardPerformanceDetails__table-date">
                                Attempts
                              </th>
                              {showLastThreeAttempts ? (
                                <>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 1
                                  </th>
                                  {attemptTwoExists && (
                                    <th className="reportCardPerformanceDetails__table-score">
                                      Attempt 2
                                    </th>
                                  )}
                                  {!attemptTwoExists && (
                                    <th className="reportCardPerformanceDetails__table-score"></th>
                                  )}
                                  {attemptThreeExists && (
                                    <th className="reportCardPerformanceDetails__table-score">
                                      Attempt 3
                                    </th>
                                  )}
                                  {!attemptThreeExists && (
                                    <th className="reportCardPerformanceDetails__table-score"></th>
                                  )}
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                </>
                              ) : (
                                <>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Last Score
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                </>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {skeletonLoad ? (
                              <TableSkeleton
                                columns={6}
                                count={10}
                                height={36}
                              />
                            ) : (
                              ""
                            )}

                            {!skeletonLoad &&
                            classrooms[item]?.summatives?.length > 0 ? (
                              classrooms[item]["summatives"].map(
                                (test: any, i: number) => {
                                  const attempts =
                                    test?.scored_summatives || [];
                                  return (
                                    <tr
                                      key={i}
                                      className="reportCardPerformanceDetails__table-row reportCardPerformanceDetails__table-row-border"
                                    >
                                      <td className="completed-data">
                                        {test?.name}
                                      </td>
                                      <td>{test?.date ? test?.date : "-"}</td>
                                      <td className="reportCardPerformanceDetails__table-attempts">
                                        {attempts?.length}
                                      </td>
                                      {showLastThreeAttempts ? (
                                        <>
                                          <td>
                                            {attempts[0]?.score_percentage && (
                                              <span
                                                className={`level-box level-box-${attempts[0]?.score_percentage} level-box-radius`}
                                              ></span>
                                            )}
                                            {attempts[0]?.score_percentage}
                                          </td>
                                          <td>
                                            {attempts[1]?.score_percentage && (
                                              <span
                                                className={`level-box level-box-${attempts[1]?.score_percentage} level-box-radius`}
                                              ></span>
                                            )}
                                            {attempts[1]?.score_percentage}
                                          </td>
                                          <td>
                                            {attempts[2]?.score_percentage && (
                                              <span
                                                className={`level-box level-box-${attempts[2]?.score_percentage} level-box-radius`}
                                              ></span>
                                            )}
                                            {attempts[2]?.score_percentage}
                                          </td>
                                          <td></td>
                                        </>
                                      ) : (
                                        <td>
                                          {test?.last_score && (
                                            <span
                                              className={`level-box level-box-${test?.last_score} level-box-radius`}
                                            ></span>
                                          )}
                                          {test?.last_score}
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                {classrooms[item]?.summatives?.length === 0 && (
                                  <td
                                    colSpan={6}
                                    className="reportCardPerformanceDetails__table_no-data"
                                  >
                                    No Records
                                  </td>
                                )}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="reportCardPerformanceDetails-table-container">
                      <div className="table-responsive">
                        <table className="reportCardPerformanceDetails__table">
                          <thead className="reportCardPerformanceDetails__table-header">
                            <tr className="reportCardPerformanceDetails__table-row">
                              <th className="reportCardPerformanceDetails__table-completed">
                                Completed Formatives
                              </th>
                              <th
                                tabIndex={0}
                                className="testResults__table-date"
                                onClick={() =>
                                  handleSortClick({
                                    className: item,
                                    isSummative: false,
                                  })
                                }
                                onKeyDown={(e)=>{
                                  if(e.key==="Enter"){
                                    handleSortClick({
                                      className: item,
                                      isSummative: false,
                                    });
                                  }
                                }}
                                
                              >
                                Date
                                <img
                                  src={ArrowDownImg}
                                  className={getClassName({
                                    className: item,
                                    isSummative: false,
                                  })}
                                  alt="arrow icon"
                                />
                              </th>
                              <th className="reportCardPerformanceDetails__table-date">
                                Attempts
                              </th>
                              {showLastThreeAttempts ? (
                                <>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 1
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 2
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 3
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                </>
                              ) : (
                                <>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Last Score
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                </>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {skeletonLoad ? (
                              <TableSkeleton
                                columns={6}
                                count={10}
                                height={36}
                              />
                            ) : (
                              ""
                            )}
                            {!skeletonLoad && formativeData?.length > 0 ? (
                              formativeData.map((test: any, i: number) => {
                                const attempts = test?.scored_formatives || [];
                                return (
                                  <tr
                                    key={i}
                                    className="reportCardPerformanceDetails__table-row reportCardPerformanceDetails__table-row-border"
                                  >
                                    <td className="completed-data">
                                      {test?.name}
                                    </td>
                                    <td>{test?.date ? test?.date : "-"}</td>
                                    <td className="reportCardPerformanceDetails__table-attempts">
                                      {attempts?.length}
                                    </td>
                                    {showLastThreeAttempts ? (
                                      <>
                                        <td>
                                          {attempts[0]?.score_percentage && (
                                            <span
                                              className={`level-box level-box-${attempts[0]?.score_percentage} level-box-radius`}
                                            ></span>
                                          )}
                                          {attempts[0]?.score_percentage}
                                        </td>
                                        <td>
                                          {attempts[1]?.score_percentage && (
                                            <span
                                              className={`level-box level-box-${attempts[1]?.score_percentage} level-box-radius`}
                                            ></span>
                                          )}
                                          {attempts[1]?.score_percentage}
                                        </td>
                                        <td>
                                          {attempts[2]?.score_percentage && (
                                            <span
                                              className={`level-box level-box-${attempts[2]?.score_percentage} level-box-radius`}
                                            ></span>
                                          )}
                                          {attempts[2]?.score_percentage}
                                        </td>
                                      </>
                                    ) : (
                                      <td>
                                        {test?.last_score && (
                                          <span
                                            className={`level-box level-box-${test?.last_score} level-box-radius`}
                                          ></span>
                                        )}
                                        {test?.last_score}
                                      </td>
                                    )}
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td
                                  colSpan={6}
                                  className="reportCardPerformanceDetails__table_no-data"
                                >
                                  No Records
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : skeletonLoad ? (
          <div className="reportCardPerformanceDetails">
            <div className="reportCardPerformanceDetails__header">
              <section className="reportCardPerformanceDetails__header-heading">
                <h3 className="reportCardPerformanceDetails__header-title">
                  <Skeleton
                    baseColor="#9c9fa343"
                    highlightColor="#d2d5db32"
                    width={220}
                  />
                </h3>
              </section>
            </div>
            <div className="reportCardPerformanceDetails-table-box">
              <div className="reportCardPerformanceDetails-table-container">
                <div className="table-responsive">
                  <table className="reportCardPerformanceDetails__table">
                    <thead className="reportCardPerformanceDetails__table-header">
                      <tr className="reportCardPerformanceDetails__table-row">
                        <TableSkeleton columns={7} count={1} height={36} />
                      </tr>
                    </thead>
                    <tbody>
                      {[1, 2, 3, 4, 5, 6, 7, 8].map((id) => {
                        return <ReportCardSkeleton loading i={id - 1} />;
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          !skeletonLoad && (
            <div className="reportCardPerformanceDetails-table-box">
              <div className="reportCardPerformanceDetails-table-container">
                <div className="table-responsive">
                  <table className="reportCardPerformanceDetails__table">
                    <thead className="reportCardPerformanceDetails__table-header"></thead>
                    <tbody>
                      <tr>
                        <td
                          colSpan={6}
                          className="reportCardPerformanceDetails__table_no-data"
                        ></td>
                      </tr>
                      <tr>
                        <td
                          colSpan={6}
                          className="reportCardPerformanceDetails__table_no-data"
                        ></td>
                      </tr>
                      <tr>
                        <td
                          colSpan={6}
                          className="reportCardPerformanceDetails__table_no-data"
                        >
                          No Records
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={6}
                          className="reportCardPerformanceDetails__table_no-data"
                        ></td>
                      </tr>
                      <tr>
                        <td
                          colSpan={6}
                          className="reportCardPerformanceDetails__table_no-data"
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default StudentPerformanceDetail;
