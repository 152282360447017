import { FC, useContext, useEffect, useRef, useState } from "react";
import { QuestionnaireContext } from "../Assessment/QuestionnaireContext";
import Cookies from "js-cookie";
import BackArrow from "assets/img/accordian-box-icon.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Choice from "./Choice";
import Highlighter from "assets/img/highlighter.svg";
import SpeechControls from "./TextToSpeech/SpeechControls";
import { setMarkings } from "util/RaphaelInit";
import HtmlParser from "components/common/htmlParser";
import Eraser from "assets/img/eraser.svg";
import { useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import Close from 'assets/img/remove-icon-bold.svg';


interface Props {
    onRemoveHighlighter: () => void,
}
const QuestionContainer: FC<Props> = (props) => {
    const navigate = useNavigate();
    const assessmentName = Cookies.get("assessmentName");
    const isMath = assessmentName && assessmentName?.indexOf('Math') > -1 ? true : false;
    const showCalculatorContainer = Cookies.get("isMath") === 'true'
    const calculatorContainerRef = useRef<HTMLDivElement>(null)
    const [bounds, setBounds] = useState<any>();
    const [pos, setPos] = useState({x:0, y:0});
    const {closeCalculator} = useContext(QuestionnaireContext);
    

    const {
        currentQuestion,
        questionIdx,
        tabIndex,
        setTabIndex,
        isHighlighterActive,
        isSpeechIconClicked,
        onSpeak,
        containerRef,
        keyToSpeak,
        showHighlightEraser,
        isExpand,
        setIsExpand,
        showCalculator
    } = useContext(QuestionnaireContext);

    const {
        passage_directions,
        direction,
        passage_1,
        passage_2,
        question,
        choice_1,
        choice_2,
        choice_3,
        choice_4,
        answer,
        hasPassage1,
        hasPassage2
    } = currentQuestion;

    const hasTabs = !(hasPassage1 || hasPassage2);

    // const closeCalculator = () => {
    //     setShowCalculator(false);
    //     destroyCalculator();
    // }
    

    useEffect(() => {
        const divElement = document.getElementById("question")?.querySelector('div')
        console.log("div element : ", divElement?.style?.maxWidth)
        if (divElement && divElement.style?.maxWidth?.length) {
            divElement.style.maxWidth = "unset"
        }
    }, [currentQuestion])

    useEffect(() => {

        const { penMarkings } = currentQuestion
        setMarkings(penMarkings)

        return () => {
            props.onRemoveHighlighter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentQuestion.id]);

    useEffect(() => {   
        if(showCalculator && calculatorContainerRef.current){
            const rectData = calculatorContainerRef.current?.getBoundingClientRect();
            if(rectData){
                setBounds({
                    left: -rectData.left,
                    top: -(rectData.top+140),
                    right: window.innerWidth - rectData.right,
                    bottom: window.innerHeight - rectData.bottom - 60
                })
            }
        }else{
            setBounds(undefined);
            setPos({x:0, y:0});
        }
    },[showCalculator, calculatorContainerRef]);

    if (!currentQuestion.id) {
        return null
    }

    return (
        <div id="highlight" className="mainContent cms-sub-header">
            <div className={`mainContent-header ${hasTabs ? "changing-width" : ""}`}>
                <div className="mainContent-header-empty"></div>
                <div className="mainContent-header-wrapper">
                    <div
                        className={`mainContent__answer ${hasTabs ? "changing-width" : ""} ${isExpand ? 'answer-sec-expand' : ''}`}
                    >
                        <div className="mainContent__answer-content">
                            <button
                                className="btn btn-link mainContent__answer-link"
                                onClick={() => navigate(-1)}
                            >
                                <img alt="Back Arrow" src={BackArrow} />
                            </button>
                            <div className="mainContent__answer-paragraph preview-class-name">
                                {assessmentName}
                            </div>
                            {!hasTabs && (
                                <button onClick={() => setIsExpand(!isExpand)} type="button" className={`expand-desc preview-expanded`}>
                                    <svg className={`${isExpand ? 'open' : ''}`} xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.76172 28.9999C1.65715 28.9999 0.761719 28.1045 0.761719 26.9999V2.52295C0.761719 1.41838 1.65715 0.522949 2.76172 0.522949H27.2387C28.3433 0.522949 29.2387 1.41838 29.2387 2.52295V26.9999C29.2387 28.1045 28.3433 28.9999 27.2387 28.9999H2.76172ZM10.2547 10.0069V6.22295H27.3397V27.1019H10.2537V23.3019H8.35372V27.1019H2.66172V6.22295H8.35472V10.0229L10.2547 10.0069ZM10.2547 15.7069H8.35472V11.9069H10.2547V15.7069ZM15.9497 17.6069H12.1497V21.4069H15.9497V25.2069L23.5437 19.5119L15.9497 13.8119V17.6069ZM8.35472 21.4069H10.2547V17.6069H8.35472V21.4069Z" fill="#ffffff"/>
                                    </svg>
                                </button>
                            )}
                        </div>
                    </div>
                    <div
                        className={`mainContent__question ${hasTabs ? "changing-width" : ""} ${isExpand ? 'question-sec-hide' : ''}`}
                    >
                        <div className="mainContent__question-content">
                            <p className="mainContent__question-paragraph">
                                {`Question ${questionIdx + 1}`}
                            </p>
                        </div>

                        <SpeechControls  isMath={isMath} />

                    </div>
                </div>

                <div className={`mainContent-header-empty ${isExpand ? 'empty-header-color' : ''}`}></div>
            </div>

            <div ref={containerRef} style={{ cursor: isHighlighterActive ? `url(${Highlighter}) 0 35, move` : showHighlightEraser ? `url(${Eraser}) 14 41, move` : 'auto' }} className={`mainContent__main ${hasTabs ? "switch-off-column" : ""}`}>
                <div style={{ display: 'contents' }}>
                    {!hasTabs && (
                        <div className={`answers ${isExpand ? 'answer-sec-expand' : ''}`}>
                            
                            <div className="answers-content">
                                {hasPassage2 ?
                                    <Tabs
                                        selectedIndex={tabIndex}
                                        onSelect={(index: number, _: any, event: any) => {
                                            const id = (event.target as Element).getAttribute("data-id");
                                            id !== "delete-icon" && setTabIndex(index);
                                        }}
                                    >
                                        <TabList>
                                            <Tab>Tab 1</Tab>
                                            <Tab>Tab 2</Tab>
                                        </TabList>

                                        <div className={`answers__editor ${hasPassage2 ? "add-tabs-height":""}`}>
                                            <div
                                                className={`answers__editor-tab ${isSpeechIconClicked && 'ondemandtts'} ${keyToSpeak === "passage_directions" && 'playtts' }`}
                                                onClick={() => onSpeak('passage_directions')}
                                            >
                                                <span className="answers__editor-instructions">
                                                    <HtmlParser html={passage_directions} id="passage_directions" />
                                                </span>
                                            </div>
                                            <div className={`answers__editor-edit`}>
                                                <TabPanel>
                                                    <div className={`zoomAnimation ${isSpeechIconClicked && 'tts'}`}>
                                                        <HtmlParser html={hasPassage1 ? passage_1 : ""} id="passage_1" />
                                                    </div>
                                                </TabPanel>
                                            </div>
                                            <div className={`answers__editor-edit`}>
                                                <TabPanel>
                                                    <div className={`zoomAnimation  ${isSpeechIconClicked && 'tts'}`}>
                                                        <HtmlParser html={hasPassage2 ? passage_2 : ""} id="passage_2" />
                                                    </div>
                                                </TabPanel>
                                            </div>
                                        </div>
                                    </Tabs>
                                    :
                                    <div className="answers__editor">
                                        <div className="zoomAnimation">
                                            <div
                                                className={`answers__editor-tab ${isSpeechIconClicked && 'ondemandtts'} ${keyToSpeak === "passage_directions" && 'playtts' }`}
                                                onClick={() => onSpeak('passage_directions')}
                                            >
                                                <span className="answers__editor-instructions">
                                                    <HtmlParser html={passage_directions} id="passage_directions" />
                                                </span>
                                            </div>
                                            <div
                                                className={`answers__editor-edit ${isSpeechIconClicked && 'tts'}`}
                                            >
                                                <HtmlParser html={passage_1} id="passage_1" />
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                    )}
                    <div className={`questions ${hasTabs ? "switch-off-column" : ""} ${isExpand ? 'question-sec-hide' : ''}`}>
                        <div className="questions-wrapper">
                            <div className="questions__question">
                                {direction &&
                                    <div className="questions__dialogue"
                                        onClick={() => onSpeak('direction')}
                                    >
                                        <p className={`${isSpeechIconClicked && 'ondemandtts'} ${keyToSpeak === "direction" && 'playtts' }`}>
                                            <HtmlParser html={direction} id="direction" />
                                        </p>
                                    </div>
                                }
                                <div
                                    id="chr-ques"
                                    className="questions__question-item instruction"
                                >
                                    <div className={`${isSpeechIconClicked && 'ondemandtts' } questions__question-instruction ${keyToSpeak === "question" && 'playtts' }`}
                                        onClick={() => onSpeak('question')}
                                    >
                                        <HtmlParser html={question} id="question" />
                                    </div>
                                </div>
                                <div className="questions__question-item">
                                    <Choice
                                        choice="A"
                                        choiceText={choice_1}
                                        option={0}
                                        answer={answer}
                                    />
                                </div>
                                <div className="questions__question-item choice-b">
                                    <Choice
                                        choice="B"
                                        choiceText={choice_2}
                                        option={1}
                                        answer={answer}
                                    />

                                </div>
                                <div className="questions__question-item choice-c">
                                    <Choice
                                        choice="C"
                                        choiceText={choice_3}
                                        option={2}
                                        answer={answer}
                                    />
                                </div>
                                <div className="questions__question-item choice-d">
                                    <Choice
                                        choice="D"
                                        choiceText={choice_4}
                                        option={3}
                                        answer={answer}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {showCalculator && <BasicCalculator />} */}
                </div>
                {showCalculatorContainer &&  
                    <Draggable
                        onDrag={(e: any, data: any) => {
                            setPos({x: data.x, y:data.y});
                        }}
                        position={pos}
                        defaultPosition={{x:0, y:0}}
                        bounds={bounds}
                    >
                        <div className="cal-main-container">
                            {showCalculator &&
                                <button onTouchEnd={closeCalculator} onClick={closeCalculator} type="button" className="closeTooltipBtn">
                                    <img src={Close} alt="Close" />
                                </button>}
                            <div
                                style={{cursor: "move"}}
                                ref={calculatorContainerRef}
                                id="desmos-calculator"
                            >
                            
                        </div>
                        </div>
                    </Draggable>
                }
            </div>
        </div>
    );
};
export default QuestionContainer;