import { createContext, useCallback, useEffect, useRef, useState } from "react"
import Cookies from "js-cookie";
import socketKeys from 'util/types/socket-keys';
import { ChatSocket } from "interfaces/chatSocket";
import Socket from "services/core/socket";
import _ from 'lodash';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { playSound } from "util/index";
import { setSocketPayload } from "redux/actionCreators/auth";


const {
    SOCKET_SUPER_ADMIN_CHAT_SUBSCRIBE,
    SOCKET_SUPER_ADMIN_CONNECT,
    SOCKET_SUPER_ADMIN_NOTIFICATIONS,
    SOCKET_SUPER_ADMIN_CHAT_SUBSCRIBE_NOTIFICATIONS,
    SOCKET_SUPER_ADMIN_CHAT_UNSUBSCRIBE,
    SOCKET_SUPER_ADMIN_CHAT_EVENTS,
    SOCKET_ADMIN_ACTIVE,
    SOCKET_SUPER_ADMIN_TYPING_EVENT
} = socketKeys;

export const ChatSocketContext = createContext<ChatSocket>(null!)

export const initialState: ChatSocket = {
    setCurrentStatus: null!,
    currentStatus: 0,
    emitSuperAdminChatUnsubscribe: null!,
    setChatNotification: null!,
    emitSuperAdminSubscribeNotification: null!,
    emitSuperAdminChatSubscribe: null!,
    socketData: null!,
    subscribeToAllChats: null!,
    emitSuperAdminConnect: null!,
    headerNotification: null!,
    chatNotification: null!,
    unsubscribeToAllChats: null!,
    chatSubscribeNotification: null!,
    superAdminMessages: null!,
    normalAdminMessages: null!,
    setSuperAdminChatOpen: null!,
    setNormalAdminChatOpen: null!,
    getSuperAdminChatOpen: null!,
    subscribeAdminActive: null!,
    emitTypingEvent: null!,
    superAdminChatTyping: null!,
    chatTyping: null!,
    adminActive: null!,
    setNormalAdminMessages: null!,
    setChatResolved: null!,
    chatResolved: false,
    setChatAssigned: null!,
    chatAssigned: false,
    settingHeaderState: null!,
}

const SocketContextProvider = ({ children }: any) => {
    const [superAdminChatTyping, setSuperAdminChatTyping] = useState(initialState.superAdminChatTyping);
    const [socketData, setSocketData] = useState<any>(initialState.socketData);
    const [headerNotification, setHeaderNotification] = useState<any>(initialState.headerNotification);
    const auth = useSelector((state: RootState) => state.auth);
    const [chatNotification, setChatNotification] = useState(initialState.chatNotification);
    const [chatSubscribeNotification, setChatSubscribeNotification] = useState<any>(null!);
    const [superAdminMessages, setSuperAdminMessages] = useState(null!);
    const [normalAdminMessages, setNormalAdminMessages] = useState(null!);
    const [chatTyping, setChatTyping] = useState(null!);
    const [adminActive, setAdminActive] = useState(null!);
    const socketInstance = Socket.getInstance();
    const [currentStatus, setCurrentStatus] = useState(initialState.currentStatus);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const isSuperAdmin = Cookies.get("role") === "Super Admin";
    const superAdminChatOpen = useRef<any>(false);
    const normalAdminChatOpen = useRef<any>(false);
    const typingRef = useRef<boolean>(true);
    const socketPayload = useSelector((state: RootState) => state.auth?.socketData);
    const [chatResolved, setChatResolved] = useState(false);
    const [chatAssigned, setChatAssigned] = useState(false);
    

    const dispatch = useDispatch();

    const subscribeAdminActive = () => {
        socketInstance.emit(SOCKET_ADMIN_ACTIVE, {});
    }

    const setSuperAdminChatOpen = (isOpen: boolean) => {
        superAdminChatOpen.current = isOpen;
        setIsChatOpen(isOpen);
        if(!isOpen){
            setSuperAdminMessages(null!);
        }
    }

    const setNormalAdminChatOpen = (isOpen: boolean) => {
        normalAdminChatOpen.current = isOpen;
    }

    const getSuperAdminChatOpen = () => {
        return superAdminChatOpen?.current;
    }

    const subscribeToAllChats = (chats: any[]) => {
        if (chats && socketInstance) {
            const user = JSON.parse(Cookies.get('user') || "");

            chats.forEach((chat: any) => {
                if (chat.respondent_id === user.id) {
                    emitSuperAdminChatSubscribe(chat.id);
                }
            })
        }
    }

    const unsubscribeToAllChats = (chats: any[]) => {
        if (chats && socketInstance) {
            const user = JSON.parse(Cookies.get('user') || "");
            chats.forEach((chat: any) => {
                if (chat.respondent_id === user.id) {
                    emitSuperAdminChatUnsubscribe(chat.id);
                }
            })
        }
    }

    const emitSuperAdminChatUnsubscribe = (threadId: any) => {
        socketInstance.emit(SOCKET_SUPER_ADMIN_CHAT_UNSUBSCRIBE, {
            thread_id: threadId
        });
    }

    const emitSuperAdminChatSubscribe = (threadId: any, callBack?: Function) => {
        socketInstance.emit(SOCKET_SUPER_ADMIN_CHAT_SUBSCRIBE, {
            thread_id: threadId
        }, (response: any) => {
            if(!response.success && !isSuperAdmin){
                callBack && callBack(response.message);
            }
        });
    }

    const emitSuperAdminConnect = (status: number, callback?: Function) => {
        socketInstance.emit(SOCKET_SUPER_ADMIN_CONNECT, {
            "status_id": status
        }, (res: any) => {
            callback && callback(res);
        });
    }

    const emitSuperAdminSubscribeNotification = () => {
        socketInstance.emit(SOCKET_SUPER_ADMIN_CHAT_SUBSCRIBE_NOTIFICATIONS, {});
    }

    const superAdminTypingEvent = (threadId: any, status: string) => {
        socketInstance.emit(SOCKET_SUPER_ADMIN_TYPING_EVENT, {
            thread_id: threadId,
            status
        })
    }

    const headerNotificationHandler = (data: any) => {
        const {active_count, notification_id} = data;

        playSound(notification_id);
        if (headerNotification === null) {
            setHeaderNotification(active_count);

        } else {
            setHeaderNotification(headerNotification + active_count);
        }

        dispatch({
            type: 'USER_PROFILE_NOTIFICATION_COUNT',
            payload: {...auth.colorProfile , support_ticket_notification_count: auth.colorProfile.support_ticket_notification_count + active_count},
        });
    }
    

    const chatSubscribeNotificationHandler = () => {
        setChatSubscribeNotification(socketData);
        // !thread_assigned && 

        if(socketData?.new_chats > 0){
            playSound(socketData?.notification_id);
            setChatNotification((prev: any) => {
                return {
                    ...(prev && prev),
                    new_chats: socketData?.new_chats || 0
                }
            });
        }

        if(socketData?.thread_assigned > 0){
            setChatNotification((prev: any) => {
                if(prev){
                    const {new_chats, ...otherData} = prev;
                    return {
                        ...otherData,
                        new_chats: socketData?.new_chats || 0
                    }
                }else{
                    return null!;
                }
            })
        }
        
        if(!superAdminChatOpen.current){
            if(socketData?.unread_message){
                playSound(socketData?.notification_id);
            }
            setChatNotification(socketData);
        }
    }

    const chatEventHandler = (data: any) => {
        const {key, ...otherData} = data;

        if(isSuperAdmin){
            if(otherData.thread_id && !isChatOpen){
                setChatNotification((prev: any) => ({
                    ...socketData,
                    ...prev,
                    new_chats: (prev?.new_chats || 0) + 1,
                    assigned: true
                }));
            }else if(otherData.thread_id && !otherData.unread_messages && isChatOpen){
                setSuperAdminMessages({
                    ...otherData,
                    assigned: true
                });
            }else{
                setSuperAdminMessages({
                    ...otherData,
                    assigned: false
                });
            }
            return;
        }
        
        setNormalAdminMessages(otherData);
       
        if(otherData.thread_id && !normalAdminChatOpen.current){
            setChatNotification({
                ...socketData,
                unread_message: socketData?.unread_messages || 0
            });
        }

    }

    const chatTypingHandler = (socketData: any) => {
        if(isSuperAdmin){
            socketData.status === 'start_typing'
            ? setSuperAdminChatTyping(socketData)
            : setSuperAdminChatTyping(null!);

            return;
        }
        
        socketData.status === 'start_typing'
        ? setChatTyping(socketData)
        : setChatTyping(null!)
    }

    const adminActiveHandler = (socketData: any) => {
        if(!isSuperAdmin){
            setAdminActive(socketData)
        }
    }

    const socketEventHandler = () => {
        const {type, key} = socketData;
        const eventKey = type || key;

        switch(eventKey){
            case SOCKET_SUPER_ADMIN_NOTIFICATIONS:
                headerNotificationHandler(socketData)
                break;
            case SOCKET_SUPER_ADMIN_CHAT_SUBSCRIBE_NOTIFICATIONS:
                chatSubscribeNotificationHandler();
                break;
            case SOCKET_SUPER_ADMIN_CHAT_EVENTS:
                chatEventHandler(socketData);
                break;         
            case SOCKET_SUPER_ADMIN_TYPING_EVENT:
                chatTypingHandler(socketData);
                break;
            case SOCKET_ADMIN_ACTIVE:
                adminActiveHandler(socketData);
                break;
            default:
        }
    }

    const emitTypingEvent = (threadId: number) => {
        if(typingRef.current){
            typingRef.current = false;
            superAdminTypingEvent(threadId, 'start_typing');
        }

        stopTyping(threadId);
    }

     /* eslint-disable react-hooks/exhaustive-deps */
    const stopTyping = useCallback(
        _.debounce((thread_id: any) => {
            superAdminTypingEvent(thread_id, 'stop_typing');
            typingRef.current = true
        }, 1000),
        []
    );

    useEffect(() => {
        if(!socketData){
            return;
        }
        dispatch(setSocketPayload(null!));
        socketEventHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[socketData]);

    useEffect(() => {
        if(!socketPayload){
            return;
        }
        
        setSocketData(socketPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[socketPayload]);

    const settingHeaderState = (val: number) => {
        setHeaderNotification(val);
    }

    return <>
        <ChatSocketContext.Provider value={{
            chatTyping,
            superAdminChatTyping,
            emitTypingEvent,
            getSuperAdminChatOpen,
            setSuperAdminChatOpen,
            setNormalAdminChatOpen,
            normalAdminMessages,
            superAdminMessages,
            setCurrentStatus,
            currentStatus,
            unsubscribeToAllChats,
            emitSuperAdminChatUnsubscribe,
            setChatNotification,
            emitSuperAdminSubscribeNotification,
            chatNotification,
            emitSuperAdminChatSubscribe,
            headerNotification,
            socketData,
            subscribeToAllChats,
            emitSuperAdminConnect,
            chatSubscribeNotification,
            subscribeAdminActive,
            adminActive,
            setNormalAdminMessages,
            setChatResolved,
            chatResolved,
            setChatAssigned,
            chatAssigned,
            settingHeaderState,
        }}>
            {children}
        </ChatSocketContext.Provider>
    </>
}
export default SocketContextProvider