import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import ArrowDownImg from "assets/img/drop-black-icon-down.svg";
import PerformanceSets from "components/common/studentInfoComponents/PerformanceSets";
import Skeleton from "react-loading-skeleton";
import { useState } from "react";
import ReportCardSkeleton from "./ReportCardSkeleton";

const Performance = ({
  performance,
  classrooms,
  handleSortClick,
  getClassName,
  skeletonLoad,
  showLastThreeAttempts,
  showAllClasses,
  performanceSets,
  isPrev = false,
}: any) => {
  const [attemptTwoExists, setAttemptTwoExists] = useState(false);
  const [attemptThreeExists, setAttemptThreeExists] = useState(false);
  return (
    <div className="studentPerformance">
      {!skeletonLoad &&
      performance &&
      Object.keys(performance?.tests || {}).length ? (
        Object.keys(performance?.tests || {})?.map((item: any, i: number) => {
          let subject = 2;

          if (item?.indexOf("Math") > -1) {
            subject = 1;
          } else if (item?.indexOf("Science") > -1) {
            subject = 3;
          }

          return (
            <>
              {showAllClasses && !isPrev && i > 0 && (
                <PerformanceSets
                  performance={performanceSets}
                  performanceEnabled={true}
                  subject={subject}
                ></PerformanceSets>
              )}
              <div className="reportCardPerformanceDetails" key={i}>
                <div className="reportCardPerformanceDetails__header">
                  <section className="reportCardPerformanceDetails__header-heading">
                    <h3 className="reportCardPerformanceDetails__header-title">
                      {item}
                    </h3>
                  </section>
                </div>
                <div className="reportCardPerformanceDetails-table-box">
                  <div className="reportCardPerformanceDetails-table-container">
                    <div className="table-responsive">
                      <table className="reportCardPerformanceDetails__table">
                        <thead className="reportCardPerformanceDetails__table-header">
                          <tr className="reportCardPerformanceDetails__table-row">
                            <th className="reportCardPerformanceDetails__table-completed">
                              Completed Summative
                            </th>
                            <th
                              className="reportCardPerformanceDetails__table-date-attempts"
                              onClick={() => {}}
                            >
                              Date
                            </th>
                            <th className="reportCardPerformanceDetails__table-score">
                              Attempts
                            </th>
                            {showLastThreeAttempts ? (
                              <>
                                <th className="reportCardPerformanceDetails__table-score">
                                  Attempt 1
                                </th>

                                {attemptTwoExists ? (
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 2
                                  </th>
                                ) : (
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                )}

                                {attemptThreeExists ? (
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 3
                                  </th>
                                ) : (
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                )}
                                <th className="reportCardPerformanceDetails__table-score"></th>
                              </>
                            ) : (
                              <>
                                <th className="reportCardPerformanceDetails__table-score">
                                  Last Score
                                </th>
                                <th className="reportCardPerformanceDetails__table-score"></th>
                                <th className="reportCardPerformanceDetails__table-score"></th>
                                <th className="reportCardPerformanceDetails__table-score"></th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {!skeletonLoad &&
                          classrooms[item]?.summatives?.length > 0 ? (
                            classrooms[item]["summatives"].map(
                              (test: any, i: number) => {
                                const attempts = test?.scored_summatives || [];

                                if (
                                  attempts[1]?.score_percentage &&
                                  !attemptTwoExists
                                ) {
                                  setAttemptTwoExists(true);
                                }

                                if (
                                  attempts[2]?.score_percentage &&
                                  !attemptThreeExists
                                ) {
                                  setAttemptThreeExists(true);
                                }

                                return (
                                  <tr
                                    key={i}
                                    className="reportCardPerformanceDetails__table-row reportCardPerformanceDetails__table-row-border"
                                  >
                                    <td className="completed-data">
                                      {test?.name}
                                    </td>
                                    <td>{test?.date ? test?.date : "-"}</td>
                                    <td className="reportCardPerformanceDetails__table-attempts">
                                      {test?.attempts}
                                    </td>
                                    {showLastThreeAttempts ? (
                                      <>
                                        <td>
                                          {attempts[0]?.score_percentage && (
                                            <span
                                              className={`level-box level-box-${attempts[0]?.score_percentage} level-box-radius`}
                                            ></span>
                                          )}
                                          {attempts[0]?.score_percentage}
                                        </td>
                                        <td>
                                          {attempts[1]?.score_percentage && (
                                            <span
                                              className={`level-box level-box-${attempts[1]?.score_percentage} level-box-radius`}
                                            ></span>
                                          )}
                                          {attempts[1]?.score_percentage}
                                        </td>
                                        <td>
                                          {attempts[2]?.score_percentage && (
                                            <span
                                              className={`level-box level-box-${attempts[2]?.score_percentage} level-box-radius`}
                                            ></span>
                                          )}
                                          {attempts[2]?.score_percentage}
                                        </td>
                                      </>
                                    ) : (
                                      <td>
                                        {test?.last_score && (
                                          <span
                                            className={`level-box level-box-${test?.last_score} level-box-radius`}
                                          ></span>
                                        )}
                                        {test?.last_score}
                                      </td>
                                    )}
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan={showLastThreeAttempts ? 6 : 4}
                                className="reportCardPerformanceDetails__table_no-data"
                              >
                                No Records
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {subject !== 3 && (
                    <div className="reportCardPerformanceDetails-table-container">
                      <div className="table-responsive">
                        <table className="reportCardPerformanceDetails__table">
                          <thead className="reportCardPerformanceDetails__table-header">
                            <tr className="reportCardPerformanceDetails__table-row">
                              <th className="reportCardPerformanceDetails__table-completed">
                                Completed Formatives
                              </th>
                              <th
                                className="reportCardPerformanceDetails__table-date-attempts"
                                onClick={() =>
                                  handleSortClick({
                                    className: item,
                                    isSummative: false,
                                  })
                                }
                              >
                                Date
                                <img
                                  src={ArrowDownImg}
                                  className={getClassName({
                                    className: item,
                                    isSummative: false,
                                  })}
                                  alt="arrow icon"
                                />
                              </th>
                              <th className="reportCardPerformanceDetails__table-score">
                                Attempts
                              </th>
                              {showLastThreeAttempts ? (
                                <>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 1
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 2
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 3
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                </>
                              ) : (
                                <>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Last Score
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                </>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {/* {skeletonLoad ? (
                              <TableSkeleton
                                columns={showLastThreeAttempts ? 6 : 4}
                                count={10}
                                height={36}
                              />
                            ) : (
                              ""
                            )} */}
                            {classrooms[item]?.formatives?.length > 0 ? (
                              classrooms[item]["formatives"].map(
                                (test: any, i: number) => {
                                  const attempts =
                                    test?.scored_formatives || [];
                                  return (
                                    <tr
                                      key={i}
                                      className="reportCardPerformanceDetails__table-row reportCardPerformanceDetails__table-row-border"
                                    >
                                      <td className="completed-data">
                                        {test?.name}
                                      </td>
                                      <td>{test?.date ? test?.date : "-"}</td>
                                      <td className="reportCardPerformanceDetails__table-attempts">
                                        {test?.attempts}
                                      </td>
                                      {showLastThreeAttempts ? (
                                        <>
                                          <td>
                                            {attempts[0]?.score_percentage && (
                                              <span
                                                className={`level-box level-box-${attempts[0]?.score_percentage} level-box-radius`}
                                              ></span>
                                            )}
                                            {attempts[0]?.score_percentage}
                                          </td>
                                          <td>
                                            {attempts[1]?.score_percentage && (
                                              <span
                                                className={`level-box level-box-${attempts[1]?.score_percentage} level-box-radius`}
                                              ></span>
                                            )}
                                            {attempts[1]?.score_percentage}
                                          </td>
                                          <td>
                                            {attempts[2]?.score_percentage && (
                                              <span
                                                className={`level-box level-box-${attempts[2]?.score_percentage} level-box-radius`}
                                              ></span>
                                            )}
                                            {attempts[2]?.score_percentage}
                                          </td>
                                        </>
                                      ) : (
                                        <td>
                                          {test?.last_score && (
                                            <span
                                              className={`level-box level-box-${test?.last_score} level-box-radius`}
                                            ></span>
                                          )}
                                          {test?.last_score}
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td
                                  colSpan={showLastThreeAttempts ? 6 : 4}
                                  className="reportCardPerformanceDetails__table_no-data"
                                >
                                  No Records
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div className="reportCardPerformanceDetails">
          <div className="reportCardPerformanceDetails__header">
            <section className="reportCardPerformanceDetails__header-heading">
              <h3 className="reportCardPerformanceDetails__header-title">
                <Skeleton
                  baseColor="#9c9fa343"
                  highlightColor="#d2d5db32"
                  width={220}
                />
              </h3>
            </section>
          </div>
          <div className="reportCardPerformanceDetails-table-box">
            <div className="reportCardPerformanceDetails-table-container">
              <div className="table-responsive">
                <table className="reportCardPerformanceDetails__table">
                  <thead className="reportCardPerformanceDetails__table-header">
                    <tr className="reportCardPerformanceDetails__table-row">
                      <TableSkeleton columns={7} count={1} height={36} />
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((id) => {
                      return <ReportCardSkeleton loading i={id - 1} />;
                    })}

                    {/* <TableSkeleton
                      columns={showLastThreeAttempts ? 7 : 4}
                      count={10}
                      height={36}
                    /> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Performance;
