import UserDropDown from "../UserDropdown";
import NavItem from "./navItem";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { RootState } from "redux/reducers/combine";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import Logo from "components/common/svgs/logo";
import { getDistrictLogoSelector } from "redux/actionCreators/management";
import { useSelectedDistrict } from "hooks/useSelectedDistrict";

const HeaderTopbar = ({ lastAssementClass }: { lastAssementClass: string }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedDistrictId = useSelectedDistrict();
  const { tooltip } = useContext(ClassroomTooltipContext);

  const isClassroomPage = pathname.includes("classroom");
  const isPacingCalendar = pathname.includes("pacing-calendar");
  const isMasteryVideos = pathname.includes("mastery-videos");

  const isClassroomActive =
    isClassroomPage || isPacingCalendar || pathname.includes("discussions");
  const isActivityActive = pathname.includes("activity");

  const isClassroomLikePages =
    isClassroomPage || isPacingCalendar || isMasteryVideos;

  const isManagementPage = pathname.includes("management");
  const isManagementActive =
    isManagementPage || pathname.includes("super-admin");

  const addClassRadious = pathname.indexOf("discussions") === -1;

  const [open, setOpen] = useState(false);

  const app = useSelector((state: RootState) => state);

  const userRole = Cookies.get("role");
  // const allAreNoStudents = app.classroom.classrooms?.every((item: any) => item.students_count === 0);

  const hideActivity = userRole === "Classroom Admin";
  const isSchoolAdmin = userRole === "School Admin";
  const districts = app.activity.districtsList;
  const districtSchools = app.activity.schoolsList;

  const isDistrictDropdown = isManagementPage
    ? Number(userRole === "District Admin" && districts.length > 1)
    : Number(userRole === "Super Admin" || districts.length > 1);
  const isSchoolDropdown = isManagementPage
    ? Number(userRole === "School Admin" && districtSchools.length > 1)
    : Number(
        (userRole !== "School Admin" && userRole !== "Classroom Admin") ||
          districtSchools.length !== 1
      );
  const isClassroomDropdown = isManagementPage
    ? Number(
        userRole === "Classroom Admin" && app.classroom.classrooms.length > 1
      )
    : isActivityActive
    ? Number(userRole === "Classroom Admin") + 2
    : 1;

  const managementArrow =
    (pathname.includes("/management") &&
      userRole === "District Admin" &&
      districts.length > 1) ||
    (pathname.includes("/management") &&
      districtSchools.length > 1 &&
      userRole === "School Admin") ||
    (pathname.includes("/management") &&
      userRole === "Classroom Admin" &&
      app.classroom.classrooms.length > 1);

  const activityArrow = pathname.includes("/activity");

  const classrromArrow =
    pathname.includes("/classroom") || pathname.includes("/pacing-calendar");

  const dropdownsLength =
    isDistrictDropdown + isSchoolDropdown + isClassroomDropdown;
  // const showRadius = (userRole === "Classroom Admin" && app.classroom.classrooms.length > 1) ||
  // (userRole === "District Admin" && districts.length > 1) ||
  // (userRole === "School Admin" && districtSchools.length > 1)

  // const [selectedDistrict, setSelectedDistrict] = useState<any>(null);

  const districtLogo = useSelector(getDistrictLogoSelector);
  let district_logo = app.activity.districtsList?.find(
    (item: any) => item.id === Number(selectedDistrictId)
  )?.logo_filename;
  const addClassRadiousForLogo =
    pathname.indexOf("activity") === -1 &&
    pathname.indexOf("classroom") === -1 &&
    pathname.indexOf("mastery-videos") === -1 &&
    pathname.indexOf("pacing-calendar") === -1 &&
    pathname.indexOf("pacing-calendar") === -1 &&
    pathname.indexOf("discussions") === -1 &&
    pathname.indexOf("management") === -1;
  district_logo = addClassRadiousForLogo ? districtLogo : district_logo;

  useEffect(() => {
    if (hideActivity && pathname.includes("/activity")) {
      // setTooltip(null!)
      navigate("/classroom");
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [app.classroom.classrooms]);
  const isSuperAdmin = userRole === "Super Admin";
  const isClassroomAdmin = userRole === "Classroom Admin";
  const isDistrictAdmin = userRole === "District Admin";
  return (
    <header
      className={`mian_header ${
        isClassroomAdmin && (isManagementPage || isClassroomPage)
          ? "classroomAdminBorder"
          : ""
      } ${isDistrictAdmin && isActivityActive ? "districtAdminBorder" : ""}  ${
        isManagementPage && app.classroom.classrooms?.length === 1
          ? "classroom-admin-mng"
          : ""
      } ${isSuperAdmin ? "super-admin-mng" : ""} ${
        isSchoolAdmin && isManagementPage ? "school-admin-mng" : ""
      } ${
        isSchoolAdmin && activityArrow ? "school-admin-activity" : ""
      } ${lastAssementClass} ${open ? "header-open" : ""} ${
        (isClassroomLikePages ? dropdownsLength > 1 : dropdownsLength > 3) ||
        !addClassRadious
          ? "radious"
          : ""
      }`}
    >
      <div className="logo">
        <Logo />
      </div>
      {
        <div className="district-logo">
          {district_logo ? <img src={district_logo} alt="District Logo" /> : ""}
        </div>
      }

      <div className="nav-dropdown-sec">
        <nav>
          {!hideActivity && (
            <NavItem name="Activity" isActive={isActivityActive} tabIndex={0} />
          )}
          <NavItem name="Classroom" isActive={isClassroomActive} tabIndex={0} />
          <NavItem
            name="Management"
            isActive={isManagementActive}
            tabIndex={0}
          />
          {districts.length > 0 && !tooltip && (
            <div
              className={`${hideActivity ? "classroom-admin-arrow" : ""} ${
                activityArrow || classrromArrow || managementArrow
                  ? "header__top-arrow-animation"
                  : "header__top-arrow"
              } ${
                activityArrow
                  ? "activity_width"
                  : classrromArrow
                  ? "classroom_width"
                  : managementArrow
                  ? "management_width"
                  : ""
              } `}
            ></div>
          )}
        </nav>
        <UserDropDown />
      </div>
      <div
        onClick={() => setOpen((prev) => !prev)}
        className="navBar-toggler-btn"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
};

export default HeaderTopbar;
