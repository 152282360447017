import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AssessmentStats from "./assessmentStats";
import { RootState } from "redux/reducers/combine";
import { useDispatch, useSelector } from "react-redux";
import { getClassroomActivity } from "services/common";
import moment from "moment";
import ActivityDropDowns from "../activityDropdowns";
import ClassroomDropDowns from "../classroomDropDowns";
import ManagementDropDowns from "../mngDropdowns";
import _ from "lodash";
import {
  getTeachersBySchools,
  setTeacherId as setTeacherIdState,
  setSchoolId,
  setGradeId as setGradeIdState,
  setDistrictId as setDistrictIdState,
  setFilters,
  setSubjectId,
  setActivityClassroomId,
} from "redux/actionCreators/activity";
import {
  setClassroomSchoolId,
  setClassroomId,
  setGrade as setClassroomGrade,
  setSubject as setClassroomSubject,
  setDistrictId,
  fetchSchoolClassrooms,
} from "redux/actionCreators/classroom";
import {
  setDistrictMId,
  setSchoolMId,
  setClassroomMId,
} from "redux/actionCreators/management";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ActivityModal from "./activityModal";
import { useShowSurvey, useTooltipSeen } from "hooks/useShowSurvey";
import { getReportFieldName } from "util/index";

export interface ActivityResponse {
  a1_proficient: number;
  a2_proficient: number;
  all_formatives_taken: boolean;
  b_proficient: number;
  classroom_id: number;
  last_test_time: string;
  objectives_taken: number;
  proficiency: number;
  total_objectives: number;
}
interface IProps {
  isClassroomScreen: boolean;
  isActivityScreen: boolean;
  isManagementScreen: boolean;
  activityData : any,
  setActivityData : (data: any)=>void,
  selectedClassroomData: any
}

// let isAutoOpenedOneTime = false;

const ActivityBar = ({
  isClassroomScreen,
  isActivityScreen,
  isManagementScreen,
  activityData, 
  setActivityData, 
  selectedClassroomData
  
}: IProps) => {
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const app = useSelector((state: RootState) => state);
  const classrooms = app.classroom.classrooms;

  const districts = app.activity.districtsList;
  const isManagementPage = pathname.includes("/management");
  const isActivityActive = pathname.includes("/activity");

  const openActivityModal = useRef<boolean | null>(null);

  //const classroom = app.classroom;
  // const classroomId = isManagementPage
  //   ? app.management?.classroom_m_id || classrooms[0]?.id
  //   : classroom.classroomId;

  const { getShowSurvey } = useShowSurvey();
  const { isTooltipSeen } = useTooltipSeen();

  const isClassroomId = sessionStorage.getItem("c_classroom_id");

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolOpen, setSchoolOpen] = useState<boolean>();
  const [classroomOpen, setClassroomOpen] = useState<boolean>();

  // const [activityData, setActivityData] = useState<ActivityResponse[]>([]);
  // const selectedClassroomData = activityData.find(
  //   (item) => item.classroom_id === classroomId
  // );




  

  const districtSchools = app.activity.schoolsList;
  const userRole = Cookies.get("role");

  // const fetchAssessmentActivity = async (classroomId: string) => {
  //   const res = await getClassroomActivity(classroomId);
  //   if(res?.code === 200){
  //     setActivityData(res.data);
  //   }
  // }



 


  const applyFilters = () => {
    dispatch(setFilters(true));
  };

  const onSchoolChangeActivity = async (event: any) => {
    dispatch(setTeacherIdState(0));
    dispatch(setGradeIdState(0));
    dispatch(setSubjectId(0));
    dispatch(setActivityClassroomId(0));
    dispatch(setSchoolId(event.id === 0 ? 0 : event.id));
    applyFilters();
    if (event.id > 0) {
      userRole === "Classroom Admin"
        ? await dispatch(fetchSchoolClassrooms(event.id))
        : await dispatch(
            getTeachersBySchools({
              params: {
                school_id: event.id,
              },
            })
          );
    }
  };

  const setAutoSelectSchool = async (inputValue: number) => {
    /* If only one school is there then it should auto select*/
    if (
      districtSchools.filter((scl: any) => scl.district_id === inputValue)
        .length === 1
    ) {
      isClassroomScreen && setSchoolOpen(undefined);
      isClassroomScreen
        ? await onSchoolChangeClassroom({
            id: districtSchools.find(
              (scl: any) => scl.fk_district_id === inputValue
            )?.id,
          })
        : await onSchoolChangeActivity({
            id: districtSchools.find(
              (scl: any) => scl.fk_district_id === inputValue
            )?.id,
          });
    } else {
      inputValue ? setSchoolOpen(true) : setSchoolOpen(undefined);
    }
  };

  const onDistrictChangeActivity = async (e: any) => {
    const inputValue = parseInt(e.id);
    dispatch(setSchoolId(0));
    dispatch(setTeacherIdState(0));
    dispatch(setGradeIdState(0));
    dispatch(setSubjectId(0));
    dispatch(setActivityClassroomId(0));
    dispatch(setDistrictIdState(!inputValue ? 0 : inputValue));
    applyFilters();
    await setAutoSelectSchool(inputValue);
  };

  const onDistrictChangeClassroom = async (e: any) => {
    const inputValue = parseInt(e.id);
    dispatch(setClassroomSchoolId(0));
    dispatch(setClassroomId(0));
    dispatch(setClassroomGrade(0));
    dispatch(setClassroomSubject(0));
    dispatch(setDistrictId(!inputValue ? 0 : inputValue));
    applyFilters();
    setAutoSelectSchool(inputValue);
  };

  const onDistrictChangeManagement = (e: any) => {
    const inputValue = parseInt(e.id);
    dispatch(setDistrictMId(inputValue));
  };

  const onSchoolChangeManagement = (e: any) => {
    const inputValue = parseInt(e.id);
    dispatch(setSchoolMId(inputValue));
  };

  const onClassChangeManagement = (e: any) => {
    const inputValue = parseInt(e.id);
    dispatch(setClassroomMId(inputValue));

    onChangeClassroom(e)
  };

  const setTeacherId = (event: any) => {
    dispatch(setGradeIdState(0));
    dispatch(setSubjectId(0));
    dispatch(setActivityClassroomId(0));
    dispatch(setTeacherIdState(event.id === 0 ? 0 : event.id));
    applyFilters();
  };

  const setGradeId = (event: any) => {
    if (event.id === 0) {
      dispatch(setGradeIdState(0));
      dispatch(setSubjectId(0));
      dispatch(setGradeIdState(0));
    }
    dispatch(setGradeIdState(event.id));
    dispatch(setSubjectId(event.id === 0 ? 0 : event.subject_id));
    applyFilters();
  };

  const onSchoolChangeClassroom = async (e: any) => {
    const inputValue = parseInt(e.id);
    dispatch(setClassroomId(0));
    dispatch(setClassroomGrade(0));
    dispatch(setClassroomSubject(0));
    dispatch(setClassroomSchoolId(!inputValue ? 0 : inputValue));
    inputValue &&
      (await dispatch(
        fetchSchoolClassrooms(inputValue, (open: boolean) => {
          setClassroomOpen(open === false ? undefined : open);
        })
      ));

    if (inputValue) {
      const showSendSchoolId = userRole !== "Classroom Admin";
      const res = await getClassroomActivity(
        showSendSchoolId ? inputValue : undefined
      );

      if (res?.code === 200) {
        setActivityData(res.data?.classrooms || []);
      }
    }

    applyFilters();
  };

  const navigate = useNavigate();

  const onChangeClassroom = (e: any) => {
    (window as any)['classroomId'] = "";
    openModal && setOpenModal(false);
    if (state) {
      "student" in state && delete state.student;
      "classroomId" in state && delete state.classroomId;
      navigate(pathname, { state });
    }
    const inputValue = parseInt(e.id);
    if (!inputValue) {
      dispatch(setClassroomId(0));
      dispatch(setClassroomGrade(0));
      dispatch(setClassroomSubject(0));
      applyFilters();
      return;
    }
    const gradeId = parseInt(e.fk_grade_id);
    const subjectId = parseInt(e.fk_subject_id);
    dispatch(setClassroomGrade(gradeId));
    dispatch(setClassroomSubject(subjectId));
    dispatch(setClassroomId(inputValue));
    applyFilters();

    if (pathname === "/classroom/resources" && gradeId !== 9 && e.students_count !== 0) {
      navigate("/classroom");
    }
  };

  const onChangeClassroomActivity = (e: any) => {
    const inputValue = parseInt(e.id);
    if (!inputValue) {
      dispatch(setActivityClassroomId(0));
      dispatch(setGradeIdState(0));
      dispatch(setSubjectId(0));
      return;
    }
    const gradeId = parseInt(e.fk_grade_id);
    const subjectId = parseInt(e.fk_subject_id);
    dispatch(setGradeIdState(gradeId));
    dispatch(setSubjectId(subjectId));
    dispatch(setActivityClassroomId(inputValue));
    applyFilters();
  };

  const formattedDate = selectedClassroomData?.last_test_time
    ? moment(selectedClassroomData.last_test_time).format("M-D-YY")
    : "";

  const teacher_id = app.activity.teacher_id;

  let grades = teacher_id
    ? app.activity.gradsList.filter(
        (item: any) => item.teacher_id === teacher_id
      )
    : app.activity.gradsList;
  grades = _.uniqBy(grades, function (e: any) {
    return e.name;
  });

  const teachers = app.activity.teachersList;
  let isOtherScreen =
    pathname.indexOf("monitor") > -1 ||
    pathname.indexOf("answer") > -1 ||
    (isClassroomScreen ? false : pathname.indexOf("report") > -1) ||
    // pathname.indexOf("classroom/resources") > -1 ||
    pathname.indexOf("mastery-videos") > -1;

  const isDistrictDropdown = isManagementPage
    ? Number(userRole === "District Admin" && districts.length > 1)
    : Number(userRole === "Super Admin" || districts.length > 1);
  const isSchoolDropdown = isManagementPage
    ? Number(userRole === "School Admin" && districtSchools.length > 1)
    : Number(
        (userRole !== "School Admin" && userRole !== "Classroom Admin") ||
          districtSchools.length !== 1
      );
  const isClassroomDropdown = isManagementPage
    ? Number(
        userRole === "Classroom Admin" && app.classroom.classrooms.length > 1
      )
    : isActivityActive
    ? Number(userRole === "Classroom Admin")
    : 1;

  const dropdownsLength =
    isDistrictDropdown + isSchoolDropdown + isClassroomDropdown;

  const fetchAcitityData = async (school_id: number) => {
    setLoading(true);
    const showSendSchoolId = userRole !== "Classroom Admin";
    const res = await getClassroomActivity(
      showSendSchoolId ? school_id : undefined
    );
    setLoading(false);

    if (res?.code === 200) {
      setActivityData(res.data?.classrooms || []);
    }
  };

  const onClickClassroomDropdown = () => {
    setOpenModal((prev) => !prev);
  };

  const onCloseModal = useCallback(() => {
    setOpenModal(false)
  },[]);

  useEffect(() => {
    const userRole = Cookies.get("role");
    const schoolId = userRole === 'Classroom Admin' ? app.activity.schoolsList[0]?.id : app.classroom.classroomSchoolId
    if (schoolId > 0) {
      fetchAcitityData(schoolId);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [app.classroom.classroomSchoolId, app.activity.schoolsList]);

  useEffect(() => {
    const iSeen = isTooltipSeen() && Cookies.get("showSurvey") !== "false";
    const isSatisfactionSurvey = getShowSurvey(iSeen);

    openActivityModal.current =
      openActivityModal.current === null
        ? userRole === "Classroom Admin" && !isClassroomId && isClassroomScreen
        : openActivityModal.current;

    if (
      !isSatisfactionSurvey &&
      openActivityModal.current &&
      app.classroom.classrooms?.length > 1
    ) {
      setOpenModal(true);
      openActivityModal.current = false;
    }
  }, [userRole, app.classroom.classrooms, app.auth]);

  const isShowStats =
    isManagementScreen &&
    userRole === "Classroom Admin" &&
    classrooms.length > 1;

  const isShowActivityBar =
    (isActivityScreen ||
      isClassroomScreen ||
      (userRole === "Classroom Admin" && classrooms.length > 1) ||
      (userRole === "District Admin" && districts.length > 1) ||
      (userRole === "School Admin" && districtSchools.length > 1)) &&
    districts.length > 0;
   
  const fieldName = useMemo(() => {
      if (pathname.includes("report")) {
        return getReportFieldName(pathname);
      } else return "";
    }, [pathname]);
  if (
    (!isShowActivityBar && isManagementScreen) ||
    pathname.includes("super-admin") ||
    pathname.includes("support-tickets")
  ) {
    return;
  }

  return (
    <div
    className={`subHeader-container schoolAdmin-tab ${
      app.activity.districtLoading || dropdownsLength === 3
        ? "three-dropdowns"
        : dropdownsLength === 2
        ? "two-dropdowns"
        : ""
    } `}
    >
      <div className={`subHeader`}>
        <div
          className={`headerLeft ${
            isActivityScreen ? "activity-bar-dropDowns" : ""
          }`}
        >
          <div className="admin-dropDown">
            {/* <div className="subHeader-fieldLabel">Classroom</div> */}
            <div className="drop-down">
              {isClassroomScreen && (
                <ClassroomDropDowns
                  onDistrictChange={onDistrictChangeClassroom}
                  onSchoolChange={onSchoolChangeClassroom}
                  userRole={userRole}
                  classrooms={classrooms}
                  onChangeClassroom={onChangeClassroom}
                  isOtherScreen={isOtherScreen}
                  onClickClassroomDropdown={onClickClassroomDropdown}
                  setSchoolOpen={setSchoolOpen}
                  schoolOpen={schoolOpen}
                  setClassroomOpen={setClassroomOpen}
                  classroomOpen={classroomOpen}
                />
              )}
              {isActivityScreen && (
                <ActivityDropDowns
                  onDistrictChange={onDistrictChangeActivity}
                  setTeacherId={setTeacherId}
                  setGradeId={setGradeId}
                  onSchoolChange={onSchoolChangeActivity}
                  onChangeClassroom={onChangeClassroomActivity}
                  applyFilters={applyFilters}
                  userRole={userRole}
                  teachers={teachers}
                  grades={grades}
                  classrooms={classrooms}
                  isOtherScreen={isOtherScreen}
                />
              )}
              {isManagementScreen && userRole !== "Super Admin" && (
                <ManagementDropDowns
                  onDistrictChange={onDistrictChangeManagement}
                  onSchoolChange={onSchoolChangeManagement}
                  onChangeClassroom={onClassChangeManagement}
                  onClickClassroomDropdown={onClickClassroomDropdown}
                  userRole={userRole}
                  classrooms={classrooms} 
                  setClassroomOpen={setClassroomOpen} 
                  isOtherScreen={isOtherScreen} 
                />
              )}
            </div>
          </div>
          {((isClassroomScreen && dropdownsLength < 2) || isShowStats) &&
            formattedDate && (
              <div className="last-assessment">
                <div className="subHeader-fieldLabel">Last Assessment</div>
                <p className="lastAssessment-date">{formattedDate}</p>
              </div>
            )}
        </div>
        {(isClassroomScreen || isShowStats) && (
          <AssessmentStats
            loading={loading}
            isThreeDropdowns={
              app.activity.districtLoading || dropdownsLength >= 3
            }
            activityData={selectedClassroomData}
          />
        )}
      </div>
      {openModal && (
        <ActivityModal
          closeModal={onCloseModal}
          onChangeClassroom={(val) => {
            onChangeClassroom(val);
            onClassChangeManagement(val);
          }}
          loading={loading}
          activityData={activityData}
          fieldName = {fieldName}
        />
      )}
    </div>
  );
};

export default ActivityBar;