import BenchmarkModal from "components/common/reportModal/benchmarkModal";
import FormativeAssessedModal from "components/common/reportModal/formativeModal";
import { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import Cookie from "js-cookie";
import {
  getItemResponseReport,
} from "redux/actionCreators/classroom";
import { useReactToPrint } from "react-to-print";
import { RootState } from "redux/reducers/combine";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import NavPrev from "assets/img/nav-prev-icon.png";
import NavNext from "assets/img/nav-next-icon.png";
import Navtop from "assets/img/nav-top-icon.png";
import NavBottom from "assets/img/nav-bottom-icon.png";
import Cookies from "js-cookie";
import Print from "./print";
import Footer from "./Footer";
import ReportTabs from "./tabs";
import Student from "./Student";
import ReportHeader from "components/common/report/header";
import {
  TabsSkeleton,
  HeaderSkeleton,
  BodySkeleton,
} from "components/common/skeleton/ReportsSkeleton";
import { TableSelectCommon } from "components/common/selectComponent";
import { useQuery } from "hooks/useQuery";
import { replaceURL } from "util/replaceUrl";
import useClassroomMasterReport, { getSummativeId } from "hooks/useClassroomMasterReport";

const ItemResponseReport = () => {
  const dispatch = useDispatch();
  let report = useSelector((state: RootState) => state.classroom.irrSummative);
  const classrooms = useSelector(
    (state: RootState) => state.classroom.classrooms
  );
  const classroomId = useSelector(
    (state: RootState) => state.classroom.classroomId
  );
  const [isolatedStudent, setIsolatedStudent] = useState<any>(null!);
  const [showFormativeModal, setShowFormativeModal] = useState(false);
  const [showBenchmarkModal, setShowBenchMarkModal] = useState(false);
  const [search, setSearch] = useState("");
  const { id }: any = useParams();
  const classroom = (window as any)["classroomId"] || classroomId;

  const [tabIndex, setTabIndex] = useState(0);
  const [questionId, setQuestionId] = useState<number>(null!);
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isNavLeftDisabled, setIsNavLeftDisabled] = useState(true);
  const [isNavTopDisabled, setIsNavTopDisabled] = useState(true);

  const [showDropDown, setSHowDropDown] = useState<boolean>();
  const assessmentName = Cookies.get("test_name") || "";
  const questionsPerPage = 25;

  const query = useQuery();
  const print = query.get("print");
  const [expandedStudents, setExpandedStudents] = useState<any>({});
  const [dropDownLabel, setDropDownLabel] = useState<string>("Attempt");

  const {masterReport} = useClassroomMasterReport();

  useEffect(() => {
    const selectedClassroomId = (window as any)["classroomId"];
    if (selectedClassroomId) {
      dispatch(getItemResponseReport(selectedClassroomId, id));
      replaceURL(
        `/classroom/report/item-response-report-summative/${id}/${selectedClassroomId}`
      );
    }
    setIsLoading(true);
  }, [dispatch, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  useEffect(() => {
    const idToFetch = getSummativeId(masterReport);
    if (idToFetch) {
      dispatch(getItemResponseReport(classroomId, idToFetch));
      replaceURL(
        `/classroom/report/item-response-report-summative/${idToFetch}/${classroomId}`
      );
    }
    setIsLoading(true);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, masterReport]);

  // const onScroll = () => {
  // 	if (contentRef.current) {
  // 	  const { scrollLeft, clientWidth } = contentRef.current;
  // 	  if (scrollLeft === clientWidth) {
  // 		// TO SOMETHING HERE
  // 		console.log('Reached bottom')
  // 	  }
  // 	}
  // };

  const modifiedReport = useMemo(() => {
    if (!report?.data) {
      return {};
    }

    return {
      ...report,
      data: {
        ...report.data,
        students: !isolatedStudent
          ? report.data.students.map((item: any) => ({
              ...item,
              isShowIsolated: null,
            }))
          : report.data.students.map((st: any) => ({
              ...st,
              isShowIsolated: st.id === isolatedStudent.id,
            })),
      },
    };
  }, [isolatedStudent, report]);

  const { questions, students, slicedQustions } = useMemo(() => {
    if (modifiedReport?.data) {
      setIsLoading(false);
      const slicedQustions: any = modifiedReport.data.questions?.slice(
        tabIndex * questionsPerPage,
        tabIndex * questionsPerPage + questionsPerPage
      );

      return {
        ...modifiedReport.data,
        slicedQustions,
        students: modifiedReport.data.students.sort((a: any, b: any) => {
          return a.last_name.localeCompare(b.last_name);
        }),
      };
    } else {
      return {
        slicedQustions: [],
        as_of_time: "",
        class_name: "",
        questions: [],
        school_name: "",
        students: [],
        teacher_name: "",
        test_name: "",
        total_correct: [],
        total_incorrect: [],
        total_students: [],
      };
    }
  }, [modifiedReport, tabIndex]);

  const Students = useMemo(() => {
    if (!students) {
      return;
    }

    if (search) {
      return students?.filter((student: any, index: number) => {
        return (student.first_name + student.last_name)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    }
    return students;
  }, [search, students]);

  const searchChangehandler = (e: any) => {
    setSearch(e.target.value);
  };

  const OnClickQuestionNo = (id: any) => {
    id === questionId ? setQuestionId(null!) : setQuestionId(id);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `IRR_Summative_${report?.data?.class_name}_${moment(
      new Date()
    ).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (modifiedReport && print) {
      handlePrint();
    }
  }, [modifiedReport, print, handlePrint]);

  const openAssessment = () => {
    const assessmentClass = classrooms.find(
      (cls: any) => cls.id === Number(classroom)
    );
    const isMath = assessmentClass.label.includes("Math");
    Cookie.set("isMath", isMath);
    Cookie.set("assessmentName", assessmentName);
    window.open(`/preview/summatives/${id}`);
  };

  const onTabChange = (tabIndex: number) => {
    setTabIndex(tabIndex);
    setQuestionId(null!);
    setIsolatedStudent(null!);
    if (contentRef.current) {
      contentRef.current.scrollLeft = 0;
    }
  };

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = contentRef.current;
      if (scrollLeft + clientWidth === scrollWidth) {
        setIsNavLeftDisabled(false);
      }
      if (scrollLeft === 0) {
        setIsNavLeftDisabled(true);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsNavTopDisabled(true);
    }, 800);
  };

  const scrollToBottom = () => {
    const target = document.getElementById("footer-content") as any;
    if (target) {
      window.scrollTo(0, target.offsetTop - window.innerHeight);
    }
    setTimeout(() => {
      setIsNavTopDisabled(false);
    }, 800);
  };

  const scrollToLeft = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft += -800;
    }
    setIsNavLeftDisabled(true);
  };

  const scrollToRight = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft += 800;
    }
    setIsNavLeftDisabled(false);
  };

  const isIsolated = (index: number) =>
    questionId !== null && index !== questionId;

  const onExpandStudentClicked = (studentId: number) => {
    let newExpandedStudents = { ...expandedStudents };
    if (newExpandedStudents[studentId]) {
      delete newExpandedStudents[studentId];
    } else {
      newExpandedStudents[studentId] = true;
    }
    setExpandedStudents(newExpandedStudents);
  };

  const onClickStudentName = (student: any) => {
    setIsolatedStudent((prev: any) =>
      !prev ? student : prev.id === student.id ? null! : student
    );
  };

  console.log(masterReport, "here");

  return (
    <div>
      {!print && (
        <div className="report hide-on-print report-print-block">
          <ReportHeader
            reportTitle="Item Response Report"
            reportGuide="Click on an objective to isolate."
            setPrint={handlePrint}
            testName={assessmentName}
            handleReviewTest={openAssessment}
            headerClassName="based-header report__transparent_header"
          />

          <div className="report-content">
            <div className="report__search">
              <div className="report__search-searcharea">
                {!isLoading && (
                  <img
                    src={SearchIconGray}
                    className="report__search-icon animated-fade"
                    alt=""
                  />
                )}
                <input
                  className={`form-control report__search-input ${
                    isLoading ? "margin-left" : ""
                  }`}
                  type="search"
                  name="Search"
                  placeholder="Search Students"
                  value={search}
                  onChange={searchChangehandler}
                />
              </div>

              {!isLoading ? (
                <ReportTabs
                  questions={questions}
                  tab={tabIndex}
                  onTabChange={onTabChange}
                  threshold={25}
                />
              ) : (
                <TabsSkeleton customClass={"sm"} width={62} />
              )}

              <div className="report__navigation">
                <p className="mb-0">Navigation</p>
                <div className="report__navigation-buttons">
                  <div className="report__navigation-right">
                    <button
                      type="button"
                      disabled={isNavLeftDisabled === true ? true : false}
                      onClick={scrollToLeft}
                      className="report__navigation-btn next"
                    >
                      <img src={NavPrev} alt="icon" />
                    </button>
                    <button
                      type="button"
                      disabled={isNavLeftDisabled === false ? true : false}
                      onClick={scrollToRight}
                      className="report__navigation-btn next ms-2"
                    >
                      <img src={NavNext} alt="icon" />
                    </button>
                  </div>
                  {Students.length > 10 && (
                    <div className="report__navigation-bottom">
                      <button
                        type="button"
                        onClick={scrollToTop}
                        disabled={isNavTopDisabled === true ? true : false}
                        className="report__navigation-btn mb-2"
                      >
                        <img src={Navtop} alt="icon" />
                      </button>
                      <button
                        type="button"
                        onClick={scrollToBottom}
                        disabled={isNavTopDisabled === false ? true : false}
                        className="report__navigation-btn"
                      >
                        <img src={NavBottom} alt="icon" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className="report-item-container"
              ref={contentRef}
              onScroll={onScroll}
            >
              <div className="report__numbers">
                <div className="report__numbers-name irr-name">
                  <p>Student Name</p>
                </div>

                {!isLoading ? (
                  <div
                    className="report__numbers-container"
                    style={{ marginLeft: "5.5px" }}
                  >
                    {slicedQustions.map((q: any, i: number) => (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => OnClickQuestionNo(i)}
                        className={`${
                          questionId === i ? "question-bold" : ""
                        } report__numbers-count`}
                      >
                        {tabIndex * questionsPerPage + i + 1}
                      </span>
                    ))}
                  </div>
                ) : (
                  <div style={{ marginLeft: "6px" }}>
                    <HeaderSkeleton rowColumns={25} height={28} />
                  </div>
                )}

                <div className="itemResponseReport-Attempt">
                  <TableSelectCommon
                    customClassName={`${
                      classrooms.length < 2 && classrooms.length > 1
                        ? "top-start"
                        : ""
                    } ${classrooms.length === 1 ? "middle-start" : ""}`}
                    isOpen={showDropDown}
                    toggleSelect={() => {
                      setSHowDropDown(!showDropDown);
                    }}
                    options={
                      dropDownLabel === "Attempt"
                        ? [{ label: "Date", value: "Date" }]
                        : [{ label: "Attempt", value: "Attempt" }]
                    }
                    onChange={(value: string) => {
                      setDropDownLabel(value);
                    }}
                    name={dropDownLabel}
                  />
                </div>

                <div className="report__numbers-score irr-score">
                  <span>
                    (# Correct) <br /> Score
                  </span>
                </div>
              </div>
              <div className="report-item-content">
                <table
                  className={`report__itemTable report__table report__table-Irr-table-view ${
                    Students.length > 15 ? "report__table-Irr-height" : ""
                  }`}
                >
                  <tbody>
                    {!Students.length && !isLoading && (
                      <tr className="dark-gray">
                        <td className="report__table-notFound">
                          No Students Found
                        </td>
                      </tr>
                    )}

                    {!isLoading ? (
                      Students?.map((student: any, index: number) => {
                        return (
                          <Student
                            student={student}
                            index={index}
                            tab={tabIndex}
                            questionId={questionId}
                            questions={questions}
                            xThreshold={questionsPerPage}
                            dropDownLabel={dropDownLabel}
                            expandedStudents={expandedStudents}
                            onClickExpand={onExpandStudentClicked}
                            setIsolatedStudent={onClickStudentName}
                          />
                        );
                      })
                    ) : (
                      <div style={{ marginLeft: "5px" }}>
                        <BodySkeleton
                          rowColumns={25}
                          circle={false}
                          circleAttempt={false}
                          scoreBox={false}
                          scoreColumn={true}
                          attemptWidth={"110px"}
                          scoreWidth={"100px"}
                        />
                      </div>
                    )}
                  </tbody>
                </table>

                <Footer
                  slicedQustions={slicedQustions}
                  questionId={questionId}
                  isLoading={isLoading}
                  allStudents={Students}
                  isIsolated={isIsolated}
                  OnClickQuestionNo={OnClickQuestionNo}
                />
              </div>
            </div>
          </div>
          <FormativeAssessedModal
            isShow={showFormativeModal}
            closeModal={() => setShowFormativeModal(false)}
          />

          <BenchmarkModal
            isShow={showBenchmarkModal}
            closeModal={() => setShowBenchMarkModal(false)}
          />
        </div>
      )}

      {report?.data && (
        <div ref={componentRef} className="printData">
          <Print report={report} dropDownLabel={dropDownLabel} />
        </div>
      )}
    </div>
  );
};
export default ItemResponseReport;
