import { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "redux/actionTypes";
import { RootState } from "redux/reducers/combine";
import { getSurveyQuestionnaire, postUpdateSurveyQuestionnaire, updateSurveyQuestionnaire } from "services/common";
import Questionnaire from "./questionnaire";
import SurveySkeleton from "./skeleton";
import { useSurveyPagination } from "hooks/useSurveyPagination";
import Cookies from "js-cookie";
import CustomModal from "components/common/Modal";

interface IProps {
    closeModal: Function;
    onSubmit: Function;
}

// let paginationActiveIndex = 0;
const SatisfactionSurvey = ({closeModal, onSubmit}:IProps) => {
    const paginationActiveIndexRef = useRef<any>(0);
    const containerRef = useRef<any>(null);
    const firstUnasnweredQuestion = useRef<any>(0);
    const questionRef = useRef<any>(null);

    const dispatch = useDispatch();
    const auth = useSelector((state: RootState) => state.auth);

    const [questions, setQuestions] = useState([]);
    const [fetchQuestionLoading, setFetchQuestionLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState('');
    const [questionIndex, setQuestionIndex] = useState(0);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [answers, setAnswers] = useState<any>([]);
    const [heightFix, setHeightFix] = useState(false);

    // const [activeIndex, setActiveIndex] = useState()

    const perPage = useSurveyPagination();
    const middle = Math.floor(perPage/2);
    const totalQuestions = questions.length;
    const isLargePagination = totalQuestions > perPage;

    const isMiddleCrossed = questionIndex > middle;
    

    const slicedPagination = isLargePagination
    ? questions.slice(isMiddleCrossed ? paginationActiveIndexRef.current-middle : 0, isMiddleCrossed ? paginationActiveIndexRef.current+middle+1 : perPage)
    : questions;

    const onUpdate = async (status: "LATER" | "NO_THANKS") => {
        if(status === "LATER"){
            Cookies.set("showSurvey", "false");
        }else{
            localStorage.removeItem("survey_answers");
            localStorage.removeItem("current_question");
        }

        setUpdateLoading(status);
        const res = await updateSurveyQuestionnaire({survey_status: status});

        if(res?.code === 200){
            if(status === "LATER"){
                dispatch({
                    type: ActionType.USER_PROFILE_DATA,
                    payload: {...auth.colorProfile, show_no_thanks: true}
                });
            }
            closeModal();
        }
        setUpdateLoading('');
    }

    const onChangeAnswer = (currentAnswer: any) => {
        let isFound = false;
        const updatedAnswers = answers.reduce((acc: any, curr: any, index: number) => {
            acc[index] = curr;
            if(curr.question_id === currentAnswer.question_id){
                acc[index] = currentAnswer;
                isFound = true;
            }
            return acc;
        },[]);
        
        if(!isFound){
            updatedAnswers.push(currentAnswer);
        }
        setAnswers(updatedAnswers);
    }

    const onClickPage = (question: number) => {
        paginationActiveIndexRef.current = question+middle >= totalQuestions ? paginationActiveIndexRef.current : question;
        setQuestionIndex(question);
    }

    const onClickNext = async () => {
        const nextQuestion = questionIndex+1;
        if(nextQuestion === totalQuestions){
            setSubmitLoading(true);
            const res = await postUpdateSurveyQuestionnaire({answers});
            setSubmitLoading(false);

            if(res?.code === 200){
                dispatch({
                    type: ActionType.USER_PROFILE_DATA,
                    payload: {...auth.colorProfile, show_survey: false}
                });
                onSubmit();
            }
        }else{
            saveData();
            onClickPage(nextQuestion);
            if(nextQuestion > firstUnasnweredQuestion.current){
                firstUnasnweredQuestion.current = nextQuestion;
            }
            
        }
    }

    const onClickPrev = async () => {
        onClickPage(questionIndex-1)
    }

    const fetchSurveyQuestionaire = async () => {
        setFetchQuestionLoading(true);
        const res = await getSurveyQuestionnaire();
        setFetchQuestionLoading(false);

        if(res?.code === 200){
            setQuestions(res?.data?.questions);
        }
    }

    const saveData = () => {
        localStorage.setItem("survey_answers", JSON.stringify(answers));

        const currQuestion = questions.findIndex((item: any) => {
            const answr = answers.find((ans: any) => ans.question_id === item.id);
            return !answr || answr?.options?.length < item?.options?.length || ('answer' in answr && answr?.answer === "");
        });
        localStorage.setItem("current_question", `${currQuestion > -1  ? currQuestion : questionIndex}`);
        localStorage.setItem("type", `${(questions[currQuestion > -1  ? currQuestion : questionIndex] as any).type}`);
    }

    useEffect(() => {
        if(questions.length > 0){
            const height = questionRef.current ? window.getComputedStyle(questionRef.current)?.height?.replace("px", '') : '';
            if(height > '22'){
                setHeightFix(true);
            }else{
                setHeightFix(false);
            }
        }
    },[questions, questionIndex]);

    useEffect(() => {
        fetchSurveyQuestionaire();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        const surveyAnswers = localStorage.getItem("survey_answers");
        if(surveyAnswers){
            const parsedAnswers = JSON.parse(surveyAnswers);
            setAnswers(parsedAnswers);
        }

        const currentQuestion = localStorage.getItem("current_question");
        if(currentQuestion){
            const parsedQuestion = JSON.parse(currentQuestion);
            setQuestionIndex(parsedQuestion);
            firstUnasnweredQuestion.current = parsedQuestion;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const showNoThanks = auth.colorProfile.show_no_thanks;
    const question:any = questions.length > 0 ? questions[questionIndex] : null;
    const answer = answers.find((item: any) => item.question_id === question?.id);
    
    const isNextDisabled = question?.type === 'GRID' ? !answer || answer?.options?.length < question?.options?.length : !answer?.answer
    const questionStatement = question?.question ? `${question?.question}` : ''
    const firsUnAnswerdQuestionId = questions.length > 0 ? (questions[firstUnasnweredQuestion.current] as any)?.id : null;
    return (
        <div >
            <CustomModal
                centered
                show
                size="lg"
                contentClassName="satisfaction-survey-modal"
            >
                <div ref={containerRef}>
                    <div className="">
                        <h3 className="modal__header-title ps-2">Satisfaction Survey</h3>
                        <p className="modal__header-desc ps-2">
                            We want your feedback. Take the survey to help us improve Alpha Plus Online for 2024-25.
                        </p>
                    </div>

                    <div className="modal__body">
                        {fetchQuestionLoading ? (
                            <SurveySkeleton type={question?.type || localStorage.getItem("type")} />
                        ):(
                            question?.type === "GRID" ? (
                                <div className="ques-body-container">
                                    <p className={`survey-ques ${heightFix ? 'ques-scnd-line' : ''}`}>{questionStatement && <span>{questionIndex+1}.</span>}<p ref={questionRef}>{questionStatement}</p></p>
                                    <div className="quiz-container">
                                        {question && (
                                            <Questionnaire 
                                                question={question} 
                                                answer={answer} 
                                                onChangeAnswer={onChangeAnswer}
                                            />
                                        )}
                                    </div>
                                </div>
                            ):(
                                <div className="ques-ans-sec">
                                    <p className={`ques ${heightFix ? 'ques-scnd-line' : ''}`}><>{questionStatement && <span>{questionIndex+1}.</span>}<p ref={questionRef}>{questionStatement}</p></></p>
                                    <div className="ans-textarea">
                                        <textarea 
                                            onChange={(e: any) => {
                                                const ans = {
                                                    question_id: question?.id,
                                                    answer: e.target.value,
                                                    options: []
                                                }
                                                onChangeAnswer(ans)
                                            }}
                                            value={answer?.answer || ''}
                                            placeholder="Enter response here..."
                                        />

                                        
                                    </div>
                                </div>
                            )
                        )}
                    </div>

                    <div className="modal__footer">
                        
                        <div className="d-flex align-items-center gap-3 left-btn">
                            {showNoThanks && (
                                <button
                                    className={`btn survey-no-thanks-btn`}
                                    onClick={() => onUpdate("NO_THANKS")}
                                    disabled={!!updateLoading || submitLoading}
                                >
                                    {updateLoading === "NO_THANKS" ? (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        "No Thanks"
                                    )}
                                </button>
                            )}
                            
                            <button
                                className={`btn survey-later-btn`}
                                onClick={() => onUpdate("LATER")}
                                disabled={!!updateLoading || submitLoading || fetchQuestionLoading}
                            >
                                {updateLoading === "LATER" ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    "Later"
                                )}
                            </button>
                        
                        </div>

                        {fetchQuestionLoading ? (
                            <div className="pagination-skeleton">
                                <div className="skeleton"></div>
                                <div className="skeleton"></div>
                                <div className="skeleton"></div>
                                <div className="skeleton"></div>
                                <div className="skeleton"></div>
                                <div className="skeleton"></div>
                                <div className="skeleton"></div>
                            </div>
                        ):(
                            <div className="survey-pagination">
                                {slicedPagination.map((quest: any, index: number) => {
                                    const {id, options, type} = quest;
                                    const isDisabledStart = isLargePagination && isMiddleCrossed && index === 0;
                                    const isEndDisabled = isLargePagination && questionIndex+middle+1 < totalQuestions && index === slicedPagination.length-1;
                                    const notCurrentQuestion = question?.id !== id;
                                    const isCurrentQuestionAnswered = (question?.id === id && firsUnAnswerdQuestionId !== id);
                                    const qAnswer = answers?.find((item: any) => item.question_id === id && (notCurrentQuestion || isCurrentQuestionAnswered));
                                    const isAnswered = type === "GRID" ? qAnswer?.options?.length === options?.length : !!qAnswer?.answer;
                                    return (
                                        <span 
                                            className={`
                                                ${index === questionIndex ? 'active' : ''}
                                                ${isDisabledStart || isEndDisabled ? 'disabled' : ''}
                                                ${isAnswered ? 'answered' : ''}
                                            `}
                                        >
                                            {index+1}
                                        </span>
                                    )
                                })}
                            </div>
                        )}
                        

                        <div className="d-flex align-items-center gap-2 pagination-btn">
                            <button 
                                type="button" 
                                className={`btn cancel-btn ${questionIndex === 0 ? 'disabled' : ''}`}
                                onClick={onClickPrev}
                                disabled={questionIndex === 0 || !!updateLoading || submitLoading}
                            >
                                Previous
                            </button>

                            <button 
                                type="button"
                                className={`btn success-btn`}
                                onClick={onClickNext}
                                disabled={isNextDisabled || submitLoading || !!updateLoading}
                            >
                                {submitLoading? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <>
                                        {questionIndex+1 === totalQuestions ? 'Finish' : 'Next'}
                                    </>
                                )}
                                
                            </button>
                        </div>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
};

export default SatisfactionSurvey;
