/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck

import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { Collapse } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EditIcon from "assets/img/edit-table.svg";
import DeleteIcon from "assets/img/trash-icon.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import ExcelReportIcon from "assets/img/excel-report-icon.png";
import SearchIcon from "assets/img/search-icon.svg";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import Pagination from "../pagination/pagination";
import { AddStudentToClassModal } from "./management-add-modal";
import { CleverIdUpdateModal } from "./management-update-modal";
import { StudentDeleteModal } from "./management-delete-modal";
import { StudentUpdateModal } from "./management-update-modal";
import { useDispatch, useSelector } from "react-redux";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { LimitSelect } from "components/common/selectComponent";
import {
  addStudent,
  deleteStudent,
  updateStudent,
  getStudentsForClass,
  updateStudentToggle,
  addStudentToClass,
  setFlagForFetch,
  addStudentLicense,
  getClassAllStudents,
  checkTestInProgress,
} from "../../redux/actionCreators/management";
import { UnroleModel } from "components/classroom/modals/unroleModel";
import { unenrollStudentFormClassroom } from "redux/actionCreators/classroom";
import ArrowDownImg from "assets/img/table-class-before.svg";
import PermissionHintModal from "./modals/permissionHintModal";
import AddLicenseConfirmModal from "./modals/addLicenseConfirmModal";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import DownloadPdf from "./download/studentsPdf";
import { AssessmentTransferModal } from "components/classroom/modals/assessmentTransferModal";
import AllStudentsDetailPrint from "./allStudentsDetailPrint";
import { useQuery } from "hooks/useQuery";
import { useReactToPrint } from "react-to-print";
import { CopyAssessmentTransferModal } from "components/classroom/modals/copyAssessmentTransferModal";
import {
  setClassroomSchoolId,
  setClassroomId,
  setGrade as setClassroomGrade,
  setSubject as setClassroomSubject,
  setDistrictId,
  fetchSchoolClassrooms,
} from "redux/actionCreators/classroom";
import useSortBy from "hooks/useSortBy";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import Toggle from "components/common/Toggle";

interface RootState {
  management: any;
  auth: any;
}

const StudentsForClass: React.FC<{
  page: string;
  districtId?: string;
  schoolId?: string;
  classId?: string;
  isLoading: boolean;
  schoolName: string;
  district_id?: number;
}> = ({
  page,
  districtId,
  schoolId,
  classId,
  isLoading,
  schoolName,
  district_id,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classroom, management, auth } = useSelector(
    (state: RootState) => state
  );
  const [students, setStudents] = useState<any[]>([]);
  const [open, setOpen] = useState(true);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [assessmentTransferModal, setAssessmentTransferModal] = useState(null);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [modalBorder, setModalBorder] = useState(true);
  // const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [skeletonCount, setSkeletonCount] = useState(10);
  const [unenrollModal, setUnenrollModal] = useState(false);
  const [deleted, setDeleted] = useState<any>(false);
  const [hasTested, setHasTested] = useState<any>(false);
  const [student, setStudent] = useState<any>(null);
  const [lastAddedId, setLastAddedId] = useState(0);

  const { sortBy, OrderByColumn, sortDataBy } = useSortBy("last_name", "ASC");

  const [openHintModal, setHintModal] = useState(false);
  const [addLicenseModal, setAddLicenseModal] = useState(false);
  const [licenseLoading, setLicenseLoading] = useState(false);
  const [loading, setLoading] = useState<any>(false);
  // const [isDesktopScreen, setIsDesktopScreen] = useState(
  //   window.screen.width > 600
  // );
  const query = useQuery();
  const print = query.get("print");
  //const componentRef = useRef(null);
  const [copyAndUnEnrollStudentModal, setCopyAndUnEnrollStudentModal] =
    useState(null);
  const [addStudentData, setAddStudentData] = useState(null!);

  const [showCleverModal, setShowCleverModal] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("resize", resizeHandler);
  //   //cleanup
  //   return () => window.removeEventListener("resize", resizeHandler);
  // }, []);

  // const resizeHandler = () => {
  //   setIsDesktopScreen(window.screen.width > 600);
  // };

  const handleOnChange = (checked: boolean, classroom_id: number) => {
    const classroomData = copyAndUnEnrollStudentModal?.classrooms?.map(
      (classData) => {
        if (classData.id === classroom_id) {
          return {
            ...classData,
            is_available: checked,
          };
        } else {
          return classData;
        }
      }
    );

    setCopyAndUnEnrollStudentModal({
      ...copyAndUnEnrollStudentModal,
      classrooms: classroomData,
    });
  };

  useEffect(() => {
    let params = {
      offSet,
      limit,
      searchTerm,
      districtId,
      schoolId,
      classId,
      sortBy,
      OrderByColumn,
    };
    schoolId && dispatch(getStudentsForClass(params));
  }, [
    offSet,
    limit,
    management.studentFlag,
    dispatch,
    deleted,
    sortBy,
    OrderByColumn,
    classId,
    schoolId,
  ]);

  useEffect(() => {
    setStudents(management.students);

    if (management?.addStudentConflict?.data?.data?.length > 0) {
      setAssessmentTransferModal(management.addStudentConflict.data.data);
    }

    // else if (management.addStudentConflict?.data?.message && management.addStudentConflict?.data?.type === 2) {
    //   setCopyAndUnEnrollStudentModal(management.addStudentConflict.data);
    // }
  }, [management.students, management.addStudentConflict]);

  const handleSearch = useCallback(
    _.debounce((params: any) => {
      setOpen(true);
      setCurrentPage(1);
      setOffSet(0);
      setModalBorder(!modalBorder);

      dispatch(getStudentsForClass(params));
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  const onSearch = (e) => {
    const params = {
      offSet: 0,
      limit,
      searchTerm: e.target.value,
      districtId,
      schoolId,
      classId,
      sortBy,
      OrderByColumn,
    };

    handleSearch(params);
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    /* Fetch page number for last added record*/
    if (management.lastAddedStudentId > 0) {
      setLastAddedId(management.lastAddedStudentId);
      setSearchTerm("");
      let params = {
        offSet: 0,
        limit: 10,
        searchTerm: "",
        districtId,
        schoolId,
        classId,
        sortBy,
        OrderByColumn,
        new_student_id: management.lastAddedStudentId,
      };
      dispatch(getStudentsForClass(params));
    }
    /* Set page and fetch record of that page */
    if (management.studentPageNumber > 0) {
      setLimit(10);
      setCurrentPage(management.studentPageNumber);
      setOffSet(management.studentPageNumber * 10 - 10);
      management.studentPageNumber === currentPage &&
        dispatch(setFlagForFetch("SET_STUDENT_FLAG"));
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedStudentId, management.studentPageNumber]);

  useEffect(() => {
    if (lastAddedId > 0 && !management.studentsLoading) {
      const element = document.getElementById("student-data");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [management.students, management.studentsLoading]);

  const doChangePage = (pageNo: number) => {
    console.log(pageNo, ".....................");
    console.log(
      management?.currentstudentsCount,
      "...........................student........"
    );
    let newOffset = pageNo * limit - limit;
    console.log(newOffset, "OFFSET..........................");
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };

  const doSetUpdateModal = (item: any) => {
    console.log(item, ".................");
    setCurrentItem(item);
    setUpdateModal(true);
  };

  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const doSetUpdateCleverModal = (item: any) => {
    console.log(item, ".................");
    setCurrentItem(item);
    setShowCleverModal(true);
  };

  const onDeletStudent = (type: string) => {
    let data = {
      student_id: currentItem?.id,
      delete_from: type,
    };
    dispatch(deleteStudent(data, setDeleteModal));
  };

  const onUpdateStudent = (data: any, type: number = 1) => {
    if (currentItem && type === 1) {
      dispatch(updateStudent(currentItem.id, data, setUpdateModal));
    } else {
      dispatch(updateStudent(currentItem.id, data, setShowCleverModal));
    }
  };

  const onAddStudent = (data: any, bySearch: boolean) => {
    console.log(data, "...............................", bySearch);
    if (bySearch) {
      dispatch(
        addStudentToClass(
          { ...data, check_old_data: true, check_data_for_copy: true },
          setAddModal
        )
      );
    } else {
      if (
        management.breadcrumbs?.student_licenses_in_use -
          management.breadcrumbs?.student_licenses ===
        0
      ) {
        setAddStudentData(data);
        auth.currentUser?.role === "Super Admin"
          ? setAddLicenseModal(true)
          : setHintModal(true);
      } else {
        dispatch(
          addStudent(
            {
              ...data,
              district_id: Number(management?.breadcrumbs?.district_id),
              school_id: Number(schoolId),
            },
            setAddModal
          )
        );
      }
    }
    !open && setOpen(true);
  };

  const enrollStudentBack = (data: any, bindOldData: any) => {
    if (bindOldData) {
      if (data[0] && data[1]) {
        setAssessmentTransferModal(false);
        setCopyAndUnEnrollStudentModal(data[1]);
      } else if (data[0] && data[0].type === 2) {
        setAssessmentTransferModal(false);
        setCopyAndUnEnrollStudentModal(data[0]);
      } else if (data[0] && data[0].type === 1) {
        dispatch(
          addStudentToClass(
            { ...data[0], bind_old_data: true },
            setAssessmentTransferModal
          )
        );
      }
    } else {
      console.log(4);
      dispatch(
        addStudentToClass(
          { ...data[0], bind_old_data: false },
          setAssessmentTransferModal
        )
      );
    }
  };

  const copyStudentData = (data: any, bindOldData: any) => {
    let classroomsToCopy = [];

    copyAndUnEnrollStudentModal?.classrooms?.forEach((copiedData) => {
      if (copiedData?.is_available) {
        classroomsToCopy.push(copiedData.id);
      }
    });

    if (bindOldData) {
      dispatch(
        addStudentToClass(
          {
            ...data,
            copy_data: true,
            classroom_to_copy_from: classroomsToCopy,
            un_enroll: true,
          },
          setCopyAndUnEnrollStudentModal
        )
      );
    } else {
      dispatch(
        addStudentToClass(
          {
            ...data,
            copy_data: false,
            classroom_to_copy_from: classroomsToCopy,
            un_enroll: true,
          },
          setCopyAndUnEnrollStudentModal
        )
      );
    }
  };

  const borderRadius = () => {
    const timer = setTimeout(() => {
      setModalBorder(true);
    }, 300);

    return () => {
      clearTimeout(timer);
      setModalBorder(false);
    };
  };

  useEffect(() => {
    if (page === "district" || page === "class") {
      setOpen(true);
      setModalBorder(!modalBorder);
    }
  }, [page]);

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setSkeletonLoad(false);
    // }, 800);

    if (limit - (currentPage * limit - management.studentsCount) < limit) {
      setSkeletonCount(
        limit - (currentPage * limit - management.studentsCount)
      );
    } else {
      setSkeletonCount(limit);
    }

    return () => {
      // clearTimeout(timer);
      // setSkeletonLoad(true);
    };
  }, [management.studentsLoading, currentPage]);

  const tableBodayHeight = skeletonCount * 37 + "px";

  const onChangeToggle = (
    id: number,
    name: string,
    value: boolean,
    data: any
  ) => {
    try {
      const updatedStudents =
        students.map((item: any) => {
          if (item.id === id) {
            item = { ...item, [name]: value };
          }
          return item;
        }) || [];
      updateStudentToggle(id, data);
      setStudents(updatedStudents);
    } catch (err) {
      console.log("Error", err);
      return;
    }
  };

  const unenrollStudent = () => {
    //!call the api here
    const unenroll = async () => {
      if (!classId) return;
      setLoading(true);
      await unenrollStudentFormClassroom(+classId, student.id);
      setStudent(null);
      setHasTested(false);
      setUnenrollModal(false);
      setLoading(false);
      setDeleted(!deleted);
    };
    unenroll();
  };

  const addLicense = async () => {
    setLicenseLoading(true);
    const data = {
      school_id: Number(schoolId),
      increase_by: 1,
    };
    const res = await addStudentLicense(data);
    if (res?.data?.data?.updated === true) {
      setLicenseLoading(false);
      setAddLicenseModal(false);
      dispatch(
        addStudent(
          {
            ...addStudentData,
            district_id: Number(management?.breadcrumbs?.district_id),
            school_id: Number(schoolId),
          },
          setAddModal
        )
      );
      setAddStudentData(null!);
    } else {
      setAddStudentData(null!);
      setLicenseLoading(false);
      setAddLicenseModal(false);
    }
  };

  const handleDownload = async () => {
    const response = await getClassAllStudents(schoolId, classId);
    if (response) {
      genPdf(response.data.data.students);
    }
  };

  const genPdf = (studentsData) => {
    const htmlString = ReactDOMServer.renderToStaticMarkup(
      <DownloadPdf
        students={studentsData}
        class_name={management.breadcrumbs?.classroom_name}
        district_name={`${management.breadcrumbs?.district_name} : ${management.breadcrumbs?.school_name}`}
      />
    );

    const opt = {
      margin: [8, 10, 5, 10],
      filename: `${management.breadcrumbs?.classroom_name}_Students_${moment(
        new Date()
      ).format("M/DD/YY hh:mmA")}.pdf`,
      html2canvas: { scale: 2, letterRendering: true },
      jsPDF: { orientation: "p", unit: "mm" },
    };

    html2pdf()
      .from(htmlString)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(6);
          pdf.text(
            `© ${new Date().getFullYear()} Alpha Plus Systems, Inc.`,
            pdf.internal.pageSize.getWidth() - 120,
            pdf.internal.pageSize.getHeight() - 8
          );
        }
      })
      .save();
  };

  const handlEnroll = async (item) => {
    const res = await checkTestInProgress(item.id, Number(classId));
    setStudent(item);
    setHasTested(!!res);
    setUnenrollModal(true);
  };

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   documentTitle: `Students_Performance_Report_${moment(new Date()).format(
  //     "M/DD/YY hh:mmA"
  //   )}`,
  //   removeAfterPrint: true,
  //   onAfterPrint: () => {
  //     print && window.close();
  //   },
  // });

  const onClickText = () => {
    console.log("onClickText");
    if (classroom?.districtId !== district_id) {
      dispatch(setDistrictId(district_id));
    }

    if (classroom?.classroomSchoolId !== schoolId) {
      dispatch(setClassroomSchoolId(schoolId));
    }

    if (classroom?.classroomId !== classId) {
      dispatch(setClassroomId(+classId));
    }

    navigate("/classroom/report-cards");
  };

  return (
    <>
      <div
        onClick={(e) => {
          if (
            (e.target as Element).nodeName === "INPUT" ||
            (e.target as Element).nodeName === "BUTTON" ||
            (e.target as Element).nodeName === "A"
          ) {
            return;
          }
          // setOpen(!open);
          borderRadius();
        }}
        className={`accordion__header students-header ${open ? "open" : ""} ${
          modalBorder ? "closeDelay " : ""
        }`}
      >
        <div className="accordion__header-heading">
          {/* <span className="accordion__header-button">
            <img src={ArrowIcon} alt="arrow" className={open ? "rotate" : ""} />
          </span> */}
          <h6>Students</h6>
          {!open ? (
            <div className="accordion__header-search animated ms-3">
              <img src={SearchIcon} alt="Search" />
              <input
                value={searchTerm}
                onChange={onSearch}
                type="text"
                className="form-control"
                placeholder="Search Students"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {open ? (
          <div
            style={{ pointerEvents: "auto" }}
            className={`accordion__header-text d-none d-lg-block ${
              page !== "district" && page !== "school"
                ? "text-with-searchbar"
                : ""
            }`}
          >
            {page === "district" ? (
              <p>
                Student progress can be viewed in <b>Report Cards.</b> <br />
                Click the pencil to edit. Click Yes/No to edit Clever ID.
              </p>
            ) : page === "school" && open ? (
              <p>
                Student progress can be viewed in <b>Report Cards.</b> <br />
                Click the pencil to edit. Click Yes/No to edit Clever ID.
              </p>
            ) : (
              <p onClick={onClickText}>
                Student progress can be viewed in <b>Report Cards.</b> <br />
                Click the pencil to edit. Click Yes/No to edit Clever ID.
              </p>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="students-header-btns">
          {page === "district" ? (
            <div className="accordion__header-buttons"></div>
          ) : page === "school" ? (
            <div className="accordion__header-buttons">
              <div className="accordion__header-btnContainer">
                <button
                  onClick={() => {
                    setAddModal(true);
                  }}
                  className="btn outline-btn outline-btn-lg"
                >
                  Add Student
                </button>
              </div>
            </div>
          ) : (
            <div className="accordion__header-buttons">
              {/* <div className="accordion__header-btnContainer">
                <button
                  className={`btn outline-btn outline-btn-lg ${!students?.length && "disabled"
                    }`}
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  {`Print ${isDesktopScreen ? "Performance" : ""} Reports`}
                </button>
              </div> */}
              <div className="accordion__header-btnContainer">
                <button
                  className="btn outline-btn outline-btn-lg"
                  onClick={handleDownload}
                >
                  Download PDF
                </button>
              </div>
              <div className="accordion__header-btnContainer">
                <button
                  className="btn outline-btn outline-btn-lg"
                  onClick={() => {
                    setAddModal(true);
                  }}
                >
                  Add Student
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Collapse in={open}>
        <div>
          <div className="accordion__content">
            <div className="section-search">
              <div className="section-search-field animated">
                <div className="icon">
                  <img src={SearchIconGray} alt="search" />
                </div>
                <input
                  ref={inputRef}
                  value={searchTerm}
                  onChange={onSearch}
                  type="text"
                  className="form-control"
                  placeholder="Search Students"
                />
              </div>
              <div className="d-flex align-items-center show-classes-dropdown">
                <span>Show</span>
                <LimitSelect
                  disableHandler={students && students?.length < 10}
                  onChangeHandler={(e: any) => setLimit(Number(e?.value))}
                />
                <span>Students</span>
              </div>
            </div>
            <div className="students-forclass-table-container">
              <div className="table-responsive">
                <table className="students__table students__table-management mt-0">
                  <thead>
                    <tr className="students__table-row">
                      <th colSpan={9} className="students__table-tools">
                        Assessment Tools
                      </th>
                    </tr>
                    <tr className="students__table-row">
                      <th
                        tabIndex={0}
                        className="students__table-name vertical-bottom"
                        onClick={() => sortDataBy("last_name")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("last_name");
                          }
                        }}
                      >
                        Student Name
                        {sortBy === "last_name" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-id vertical-bottom"
                        onClick={() => sortDataBy("school_student_id")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("school_student_id");
                          }
                        }}
                      >
                        Student ID
                        {sortBy === "school_student_id" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-last vertical-bottom"
                        onClick={() => sortDataBy("last_login")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("last_login");
                          }
                        }}
                      >
                        Last Login
                        {sortBy === "last_login" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-clever vertical-bottom"
                        onClick={() => sortDataBy("clever_id")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("clever_id");
                          }
                        }}
                      >
                        Has Clever ID
                        {sortBy === "clever_id" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-calculator"
                        onClick={() => sortDataBy("calculator")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("calculator");
                          }
                        }}
                      >
                        Calculator
                        {sortBy === "calculator" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="students__table-speech"
                        onClick={() => sortDataBy("tts")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            sortDataBy("tts");
                          }
                        }}
                      >
                        Text to <br /> Speech
                        {sortBy === "tts" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th className="students__table-unenroll">
                        Unenroll <br /> From Class
                      </th>
                      <th className="students__table-notes">Notes</th>
                      <th className="students__table-singleOption">Edit</th>
                    </tr>
                  </thead>
                  <tbody style={{ height: tableBodayHeight }}>
                    {/* {skeletonLoad ? (
                      <TableSkeleton
                        columns={9}
                        count={skeletonCount}
                        height={36}
                      />
                    ) : (
                      ""
                    )} */}

                    {management.studentsLoading || isLoading ? (
                      <TableSkeleton
                        columns={9}
                        count={skeletonCount}
                        height={36}
                      />
                    ) : students && students?.length ? (
                      students.map((item: any, i: number) => {
                        return (
                          <tr
                            key={i}
                            className={
                              lastAddedId === item.id
                                ? "students__table-row highlight-row"
                                : i % 2 !== 0
                                ? "students__table-row"
                                : "students__table-row"
                            }
                            id={lastAddedId === item.id ? "student-data" : ""}
                          >
                            <td className="name-data">
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  if (
                                    auth.currentUser?.role !== "Classroom Admin"
                                  )
                                    navigate(`/management/student/${item.id}`);
                                }}
                              >
                                {item?.last_name}, {item?.first_name}
                              </span>
                            </td>
                            <td className="id-data">
                              {item?.school_student_id}
                            </td>
                            <td className="text-center">
                              {item?.last_login
                                ? moment(item?.last_login).format("M-D-YY")
                                : "-"}
                            </td>
                            <td
                              className="text-center"
                              style={{
                                fontWeight: item?.clever_id ? "600" : "unset",
                              }}
                            >
                              {item?.clever_id ? (
                                <button
                                  className="students__clever_text_button"
                                  onClick={() => doSetUpdateCleverModal(item)}
                                >
                                  <b>Yes</b>
                                </button>
                              ) : (
                                <button
                                  className="students__clever_text_button"
                                  onClick={() => doSetUpdateCleverModal(item)}
                                >
                                  <b>No</b>
                                </button>
                              )}
                            </td>
                            <td className="text-center">

                              <Toggle 
                                inputName="Calculator"
                                ariaLabel="Calculator"
                                isSmall={true}
                                tabIndex={0}
                                labelClassName="mb-0" 
                                checked={item?.allow_calculator}
                                onChange={(e) =>
                                  onChangeToggle(
                                    item.id,
                                    "allow_calculator",
                                    !item?.allow_calculator,
                                    {
                                      id: item.id,
                                      allow_calculator:
                                        !item?.allow_calculator,
                                      classroom_id: Number(classId),
                                    }
                                  )
                                }

                              />
                              
                            </td>
                            <td className="text-center">

                                <Toggle 
                                  inputName="Text to Speech"
                                  ariaLabel="Text to Speech"
                                  labelClassName="mb-0"
                                  isSmall = {true}
                                  tabIndex={0}
                                  checked={item?.text_to_speech_enabled}
                                  onChange={(e) =>
                                    onChangeToggle(
                                      item.id,
                                      "text_to_speech_enabled",
                                      !item?.text_to_speech_enabled,
                                      {
                                        id: item.id,
                                        text_to_speech_enabled:
                                          !item?.text_to_speech_enabled,
                                        classroom_id: Number(classId),
                                      }
                                    )
                                  }
                                />
                            </td>
                            <td className="text-center">
                              <button
                                className="btn unenroll-button-class"
                                onClick={() => {
                                  handlEnroll(item);
                                }}
                              >
                                Unenroll
                              </button>
                            </td>

                            <td>{item?.notes}</td>
                            <td className="px-0">
                              <div
                                tabIndex={0}
                                role="button"
                                onKeyDown={(e: any) => {
                                  if (e.key === "Enter") {
                                    doSetUpdateModal(item);
                                  }
                                }}
                                className="d-flex align-items-center"
                                onClick={() => doSetUpdateModal(item)}
                              >
                                <img
                                  src={EditIcon}
                                  alt="Edit"
                                  className="management__table-editBtn"
                                />
                                {/* <img
                                    onClick={() => doSetDelModal(item)}
                                    src={DeleteIcon}
                                    alt=""
                                    className="management__table-editBtn"
                                  /> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {!students?.length && !management.studentsLoading && (
                          <td colSpan={9} className="no-admin">
                            No Students
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination
            totalCount={management.studentsCount}
            currentPage={currentPage}
            pageSize={limit}
            onPageChange={doChangePage}
          />
        </div>
      </Collapse>
      <StudentDeleteModal
        loading={management.studentDeleteLoading}
        isShow={deleteModal}
        headerText={`Delete ${currentItem?.first_name} ${currentItem?.last_name} `}
        bodyText={`Would you like to delete this student entirely from the district?`}
        onCancel={() => setDeleteModal(false)}
        onDelete={(type) => onDeletStudent(type)}
      />
      {updateModal && (
        <StudentUpdateModal
          isShow={updateModal}
          first_name={currentItem?.first_name}
          last_name={currentItem && currentItem.last_name}
          std_id={currentItem && currentItem.school_student_id}
          notes={currentItem && currentItem.notes}
          classroom_id={Number(classId)}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateStudent(data)}
          loading={management.studentUpdateLoading}
          allow_calculator={currentItem?.allow_calculator}
          text_to_speech={currentItem?.text_to_speech_enabled}
          showSwitch={true}
          serverError={management.studentUpdateError}
        />
      )}
      {showCleverModal && (
        <CleverIdUpdateModal
          isShow={showCleverModal}
          std_id={currentItem?.id?.toString()}
          clever_id={currentItem?.clever_id}
          onCancel={() => {
            setShowCleverModal(false);
          }}
          onUpdate={(data) => {
            onUpdateStudent(data, 2);
          }}
          loading={false}
        />
      )}
      {addModal && (
        <AddStudentToClassModal
          isShow={addModal}
          school_id={Number(schoolId)}
          classroom_id={Number(classId)}
          loading={management.studentAddLoading}
          onCancel={() => setAddModal(false)}
          onAdd={(data, flag) => onAddStudent(data, flag)}
        />
      )}
      {unenrollModal && (
        <UnroleModel
          isShow={unenrollModal}
          onClose={() => {
            setStudent(null);
            setHasTested(false);
            setUnenrollModal(false);
          }}
          onSave={unenrollStudent}
          currentData={classId}
          hasTested={hasTested}
          studentName={student?.first_name + " " + student?.last_name}
          loading={loading}
        />
      )}
      {assessmentTransferModal && (
        <AssessmentTransferModal
          isShow={assessmentTransferModal}
          onClose={() => {
            setStudent(null);
            setHasTested(false);
            setAssessmentTransferModal(null);
          }}
          onSave={enrollStudentBack}
          currentData={assessmentTransferModal}
          loading={loading}
        />
      )}
      {copyAndUnEnrollStudentModal !== null && (
        <CopyAssessmentTransferModal
          isShow={copyAndUnEnrollStudentModal}
          onClose={() => {
            setStudent(null);
            setHasTested(false);
            setCopyAndUnEnrollStudentModal(null);
            management.addStudentConflict = null;
          }}
          handleOnChange={handleOnChange}
          onSave={copyStudentData}
          currentData={copyAndUnEnrollStudentModal}
          loading={loading}
        />
      )}
      {openHintModal && (
        <PermissionHintModal
          isShow={openHintModal}
          onClose={() => setHintModal(false)}
          onYes={() => setHintModal(false)}
          message={`There are currently no student licenses available for ${management?.currentSchool?.school_name} . Please contact Alpha Plus at (405) 842-8408 to add additional licenses.`}
          customHeader="No Student Licenses Available"
        />
      )}
      {addLicenseModal && (
        <AddLicenseConfirmModal
          isShow={addLicenseModal}
          onClose={() => setAddLicenseModal(false)}
          onYes={addLicense}
          message={`There are currently no student licenses available for this school ${management?.currentSchool?.school_name}. Adding an additional student will automatically increment the license count.`}
          header="No Licenses Available"
          loading={licenseLoading}
        />
      )}
      {/* {classId && (
        <div className="printData" ref={componentRef}>
          <AllStudentsDetailPrint schoolName={schoolName} classId={classId} />
        </div>
      )} */}
    </>
  );
};

export default StudentsForClass;
