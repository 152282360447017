import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "redux/reducers/combine";

export const useShowSurvey = () => {
    const token: any = Cookies.get("super-admin-token");
    const auth = useSelector((state: RootState) => state.auth);

    const getShowSurvey = (isTooltipSeen: boolean) => {
        if(!token) return;

        const decodedToken: any = jwtDecode(token);
        const forgot = decodedToken?.forgot_password;

        const isSatisfactionSurvey = auth?.colorProfile?.show_survey && auth?.colorProfile?.no_thanks_clicked === false;

        const isShowModalSurvey = isSatisfactionSurvey && isTooltipSeen && !forgot;
        return isShowModalSurvey;
    }

    return {
        getShowSurvey
    }
}

export const useTooltipSeen = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const location = useLocation();

    const isTooltipSeen = () => {
        let isSeen = false;
        switch (location.pathname) {
          case "/activity":
            isSeen = auth?.colorProfile?.activity_tooltip_seen;
            break;
          case "/classroom":
            isSeen = auth?.colorProfile?.tooltips_seen;
            break;
          default:
            isSeen = true;
        }
    
        return isSeen;
    };

    return {
        isTooltipSeen
    }
}