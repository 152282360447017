/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button } from "../../common/buttonComponent";
import BlackSmithImg from "assets/img/black-smith.png";
import { TextAreaInput } from "../../common/inputComponent";
import { handlePress } from "./../../../util/index";
import CustomModal from "components/common/Modal";

interface QuestionsAnswersModalProps {
  isShow: boolean;
  customClass: string;
  onSave: (edit_log: string, id: number, questions: any[]) => void;
  onClose: () => void;
  isEdit: boolean;
  selectedStudent: any;
  recordId: number;
  onChangeAnswer: (
    recordId: number,
    question_id: number,
    answer: string,
  ) => void;
  students: any[];
}

const QuestionsAnswersModal: React.FC<QuestionsAnswersModalProps> = ({
  isShow,
  onSave,
  onClose,
  isEdit,
  selectedStudent,
  recordId,
  customClass,
  onChangeAnswer,
  students
}) => {
  const [reason, setReason] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [modalLoading, setModalLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    setQuestions(selectedStudent?.scores[recordId]?.student_answers)
  }, [selectedStudent]);

  useEffect(() => {
    checkDisabled();
  }, [reason,questions]);

  const checkDisabled = () => {
    const oldData = students.find((item) => item.fk_student_id === selectedStudent.fk_student_id)
    const atlistOneAnswer = questions.find((item:any,index:number) => item[`question_${index+1}_response`] === '0' || item[`question_${index+1}_response`] === '1' || item[`question_${index+1}_response`] === '2' || item[`question_${index+1}_response`] === '3')
    if ((!isEdit || reason) && !_.isEqual(oldData?.scores,selectedStudent?.scores) && atlistOneAnswer) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    const errors = handleValidation();
    const isError = isEdit ? errors.reason: '';
    if (!isError) {
      setModalLoading(true)
      onSave(
        reason,
        selectedStudent?.scores[recordId]["id"],
        selectedStudent?.scores[recordId]?.student_answers
      );
      setTimeout(() => {
        setModalLoading(false);
        handleCancel();
      }, 1000);
    } else {
      setClientErrors(errors);
      setIsSubmitError(true);
    }
  };

  const handleValidation = () => {
    const errors: any = {};
    if (reason.trim().length === 0) {
      errors["reason"] = "required";
    }
    return errors;
  };

  const handleOnChange = (name: string, value: string) => {
    name === "reason" && setReason(value);
    if (isSubmitError) {
      const isValid = value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    setReason("");
    setDisabled(true);
    setClientErrors({});
    setIsSubmitError(false);
    setModalLoading(false)
    setQuestions([])
  };

  const handleCancel = () => {
    clearData();
    onClose();
  };

  let keys = {
    a: false,
    s: false,
  };

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 91 || event.keyCode === 93 || event.keyCode === 17 || event.keyCode === 224) {
      keys.a = true;
    }

    if (event.key === "Enter") {
      keys.s = true;
    }

    if(keys.a === true && keys.s === true){
      document.getElementById('save-button')?.click();
    }

  }

  const handleKeyUp = () => {
    keys.a = false;
    keys.s = false;
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
        document.removeEventListener('keydown', handleKeyPress);
        document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);

  return (
    <CustomModal
      centered
      show={isShow}
      className={`modal-black-progress ${customClass}`}
      contentClassName="modal-content-blackProgress"
      onEscapeKeyDown={handleCancel}
    >
      <div className="modal__header">
        <img className="black__progress-icon" src={BlackSmithImg} alt="" />
        <h6 className="modal__header-title">
          {isEdit ? "Edit Answers: " : "Add Answers: "}
          {selectedStudent.students.first_name +
            " " +
            selectedStudent.students.last_name}
        </h6>
      </div>
      <div className="modal__body">
        <div className={`edit-answers ${customClass}`}>
          {questions?.map(
            (std: any, i: number) => {
              return (
                <div className="edit-answers__item">
                  <span className="edit-answers__item-count">{i + 1}</span>
                  <div
                    className={`edit-answers__item-answer ${
                      std[`question_${i + 1}_response`] === "0" ? "active" : ""
                    }`}
                    onClick={() => onChangeAnswer(recordId, i, "0")}
                  >
                    A
                  </div>
                  <div
                    className={`edit-answers__item-answer ${
                      std[`question_${i + 1}_response`] === "1" ? "active" : ""
                    }`}
                    onClick={() => onChangeAnswer(recordId, i, "1")}
                  >
                    B
                  </div>
                  <div
                    className={`edit-answers__item-answer ${
                      std[`question_${i + 1}_response`] === "2" ? "active" : ""
                    }`}
                    onClick={() => onChangeAnswer(recordId, i, "2")}
                  >
                    C
                  </div>
                  <div
                    className={`edit-answers__item-answer ${
                      std[`question_${i + 1}_response`] === "3" ? "active" : ""
                    }`}
                    onClick={() => onChangeAnswer(recordId, i, "3")}
                  >
                    D
                  </div>
                </div>
              );
            }
          )}
        </div>
        <TextAreaInput
          fieldName="reason"
          isSubmitError={isSubmitError}
          errorPresentBefore={clientErrors["reason"] !== undefined}
          value={reason}
          autoFocus={true}
          label={isEdit ? "Reason for Edit": "Reason for Add"}
          onChange={handleOnChange}
          error={isEdit ? clientErrors.reason: ''}
          customClass={`edit-answers__textarea ${customClass}`}
        />
      </div>
      <div className="modal__footer">
        <Button
          buttonText="Cancel"
          onClick={handleCancel}
          className="btn cancel-btn"
          disabled={modalLoading}
          type="button"
        />
        <Button
          buttonText="Save"
          onClick={onSubmit}
          className={`btn success-btn btn-lg ${isDisabled  && 'btn-disabled'}`}
          // disabled={isDisabled}
          id="save-button"
          loading={modalLoading}
          type="button"
        />
      </div>
    </CustomModal>
  );
};

export default QuestionsAnswersModal;
