/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  encryptPassword,
  periodList,
  validateClassFields,
  validateCommonFields,
  validateEmail,
  validateSchoolFields,
  validateStudentFields,
  handlePress,
  handleKeyUp,
  handlePressClass,
  checkIfNumber,
} from "util/index";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
import Tabs from "components/common/Tabs/Tabs";
import { getDefaultSummatives } from "services/get-default-summatives";
import { getSubjectList } from "services/get-subject-list";
import { getGradeList } from "services/get-grades-list";
import {
  fetchSchoolStudents,
  getAllClassAdmins,
  getClassAdmins,
  getDistrictAdmins,
  getSchoolAdmins,
} from "services/get-admins";
import RPIcon from "assets/img/cross-icon.svg";
import {
  CustomInput,
  Input,
  SelectInput,
  ValidationHint,
} from "../common/inputComponent";
import { Button } from "../common/buttonComponent";
import ActionType from "redux/actionTypes";
import { v4 as uuidv4 } from "uuid";
import {
  AdminsSelect,
  AdminsSelectClass,
} from "components/common/selectComponent";
import Cookies from "js-cookie";
import { getSuperAdmins } from "services/supportTicketSuperAdmin";
import Select from "react-select";
import CustomModal from "components/common/Modal";
import Toggle from "components/common/Toggle";

interface SuperAdminAddProps {
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
}

export const SuperAdminAddModal: React.FC<SuperAdminAddProps> = ({
  isShow,
  loading,
  onCancel,
  onAdd,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const dispatch = useDispatch();
  const { management } = useSelector((state: any) => state);

  useEffect(() => {
    if (management.superAdminsAddError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        email: management.superAdminsAddError,
      });
    }
  }, [management.superAdminsAddError]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, email, password]);

  const checkDisabled = () => {
    if (firstName && lastName && email && password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    dispatch({
      type: ActionType.ADD_SUPER_ADMIN_ERROR,
      payload: null,
    });
    const errors = validateCommonFields(
      firstName,
      lastName,
      email,
      password,
      true
    );
    const isError =
      errors.firstName || errors.lastName || errors.email || errors.password;
    if (!isError) {
      let data = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        password: encryptPassword(password),
        title,
      };
      onAdd(data);
    } else {
      setClientErrors(errors);
      setIsSubmitError(true);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    name === "password" && setPassword(value);
    name === "title" && setTitle(value);
    if (isSubmitError) {
      const isValid =
        name === "email"
          ? validateEmail(value.trim())
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.ADD_SUPER_ADMIN_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Add Super Admin</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Input
              fieldName="firstName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["firstName"] !== undefined}
              value={firstName}
              type="text"
              label="First Name"
              onChange={handleOnChange}
              error={clientErrors.firstName}
              autoFocus={true}
            />
            <Input
              fieldName="lastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["lastName"] !== undefined}
              value={lastName}
              type="text"
              label="Last Name"
              onChange={handleOnChange}
              error={clientErrors.lastName}
            />
            <CustomInput
              fieldName="email"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["email"] !== undefined}
              value={email}
              type="text"
              label="Email"
              onChange={handleOnChange}
              error={clientErrors.email}
              errorMessage={clientErrors.email}
              overlayClick={() => {
                setClientErrors({
                  ...clientErrors,
                  email:
                    clientErrors.email === "Invalid Email"
                      ? "required"
                      : undefined,
                });
              }}
            />
            <Input
              fieldName="title"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["title"] !== undefined}
              value={title}
              type="text"
              label="Title"
              onChange={handleOnChange}
            />
            <Input
              fieldName="password"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["password"] !== undefined}
              value={password}
              type="password"
              label="Password"
              onChange={handleOnChange}
              error={clientErrors.password}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface DistrictAdminAddProps {
  district_id: number;
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
}

export const DistrictAdminAddModal: React.FC<DistrictAdminAddProps> = ({
  district_id,
  isShow,
  loading,
  onCancel,
  onAdd,
}) => {
  const [adminsList, setAdminsList] = useState<null | any>(null);
  const [currentTab, setCurrentTab] = useState("Search");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [id, setId] = useState(null);
  const [email, setEmail] = useState("");
  const [sendEmail, setSendEmail] = useState(true);
  const [isDisabled, setDisabled] = useState(true);
  const [isDropdownShow, setDropdownShow] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const dispatch = useDispatch();
  const { management } = useSelector((state: any) => state);

  useEffect(() => {
    const init = async () => {
      try {
        let response = await getDistrictAdmins("", district_id);
        setAdminsList(response?.data?.admins ?? []);
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (management.districtAdminsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        email: management.districtAdminsError,
      });
    }
  }, [management.districtAdminsError]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, email]);

  const checkDisabled = () => {
    if (firstName && lastName && email) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearError();
    const errors = validateCommonFields(firstName, lastName, email);
    const isError = errors.firstName || errors.lastName || errors.email;
    if (!isError) {
      let data = {
        admin_id: id,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        title: title?.trim(),
        send_email: sendEmail,
      };
      onAdd(data);
    } else {
      setClientErrors(errors);
      setIsSubmitError(true);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    name === "title" && setTitle(value);
    if (isSubmitError) {
      const isValid =
        name === "email"
          ? validateEmail(value.trim())
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearError = () => {
    dispatch({
      type: ActionType.ADD_DISTRICT_ADMIN_ERROR,
      payload: null,
    });
  };

  const clearData = () => {
    setFirstName("");
    setLastName("");
    setTitle("");
    setEmail("");
    setClientErrors({});
    setId(null);
    setIsSubmitError(false);
    clearError();
  };

  const setCurrentAdmin = (data: any) => {
    const { item } = data;
    setId(item?.id);
    setFirstName(item?.first_name);
    setLastName(item?.last_name);
    setEmail(item?.email);
    setDropdownShow(false);
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Add District Admin</h6>
        </div>
        <div className="modal__tabs add-district-admin-tabs">
          <Tabs
            options={["Search", "Create New"]}
            currentTab={currentTab}
            onChangeTab={(option: string) => {
              clearData();
              setIsSubmitError(false);
              setClientErrors({});
              setCurrentTab(option);
            }}
          />
        </div>
        <div className="modal__body">
          {currentTab === "Create New" ? (
            <div className="modal__form" onKeyDown={handlePress}>
              <ValidationHint isSubmitError={isSubmitError} />
              <Input
                fieldName="firstName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["firstName"] !== undefined}
                value={firstName}
                type="text"
                label="First Name"
                onChange={handleOnChange}
                error={clientErrors.firstName}
                autoFocus={true}
              />
              <Input
                fieldName="lastName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["lastName"] !== undefined}
                value={lastName}
                type="text"
                label="Last Name"
                onChange={handleOnChange}
                error={clientErrors.lastName}
              />
              <CustomInput
                fieldName="email"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["email"] !== undefined}
                value={email}
                type="text"
                label="Email"
                onChange={handleOnChange}
                error={clientErrors.email}
                errorMessage={clientErrors.email}
                overlayClick={() => {
                  setClientErrors({
                    ...clientErrors,
                    email:
                      clientErrors.email === "Invalid Email"
                        ? "required"
                        : undefined,
                  });
                }}
              />
              <Input
                fieldName="title"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["title"] !== undefined}
                value={title}
                type="text"
                label="Title"
                onChange={handleOnChange}
              />
              <div className="modal__form-switch">
                <p>Send Welcome Email</p>
                <Toggle 
                  isSmall 
                  checked = {sendEmail} 
                  onChange={(e)=>setSendEmail(!sendEmail)} 
                  labelClassName="mb-0"   
                  /> 
              
              </div>
            </div>
          ) : (
            <div className="modal__form">
              <div className="admin-error">
                <ValidationHint isSubmitError={isSubmitError} />
              </div>
              <AdminsSelect
                options={adminsList}
                disableHandler={false}
                onChangeHandler={(item: any) => setCurrentAdmin(item)}
                noOptionsMessage={
                  <p className="no-admins">No District Admins Available</p>
                }
                open={isDropdownShow}
                setOpen={setDropdownShow}
                isId={!!id}
                onSave={onSubmit}
              />
            </div>
          )}
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface DistrictAddProps {
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
}

export const DistrictAddModal: React.FC<DistrictAddProps> = ({
  isShow,
  loading,
  onCancel,
  onAdd,
}) => {
  const [name, setName] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const dispatch = useDispatch();
  const { management } = useSelector((state: any) => state);

  useEffect(() => {
    if (management.districtsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        name: management.districtsError,
      });
    }
  }, [management.districtsError]);

  useEffect(() => {
    checkDisabled();
  }, [name]);

  const checkDisabled = () => {
    if (name) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearData();
    const errors = handleValidation();
    const isError = errors.name;
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        name,
      };
      onAdd(data);
    }
  };

  const handleValidation = () => {
    const errors: any = {};
    if (name.trim().length === 0) {
      errors["name"] = "required";
    }
    return errors;
  };

  const handleOnChange = (name: string, value: string) => {
    name === "name" && setName(value);
    if (isSubmitError) {
      const isValid = value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.ADD_DISTRICT_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Add District</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <CustomInput
              fieldName="name"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["name"] !== undefined}
              value={name}
              autoFocus={true}
              type="text"
              label="Name"
              onChange={handleOnChange}
              error={clientErrors.name}
              errorMessage={clientErrors.name}
              overlayClick={() => {
                setClientErrors({});
              }}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface SchoolAdminAddProps {
  school_id?: number;
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
  active?: number;
  district_id?: any;
}

export const SchoolAdminAddModal: React.FC<SchoolAdminAddProps> = ({
  school_id,
  isShow,
  loading,
  onCancel,
  onAdd,
  active,
  district_id,
}) => {
  const [adminsList, setAdminsList] = useState<null | any>(null);

  const [currentTab, setCurrentTab] = useState(
    !active ? "Search" : active === 2 ? "Create New" : "Search"
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [sendEmail, setSendEmail] = useState(true);
  const [isDisabled, setDisabled] = useState(true);
  const [isPrincipal, setIsPrincipal] = useState(false);
  const [isDropdownShow, setDropdownShow] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [id, setId] = useState<any>(null);

  const dispatch = useDispatch();
  const { management } = useSelector((state: any) => state);

  useEffect(() => {
    const init = async () => {
      try {
        let response = await getSchoolAdmins("", school_id);
        setAdminsList(response?.data?.admins);
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (management.schoolAdminsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        email: management.schoolAdminsError,
      });
    }
  }, [management.schoolAdminsError]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, email]);

  const checkDisabled = () => {
    if (firstName && lastName && email) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearError();
    const errors = validateCommonFields(firstName, lastName, email);
    const isError = errors.firstName || errors.lastName || errors.email;
    if (!isError) {
      let data = {
        admin_id: id,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        title: title?.trim(),
        send_email: sendEmail,
        is_principal: isPrincipal,
        district_id: parseInt(district_id),
      };
      onAdd(data);
    } else {
      setClientErrors(errors);
      setIsSubmitError(true);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    name === "title" && setTitle(value);
    if (isSubmitError) {
      const isValid =
        name === "email"
          ? validateEmail(value.trim())
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearError = () => {
    dispatch({
      type: ActionType.ADD_SCHOOL_ADMIN_ERROR,
      payload: null,
    });
  };

  const clearData = () => {
    dispatch({
      type: ActionType.ADD_SCHOOL_ADMIN_ERROR,
      payload: null,
    });
    setFirstName("");
    setLastName("");
    setTitle("");
    setEmail("");
    setClientErrors({});
    setId(null);
    setIsSubmitError(false);
    clearError();
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  const setCurrentAdmin = (data: any) => {
    const { item } = data;
    setId(item?.id);
    setFirstName(item?.first_name);
    setLastName(item?.last_name);
    setEmail(item?.email);
    setDropdownShow(false);
  };

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Add School Admin</h6>
        </div>
        {active !== 2 && (
          <div className="modal__tabs add-class-admin-tabs">
            <Tabs
              options={
                !active
                  ? ["Search", "Create New"]
                  : active === 2
                  ? ["Create New"]
                  : ["Search"]
              }
              currentTab={currentTab}
              onChangeTab={(option: string) => {
                clearData();
                setIsSubmitError(false);
                setClientErrors({});
                setCurrentTab(option);
              }}
            />
          </div>
        )}
        <div className="modal__body">
          {currentTab === "Create New" || active === 2 ? (
            <div className="modal__form" onKeyDown={handlePress}>
              <ValidationHint isSubmitError={isSubmitError} />
              <Input
                fieldName="firstName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["firstName"] !== undefined}
                value={firstName}
                type="text"
                label="First Name"
                onChange={handleOnChange}
                autoFocus={true}
                error={clientErrors.firstName}
              />
              <Input
                fieldName="lastName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["lastName"] !== undefined}
                value={lastName}
                type="text"
                label="Last Name"
                onChange={handleOnChange}
                error={clientErrors.lastName}
              />
              <CustomInput
                fieldName="email"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["email"] !== undefined}
                value={email}
                type="text"
                label="Email"
                onChange={handleOnChange}
                error={clientErrors.email}
                errorMessage={clientErrors.email}
                overlayClick={() => {
                  setClientErrors({
                    ...clientErrors,
                    email:
                      clientErrors.email === "Invalid Email"
                        ? "required"
                        : undefined,
                  });
                }}
              />
              <Input
                fieldName="title"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["title"] !== undefined}
                value={title}
                type="text"
                label="Title"
                onChange={handleOnChange}
              />
              {active !== 2 && (
                <div className="modal__form-switch">
                  <p>Is Principal</p>
                  <Toggle 
                    isSmall 
                    onChange={(e)=>setIsPrincipal(!isPrincipal)} 
                    checked = {isPrincipal} 
                    labelClassName="mb-0" 
                    />
                 
                </div>
              )}
              <div className="modal__form-switch">
                <p>Send Welcome Email</p>
                <Toggle 
                  isSmall 
                  checked= {sendEmail}  
                  onChange={(e)=>setSendEmail(!sendEmail)} 
                  labelClassName="mb-0"/>
              </div>
            </div>
          ) : (
            <div className="modal__form">
              <div className="admin-error">
                <ValidationHint isSubmitError={isSubmitError} />
              </div>
              <AdminsSelect
                options={adminsList}
                disableHandler={false}
                onChangeHandler={(item: any) => setCurrentAdmin(item)}
                noOptionsMessage={
                  <p className="no-admins">No School Admins Available</p>
                }
                open={isDropdownShow}
                setOpen={setDropdownShow}
                isId={!!id}
                onSave={onSubmit}
              />
            </div>
          )}
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface ClassAdminAddProps {
  classroom_id?: number;
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
  school_id?: any;
  create?: boolean;
}

export const ClassAdminAddModal: React.FC<ClassAdminAddProps> = ({
  classroom_id,
  isShow,
  loading,
  onCancel,
  onAdd,
  school_id,
  create,
}) => {
  const [adminsList, setAdminsList] = useState<null | any>(null);
  const [currentTab, setCurrentTab] = useState("Search");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [sendEmail, setSendEmail] = useState(true);
  const [isDisabled, setDisabled] = useState(true);
  const [isDropdownShow, setDropdownShow] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [id, setId] = useState<any>(null);

  const dispatch = useDispatch();
  const { management } = useSelector((state: any) => state);

  useEffect(() => {
    const init = async () => {
      try {
        let response = await getClassAdmins("", classroom_id);
        setAdminsList(response?.data?.admins ?? []);
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (management.classAdminsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        email: management.classAdminsError,
      });
    }
  }, [management.classAdminsError]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, email]);

  const checkDisabled = () => {
    if (firstName && lastName && email) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearError();
    const errors = validateCommonFields(firstName, lastName, email);
    const isError = errors.firstName || errors.lastName || errors.email;
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        admin_id: id,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        email: email.trim(),
        send_email: sendEmail,
        title: title?.trim(),
        school_id: parseInt(school_id),
      };
      onAdd(data);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "email" && setEmail(value);
    name === "title" && setTitle(value);
    if (isSubmitError) {
      const isValid =
        name === "email"
          ? validateEmail(value.trim())
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearError = () => {
    dispatch({
      type: ActionType.ADD_CLASSADMINS_ERROR,
      payload: null,
    });
  };

  const clearData = () => {
    setFirstName("");
    setLastName("");
    setTitle("");
    setEmail("");
    setClientErrors({});
    setId(null);
    setIsSubmitError(false);
    clearError();
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  const setCurrentAdmin = (data: any) => {
    const { item } = data;
    setId(item?.id);
    setFirstName(item?.first_name);
    setLastName(item?.last_name);
    setEmail(item?.email);
    setDropdownShow(false);
  };

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Add Class Admin</h6>
        </div>
        {!create && (
          <div className="modal__tabs add-class-admin-tabs">
            <Tabs
              options={["Search", "Create New"]}
              currentTab={currentTab}
              onChangeTab={(option: string) => {
                clearData();
                setIsSubmitError(false);
                setClientErrors({});
                setCurrentTab(option);
              }}
            />
          </div>
        )}
        <div className="modal__body">
          {currentTab === "Create New" || create ? (
            <div className="modal__form" onKeyDown={handlePress}>
              <ValidationHint isSubmitError={isSubmitError} />
              <Input
                fieldName="firstName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["firstName"] !== undefined}
                value={firstName}
                type="text"
                autoFocus={true}
                label="First Name"
                onChange={handleOnChange}
                error={clientErrors.firstName}
              />
              <Input
                fieldName="lastName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["lastName"] !== undefined}
                value={lastName}
                type="text"
                label="Last Name"
                onChange={handleOnChange}
                error={clientErrors.lastName}
              />
              <CustomInput
                fieldName="email"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["email"] !== undefined}
                value={email}
                type="text"
                label="Email"
                onChange={handleOnChange}
                error={clientErrors.email}
                errorMessage={clientErrors.email}
                overlayClick={() => {
                  setClientErrors({
                    ...clientErrors,
                    email:
                      clientErrors.email === "Invalid Email"
                        ? "required"
                        : undefined,
                  });
                }}
              />
              <Input
                fieldName="title"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["title"] !== undefined}
                value={title}
                type="text"
                label="Title"
                onChange={handleOnChange}
              />
              <div className="modal__form-switch">
                <p>Send Welcome Email</p>
                <Toggle 
                  isSmall  
                  onChange={(e)=>setSendEmail(!sendEmail)}
                  checked={sendEmail}
                  labelClassName="mb-0"
                  />
              </div>
            </div>
          ) : (
            <div className="modal__form">
              <div className="admin-error">
                <ValidationHint isSubmitError={isSubmitError} />
              </div>
              <AdminsSelect
                options={adminsList}
                disableHandler={false}
                onChangeHandler={(item: any) => setCurrentAdmin(item)}
                noOptionsMessage={
                  <p className="no-admins">No Class Admins Available</p>
                }
                open={isDropdownShow}
                setOpen={setDropdownShow}
                isId={!!id}
                onSave={onSubmit}
              />
            </div>
          )}
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface SchoolAddProps {
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
  isSummativeShow?: boolean;
  superAdmins: any[];
}

export const SchoolAddModal: React.FC<SchoolAddProps> = ({
  isShow,
  loading,
  onCancel,
  onAdd,
  isSummativeShow = true,
}) => {
  const userRole = Cookies.get("role");

  const [schoolName, setSchoolName] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [stdLicense, setStdLicense] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [defaultSummatives, setDefaultSummatives] = useState<null | any>(null);
  const [availablityFlag, setAvailablityFlag] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [defaults, setDefaults] = useState<null | any>(null);
  const [facilitatorId, setFacilitatorId] = useState<any>(0);
  const [super_admins, setSuperAdmins] = useState([]);
  const dispatch = useDispatch();
  const { management } = useSelector((state: any) => state);

  useEffect(() => {
    const fetch = async () => {
      const superAdmins = await getSuperAdmins({ offset: 0, limit: 9999 });
      const { super_admins } = superAdmins;
      setSuperAdmins(super_admins);
      const summatives = await getDefaultSummatives();
      setDefaults([...summatives.data]);
      setDefaultSummatives([...summatives.data]);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (management.schoolsError) {
      setIsSubmitError(true);
      setClientErrors({
        ...clientErrors,
        schoolName: management.schoolsError,
      });
    }
  }, [management.schoolsError]);

  useEffect(() => {
    checkDisabled();
  }, [schoolName, stdLicense]);

  const checkDisabled = () => {
    if (schoolName && stdLicense) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.ADD_SCHOOL_ERROR,
      payload: null,
    });
  };

  useEffect(() => {
    setDefaultSummatives(defaults);
  }, [availablityFlag]);

  const onSubmit = () => {
    clearData();
    const errors = validateSchoolFields(
      schoolName,
      // addressOne,
      // addressTwo,
      // zip,
      // phone,
      // city,
      // state,
      stdLicense,
      defaultSummatives
    );
    const isError = errors.assessment || errors.schoolName || errors.stdLicense;
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        name: schoolName,
        address_1: addressOne,
        address_2: addressTwo,
        city: city,
        state: state,
        zip: zip,
        phone: phone,
        student_licenses: Number(stdLicense),
        has_pdf_access: true,
        summatives: [...defaultSummatives],
        facilitator_id: facilitatorId,
      };
      onAdd(data);
    }
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  const doChangeDate = (e: any, i: number) => {
    let summativesCopy = _.cloneDeep([...defaultSummatives]);
    if (summativesCopy[i][e.target.name] !== e.target.value) {
      setDisabled(false);
    }
    let disableButton = false;
    summativesCopy.map((item, index) => {
      if (
        (item["edit_end_date"] &&
          !moment(
            e.target.name === "edit_end_date" && index === i
              ? e.target.value
              : item["edit_end_date"],
            "M-D-YY",
            true
          ).isValid()) ||
        (item["monitor_end_date"] &&
          !moment(
            e.target.name === "monitor_end_date" && index === i
              ? e.target.value
              : item["monitor_end_date"],
            "M-D-YY",
            true
          ).isValid()) ||
        (item["start_date"] &&
          !moment(
            e.target.name === "start_date" && index === i
              ? e.target.value
              : item["start_date"],
            "M-D-YY",
            true
          ).isValid())
      ) {
        disableButton = true;
      }
    });

    setDisabled(disableButton);
    console.log("defaults", defaults[i][e.target.name], e.target.value);
    if (defaults[i][e.target.name] !== e.target.value) {
      summativesCopy[i]["edit_date_type"] = "CUSTOM";
      summativesCopy[i]["monitor_date_type"] = "CUSTOM";
      summativesCopy[i]["start_date_type"] = "CUSTOM";
    } else {
      summativesCopy[i]["edit_date_type"] = "DEFAULT";
      summativesCopy[i]["monitor_date_type"] = "DEFAULT";
      summativesCopy[i]["start_date_type"] = "DEFAULT";
    }
    summativesCopy[i][e.target.name] = e.target.value;
    setDefaultSummatives([...summativesCopy]);
    // console.log(summativesCopy , "Again.........")
  };

  const handleOnChange = (name: string, value: string) => {
    name === "schoolName" && setSchoolName(value);
    name === "addressOne" && setAddressOne(value);
    name === "zip" && setZip(value);
    name === "addressTwo" && setAddressTwo(value);
    name === "phone" && setPhone(value);
    name === "city" && setCity(value);
    name === "state" && setState(value);
    name === "stdLicense" && setStdLicense(value);
    name === "facilitatorId" && setFacilitatorId(parseInt(value));
    if (isSubmitError) {
      const isValid = value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const formatOptionLabel = (
    { label, first_name, last_name }: any,
    metaData: any
  ) => {
    const initials = `${first_name.length > 0 ? first_name[0] : ""}${
      last_name.length > 0 ? last_name[0] : ""
    }`;
    return metaData.context === "menu" ? (
      <div>
        <span>{label}</span>
      </div>
    ) : (
      <div>
        <span>{initials}</span>
      </div>
    );
  };

  const customStyles = {
    control: (provided: any) => ({
      // none of react-select's styles are passed to <Control />
      // width: 200,
      ...provided,
      height: 42,
      borderRadius: 8,
    }),
  };

  return (
    <CustomModal
      contentClassName="modal-content-edit"
      className="modal-edit-container"
      centered
      show={isShow}
      onEscapeKeyDown={handleCancel}
    >
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Add School</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Row>
              <Col sm={6} md={6} className="pe-12 pe-sm-2">
                <CustomInput
                  fieldName="schoolName"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["schoolName"] !== undefined}
                  value={schoolName}
                  type="text"
                  label="School Name"
                  onChange={handleOnChange}
                  error={clientErrors.schoolName}
                  errorMessage={
                    clientErrors.schoolName !== "required"
                      ? clientErrors.schoolName
                      : ""
                  }
                  overlayClick={() => {
                    setClientErrors({
                      ...clientErrors,
                      schoolName: "required",
                    });
                  }}
                />
              </Col>
              <Col sm={6} md={6}></Col>
            </Row>
            <Row>
              <Col sm={6} md={6} className="pe-12 pe-sm-2">
                <Input
                  fieldName="addressOne"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["addressOne"] !== undefined}
                  value={addressOne}
                  type="text"
                  label="Address 1"
                  onChange={handleOnChange}
                  error={clientErrors.addressOne}
                />
              </Col>
              <Col sm={6} md={6} className="ps-12 ps-sm-2">
                <Input
                  fieldName="zip"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["zip"] !== undefined}
                  value={zip}
                  type="text"
                  label="Zip"
                  onChange={handleOnChange}
                  error={clientErrors.zip}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={6} className="pe-12 pe-sm-2">
                <Input
                  fieldName="addressTwo"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["addressTwo"] !== undefined}
                  value={addressTwo}
                  type="text"
                  label="Address 2"
                  onChange={handleOnChange}
                  error={clientErrors.addressTwo}
                />
              </Col>
              <Col sm={6} md={6} className="ps-12 ps-sm-2">
                <Input
                  fieldName="phone"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["phone"] !== undefined}
                  value={phone}
                  type="text"
                  label="Phone"
                  onChange={handleOnChange}
                  error={clientErrors.phone}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4} className="pe-12 pe-sm-2">
                <Input
                  fieldName="city"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["city"] !== undefined}
                  value={city}
                  type="text"
                  label="City"
                  onChange={handleOnChange}
                  error={clientErrors.city}
                />
              </Col>
              <Col xs={12} sm={6} md={2} className="pe-sm-12 ps-12 ps-sm-2">
                <Input
                  fieldName="state"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["state"] !== undefined}
                  value={state}
                  type="text"
                  label="State"
                  onChange={handleOnChange}
                  error={clientErrors.state}
                />
              </Col>
              <Col
                xs={12}
                sm={6}
                md={
                  clientErrors["stdLicense"] &&
                  clientErrors.stdLicense !== "required"
                    ? 6
                    : 3
                }
                className="ps-12 ps-sm-2"
              >
                <CustomInput
                  fieldName="stdLicense"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["stdLicense"] !== undefined}
                  value={stdLicense}
                  type="number"
                  label="Student Licenses"
                  onChange={handleOnChange}
                  onKeyDownProp={(event: any) => checkIfNumber(event)}
                  error={clientErrors.stdLicense}
                  errorMessage={
                    clientErrors.stdLicense !== "required"
                      ? clientErrors.stdLicense
                      : ""
                  }
                  overlayClick={() => {
                    setClientErrors({
                      ...clientErrors,
                      stdLicense: "required",
                    });
                  }}
                />
              </Col>

              <Col
                xs={12}
                sm={6}
                md={
                  clientErrors["stdLicense"] &&
                  clientErrors.stdLicense !== "required"
                    ? 6
                    : 3
                }
                className="ps-12 ps-sm-2"
              >
                {userRole === "Super Admin" && (
                  // <SelectInput
                  //   fieldName="facilitatorId"
                  //   isSubmitError={isSubmitError}
                  //   value={facilitatorId}
                  //   label="Facilitator"
                  //   onChange={handleOnChange}
                  //   error={clientErrors.facilitatorId}
                  //   errorPresentBefore={false}
                  //   defaultTitle={"Select Super Admin"}
                  //   options={super_admins?.map((item: any) => ({...item, name: `${item.first_name}${item.last_name ? ` ${item.last_name}` : ''}`})) || []}
                  // />
                  <div className="facilitator-select pt-1 position-relative">
                    <label className="facilitator-select__label position-absolute">
                      Facilitator
                    </label>
                    <Select
                      isSearchable
                      options={
                        super_admins?.map((item: any) => ({
                          ...item,
                          name: `${item.first_name}${
                            item.last_name ? ` ${item.last_name}` : ""
                          }`,
                          label: `${item.first_name}${
                            item.last_name ? ` ${item.last_name}` : ""
                          }`,
                          value: item.id,
                        })) || []
                      }
                      placeholder="None"
                      onChange={(data: any) =>
                        handleOnChange("facilitatorId", data.value)
                      }
                      className="facilitator-select__wrapper"
                      classNamePrefix="facilitator-select"
                      formatOptionLabel={formatOptionLabel}
                      components={{
                        IndicatorSeparator: () => null,
                        // DropdownIndicator: (props) => (
                        //   <div {...props}>
                        //     <img src={DrdownIcon} />
                        //   </div>
                        // )
                      }}
                      styles={customStyles}
                      value={super_admins?.find(
                        (item: any) => item.id === facilitatorId
                      )}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
          {isSummativeShow ? (
            <div className="modal__assesment">
              <div className="d-flex align-items-center w-100">
                <div className="modal__assesment-availablity">
                  <p>Assessment Availability</p>
                  {defaultSummatives &&
                  defaultSummatives?.filter(
                    (item: any) =>
                      (item?.edit_date_type &&
                        item?.edit_date_type !== "DEFAULT") ||
                      (item?.monitor_date_type &&
                        item?.monitor_date_type !== "DEFAULT") ||
                      (item?.start_date_type &&
                        item?.start_date_type !== "DEFAULT")
                  ).length ? (
                    <div className="pill-warning">Custom</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="modal__assesment-date">
                  <p>Date format is MM-DD-YY</p>
                </div>
                <p className="text-danger ms-auto mb-0 shadow-text fw-bold">
                  {clientErrors.assessment}
                </p>
              </div>
              <div className="modal__table-container">
                <table className="modal__table">
                  <thead>
                    <tr>
                      <th className="modal__table-name">Assessment Name</th>
                      <th className="modal__table-monitor">Monitor</th>
                      <th className="modal__table-edit">Edit Button</th>
                    </tr>
                  </thead>
                  <tbody>
                    {defaultSummatives && defaultSummatives.length
                      ? defaultSummatives?.map((item: any, i: number) => {
                          return (
                            <tr key={i} className="modal__table-row">
                              <td>
                                <div className="name-data">
                                  {(item?.edit_date_type &&
                                    item?.edit_date_type !== "DEFAULT") ||
                                  (item?.monitor_date_type &&
                                    item?.monitor_date_type !== "DEFAULT") ||
                                  (item?.start_date_type &&
                                    item?.start_date_type !== "DEFAULT") ? (
                                    <span className="pill-circle"></span>
                                  ) : (
                                    ""
                                  )}
                                  <p>{item?.display_label}</p>
                                </div>
                              </td>
                              <td>
                                <div className="monitor-data">
                                  <span className="pill-success">From</span>
                                  {/* <p>{item?.start_date}</p> */}
                                  {item?.start_date !== null ? (
                                    <div className="modal__table-inputs">
                                      <input
                                        onChange={(e) => doChangeDate(e, i)}
                                        name="start_date"
                                        value={item?.start_date}
                                      />
                                      <small>
                                        {moment(
                                          item?.start_date,
                                          "M-D-YY",
                                          true
                                        ).isValid() ? (
                                          ""
                                        ) : (
                                          <span className="error-circle"></span>
                                        )}
                                      </small>
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                  <span className="pill-until">Until</span>
                                </div>
                              </td>
                              <td>
                                <div className="edit-data">
                                  {/* <p>{item?.monitor_end_date}</p> */}
                                  {item?.monitor_end_date !== null ? (
                                    <div className="modal__table-inputs">
                                      <input
                                        onChange={(e) => doChangeDate(e, i)}
                                        name="monitor_end_date"
                                        value={item?.monitor_end_date}
                                      />
                                      <small>
                                        {moment(
                                          item?.monitor_end_date,
                                          "M-D-YY",
                                          true
                                        ).isValid() ||
                                        (item.display_label ===
                                          "A2 for 3-8 R/M" &&
                                          item?.monitor_end_date === "") ? (
                                          ""
                                        ) : (
                                          <span className="error-circle"></span>
                                        )}
                                      </small>
                                    </div>
                                  ) : (
                                    <span className="empty-data">-</span>
                                  )}
                                  <span className="pill-until">Until</span>
                                  {/* <p>{item?.edit_end_date}</p> */}
                                  {item?.edit_end_date !== null ? (
                                    <div className="modal__table-inputs">
                                      <input
                                        onChange={(e) => doChangeDate(e, i)}
                                        name="edit_end_date"
                                        value={item?.edit_end_date}
                                      />
                                      <small>
                                        {moment(
                                          item?.edit_end_date,
                                          "M-D-YY",
                                          true
                                        ).isValid() ||
                                        (item.display_label ===
                                          "A2 for 3-8 R/M" &&
                                          item?.edit_end_date === "") ? (
                                          ""
                                        ) : (
                                          <span className="error-circle"></span>
                                        )}
                                      </small>
                                    </div>
                                  ) : (
                                    <span className="empty-data lg">-</span>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="modal__footer d-flex justify-content-between ">
          {isSummativeShow ? (
            <button
              onClick={() => setAvailablityFlag(!availablityFlag)}
              disabled={loading}
              className="btn outline-btn outline-btn-lg font-extra-sm"
            >
              Reset Availability to Default
            </button>
          ) : (
            ""
          )}
          <div className="inner-buttons">
            <Button
              buttonText="Cancel"
              onClick={handleCancel}
              className="btn cancel-btn"
              disabled={loading}
              type="button"
            />
            <Button
              buttonText="Save"
              onClick={onSubmit}
              className={`btn success-btn btn-lg ${
                (loading || isDisabled) && "btn-disabled"
              }`}
              loading={loading}
              type="button"
              id="save-button"
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

interface ClassAddProps {
  isShow: boolean;
  schoolId?: any;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
}

export const ClassAddModal: React.FC<ClassAddProps> = ({
  isShow,
  loading,
  onCancel,
  onAdd,
  schoolId,
}) => {
  const [adminList, setAdminList] = useState<null | any>(null);
  const [gradeList, setGradeList] = useState<null | any>(null);
  const [subjectList, setSubjectList] = useState<null | any>(null);
  const [grade, setGrade] = useState("0");
  const [notes, setNotes] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("0");
  const [selectedPeriod, setSelectedPeriod] = useState("0");
  const [isDisabled, setDisabled] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownShow, setDropdownShow] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState<any | null>(null);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [className, setClassName] = useState("");
  const [subject, setSubject] = useState<any>("");

  useEffect(() => {
    !isShow && handleCancel();
  }, [isShow]);

  useEffect(() => {
    console.log(selectedSubject, subjectList);

    if (subjectList && subjectList.length) {
      const subjectSelect = subjectList.find(
        (sub: any) => parseInt(sub.id) === parseInt(selectedSubject)
      );
      console.log(subjectSelect);

      setSubject(subjectSelect);
    }
  }, [subjectList, selectedSubject]);

  useEffect(() => {
    checkDisabled();
    if (currentAdmin || grade || subject) {
      let createClassroomName = `${
        currentAdmin
          ? currentAdmin.last_name + " " + currentAdmin.first_name[0]
          : ""
      } ${subject && subject.name ? subject.name : ""} ${
        grade && parseInt(grade) > 0
          ? parseInt(grade) === 9
            ? "K"
            : grade
          : ""
      }`;

      if (selectedPeriod) {
        if (+selectedPeriod === 0) {
          // createClassroomName = createClassroomName;
        } else if (+selectedPeriod === 1) {
          createClassroomName = `${createClassroomName} - ${selectedPeriod}st Hr`;
        } else if (+selectedPeriod === 2) {
          createClassroomName = `${createClassroomName} - ${selectedPeriod}nd Hr`;
        } else if (+selectedPeriod === 3) {
          createClassroomName = `${createClassroomName} - ${selectedPeriod}rd Hr`;
        } else {
          createClassroomName = `${createClassroomName} - ${selectedPeriod}th Hr`;
        }
      }
      if (notes && (!selectedPeriod || +selectedPeriod === 0)) {
        createClassroomName = `${createClassroomName} - (${notes})`;
      }
      if (notes && selectedPeriod && +selectedPeriod !== 0) {
        createClassroomName = `${createClassroomName} (${notes})`;
      }

      setClassName(createClassroomName);
    }
  }, [grade, subject, selectedPeriod, currentAdmin, notes]);

  const checkDisabled = () => {
    if (
      currentAdmin ||
      // notes ||
      selectedSubject !== "0" ||
      grade !== "0"
      // && selectedPeriod !== "0"
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    const getAllSubjects = async () => {
      let subjectResponse = await getSubjectList();
      let gradeResponse = await getGradeList();
      let adminResponse = await getAllClassAdmins(schoolId);
      setAdminList(adminResponse?.data?.classroom_admins);
      setSubjectList(subjectResponse?.data?.subjects);
      setGradeList(gradeResponse?.data?.grades);
    };
    getAllSubjects();
  }, []);

  const onSubmit = () => {
    const errors = validateClassFields(
      selectedSubject,
      grade,
      currentAdmin,
      selectedPeriod
    );
    let isError =
      errors.selectedSubject ||
      errors.grade ||
      errors.name ||
      errors.selectedPeriod ||
      errors.currentAdmin;
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let subject = subjectList.filter(
        (item: any) => item.id === Number(selectedSubject)
      )[0]?.name;

      let newPeriod =
        selectedPeriod &&
        parseInt(selectedPeriod) !== 0 &&
        periodList &&
        periodList.length
          ? periodList.filter(
              (item: any) => parseInt(item.name) === parseInt(selectedPeriod)
            )[0].value
          : "";
      let newClassName = `${currentAdmin?.last_name} ${
        currentAdmin?.first_name[0]
      } ${subject} ${grade === "9" ? "K" : grade} ${
        selectedPeriod !== "0" ? "- " + newPeriod : ""
      } ${notes ? "(" + notes + ")" : ""}`;

      let data: any = {
        grade_id: Number(grade),
        subject_id: Number(selectedSubject),
        name: newClassName,
        notes: notes,
        admin_name: `${currentAdmin.last_name}, ${currentAdmin.first_name}`,
        admin_id: currentAdmin.id,
      };

      if (selectedPeriod !== "0") {
        data.period = Number(selectedPeriod);
      }
      console.log(data, "..................");
      onAdd(data);
      handleCancel();
    }
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  const clearData = () => {
    setNotes("");
    setGrade("0");
    setSelectedSubject("0");
    setSelectedPeriod("0");
    setCurrentAdmin(null);
    setDropdownShow(false);
  };

  const onSetCurrentAdmin = (item: any) => {
    // console.log(item , "........")
    setCurrentAdmin(item);
    setDropdownShow(false);
    if (isSubmitError) {
      setClientErrors({ ...clientErrors, currentAdmin: "" });
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "grade" && setGrade(value);
    name === "selectedSubject" && setSelectedSubject(value);
    name === "selectedPeriod" && setSelectedPeriod(value);
    name === "notes" && setNotes(value);
    if (isSubmitError) {
      const isValid =
        name === "grade" || name === "selectedSubject"
          ? value !== "0"
          : name === "notes"
          ? true
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePressClass);
    return () => {
      document.removeEventListener("keydown", handlePressClass);
    };
  }, []);

  const filterdSubjects =
    grade === "5" || grade === "8"
      ? subjectList
      : subjectList?.filter((item: any) => item.name !== "Science");

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Add Class</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <div className="admin-error">
              <ValidationHint isSubmitError={isSubmitError} />
            </div>
            <AdminsSelectClass
              options={adminList}
              disableHandler={false}
              onChangeHandler={(data: any) => onSetCurrentAdmin(data?.item)}
              noOptionsMessage={
                <p className="no-admins">No Class Admins Available</p>
              }
              open={isDropdownShow}
              setOpen={setDropdownShow}
              isId={!!currentAdmin?.id}
              onSave={onSubmit}
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["currentAdmin"] !== undefined}
              error={clientErrors.currentAdmin}
            />
            <SelectInput
              fieldName="grade"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["grade"] !== undefined}
              value={grade}
              label="Grade"
              onChange={handleOnChange}
              error={clientErrors.grade}
              options={gradeList}
              defaultTitle="Select Grade"
            />
            <SelectInput
              fieldName="selectedSubject"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["selectedSubject"] !== undefined}
              value={selectedSubject}
              label="Subject"
              onChange={handleOnChange}
              error={clientErrors.selectedSubject}
              options={filterdSubjects}
              defaultTitle="Select Subject"
            />
            <SelectInput
              fieldName="selectedPeriod"
              errorPresentBefore={clientErrors["selectedPeriod"] !== undefined}
              value={selectedPeriod}
              label="Period"
              onChange={handleOnChange}
              error={clientErrors.selectedPeriod}
              options={[...periodList]}
              notDisableFirst={true}
              defaultTitle=" Select Period"
              isSubmitError={false}
            />
            <Input
              fieldName="notes"
              errorPresentBefore={clientErrors["notes"] !== undefined}
              value={notes}
              label="Notes"
              type="text"
              onChange={handleOnChange}
              margin={true}
              error={clientErrors.notes}
              isSubmitError={false}
            />
          </div>
        </div>

        {className && <p style={{ margin: "0 0 16px 13px" }}>{className}</p>}

        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              loading || isDisabled ? "btn-disabled" : ""
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface StudentAddProps {
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
}

export const StudentAddModal: React.FC<StudentAddProps> = ({
  isShow,
  loading,
  onCancel,
  onAdd,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [schoolStdId, setSchoolStdId] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { management } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, schoolStdId]);

  const checkDisabled = () => {
    if (firstName && lastName && schoolStdId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    if (management.studentAddError) {
      if (management.studentAddError) {
        setClientErrors({
          ...clientErrors,
          schoolStdId: management.studentAddError?.response?.data?.message,
        });

        setIsSubmitError(true);
      }
    }
  }, [management.studentAddError]);

  const onSubmit = () => {
    clearData();
    const errors = validateStudentFields(firstName, lastName, schoolStdId);
    const isError = errors.firstName || errors.lastName || errors.schoolStdId;
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        school_student_id: schoolStdId,
      };
      onAdd(data);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "schoolStdId" && setSchoolStdId(value);
    if (isSubmitError) {
      const isValid =
        name === "schoolStdId"
          ? value.length <= 10 && value.length >= 4
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearData = () => {
    dispatch({
      type: ActionType.ADD_STUDENT_ERROR,
      payload: null,
    });
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Add Student</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
            <Input
              fieldName="firstName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["firstName"] !== undefined}
              value={firstName}
              type="text"
              autoFocus={true}
              label="First Name"
              onChange={handleOnChange}
              error={clientErrors.firstName}
            />
            <Input
              fieldName="lastName"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["lastName"] !== undefined}
              value={lastName}
              type="text"
              label="Last Name"
              onChange={handleOnChange}
              error={clientErrors.lastName}
            />
            <CustomInput
              fieldName="schoolStdId"
              isSubmitError={isSubmitError}
              // errorPresentBefore={clientErrors["schoolStdId"] !== undefined}
              errorPresentBefore={
                clientErrors["schoolStdId"] !== undefined &&
                clientErrors["schoolStdId"] !== ""
              }
              value={schoolStdId}
              type="number"
              label="Student ID"
              onChange={handleOnChange}
              onKeyDownProp={(event: any) => checkIfNumber(event)}
              error={clientErrors.schoolStdId}
              // errorMessage='Student ID must be 10 digits'
              errorMessage={
                clientErrors.schoolStdId !== "required"
                  ? clientErrors.schoolStdId
                  : ""
              }
              overlayClick={() =>
                setClientErrors({
                  ...clientErrors,
                  schoolStdId: "required",
                })
              }
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Add"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface AddStudentToClassProps {
  isShow: boolean;
  school_id: number;
  classroom_id: number;
  onCancel: () => void;
  onAdd: (data: any, bySearch: boolean) => void;
  loading: boolean;
  create?: boolean;
}

export const AddStudentToClassModal: React.FC<AddStudentToClassProps> = ({
  isShow,
  school_id,
  classroom_id,
  loading,
  onCancel,
  onAdd,
  create,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [allStudents, setAllStudents] = useState<null | any>(null);
  const [currentTab, setCurrentTab] = useState("Search");
  const [isDropdownShow, setDropdownShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [schoolStdId, setSchoolStdId] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [studentData, setStudentData] = useState({});
  const [currentStudent, setCurrentStudent] = useState<any>(null);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const { management } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    !isShow && handleCancel();
  }, [isShow]);

  useEffect(() => {
    checkDisabled();
  }, [firstName, lastName, schoolStdId, searchTerm, currentStudent]);

  const getSchoolStudents = async () => {
    try {
      let response = await fetchSchoolStudents("", school_id, classroom_id);
      console.log(response.data, "SEARCH");
      setAllStudents(response?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSchoolStudents();
    // if (searchTerm) {

    // }
  }, []);

  useEffect(() => {
    if (management.studentAddError) {
      setClientErrors({
        ...clientErrors,
        schoolStdId: management.studentAddError?.response?.data?.message,
      });

      setIsSubmitError(true);
    }
  }, [management.studentAddError]);

  const doFetchStudents = () => {
    setDropdownShow(true);
    if (!searchTerm) {
      getSchoolStudents();
    }
  };

  const checkDisabled = () => {
    if (
      (firstName && lastName && schoolStdId) ||
      searchTerm ||
      currentStudent?.first_name ||
      currentStudent?.last_name
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = () => {
    clearError();
    if (currentTab === "Search") {
      onAdd(studentData, true);
    } else {
      const errors = validateStudentFields(firstName, lastName, schoolStdId);
      const isError = errors.firstName || errors.lastName || errors.schoolStdId;
      if (isDisabled && !isError) {
        return handleCancel();
      }
      if (isError) {
        setClientErrors(errors);
        setIsSubmitError(true);
      } else {
        let data = {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          school_student_id: schoolStdId,
          classroom_id,
        };
        onAdd(data, false);
      }
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "firstName" && setFirstName(value);
    name === "lastName" && setLastName(value);
    name === "schoolStdId" && setSchoolStdId(value);
    if (isSubmitError) {
      const isValid =
        name === "schoolStdId"
          ? value.length <= 10 && value.length >= 4
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const clearError = () => {
    dispatch({
      type: ActionType.ADD_STUDENT_ERROR,
      payload: null,
    });
  };

  const clearData = () => {
    setFirstName("");
    setLastName("");
    setSchoolStdId("");
    setDisabled(true);
    setCurrentTab("Search");
    setClientErrors({});
    setStudentData({});
    setIsSubmitError(false);
    setCurrentStudent(null);
    clearError();
  };

  const handleCancel = () => {
    setCurrentStudent(null);
    clearData();
    onCancel();
  };

  const onSetCurrentStudent = (item: any) => {
    // setSearchTerm(`${item?.last_name}, ${item?.first_name}`);
    setCurrentStudent(item);
    setStudentData({
      classroom_id,
      student_id: item.id,
    });
    setDropdownShow(false);
  };

  // useEffect(() => {
  //   document.addEventListener('keydown', handlePress);
  //   return () => {
  //       document.removeEventListener('keydown', handlePress);
  //   }
  // }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Add Student</h6>
        </div>
        {!create && (
          <div className="modal__tabs add-class-admin-tabs">
            <Tabs
              options={["Search", "Create New"]}
              currentTab={currentTab}
              onChangeTab={(option: string) => {
                clearData();
                setCurrentTab(option);
              }}
            />
          </div>
        )}
        <div className="modal__body">
          {currentTab === "Create New" || create ? (
            <div className="modal__form" onKeyDown={handlePress}>
              <ValidationHint isSubmitError={isSubmitError} />
              <Input
                fieldName="firstName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["firstName"] !== undefined}
                value={firstName}
                autoFocus={true}
                type="text"
                label="First Name"
                onChange={handleOnChange}
                error={clientErrors.firstName}
              />
              <Input
                fieldName="lastName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["lastName"] !== undefined}
                value={lastName}
                type="text"
                label="Last Name"
                onChange={handleOnChange}
                error={clientErrors.lastName}
              />
              <CustomInput
                fieldName="schoolStdId"
                isSubmitError={isSubmitError}
                // errorPresentBefore={clientErrors["schoolStdId"] !== undefined}
                errorPresentBefore={
                  clientErrors["schoolStdId"] !== undefined &&
                  clientErrors["schoolStdId"] !== ""
                }
                value={schoolStdId}
                type="number"
                label="Student ID"
                onChange={handleOnChange}
                onKeyDownProp={(event: any) => checkIfNumber(event)}
                error={clientErrors.schoolStdId}
                // errorMessage='Student ID must be 10 digits'
                errorMessage={
                  clientErrors.schoolStdId !== "required"
                    ? clientErrors.schoolStdId
                    : ""
                }
                overlayClick={() =>
                  setClientErrors({
                    ...clientErrors,
                    schoolStdId: "required",
                  })
                }
              />
            </div>
          ) : (
            <div className="modal__form">
              <div className="admin-error">
                <ValidationHint isSubmitError={isSubmitError} />
              </div>
              <AdminsSelect
                options={allStudents}
                disableHandler={false}
                onChangeHandler={(data: any) => onSetCurrentStudent(data.item)}
                noOptionsMessage={
                  <p className="no-admins">No Student Available</p>
                }
                open={isDropdownShow}
                setOpen={setDropdownShow}
                isId={!!currentStudent?.id}
                onSave={onSubmit}
              />
              {/* <div className="modal__form-row">
                <div className="search-select">
                  <input
                    value={
                      currentStudent
                        ? `${currentStudent.last_name}, ${currentStudent.first_name}`
                        : searchTerm
                    }
                    onChange={(e) => {
                      setCurrentStudent(null);
                      setSearchTerm(e.target.value);
                    }}
                    placeholder="Select or Type Name"
                    onFocus={doFetchStudents}
                  />
                  <span className="search-select-icon" onClick={() => setDropdownShow(!isDropdownShow)}>
                  <img
                    onClick={doFetchStudents}
                    src={DropdownArrow}
                    alt="drop"
                  />
                  </span>
                </div>
                <small className="px-2 text-danger">
                  {clientErrors.firstName}
                </small>
              </div>
              {isDropdownShow ? (
                <div className="all-admins-container">
                  {allStudents && allStudents.length ? (
                    allStudents.map((item: any) => {
                      return (
                        <div
                          key={uuidv4()}
                          onClick={() => {
                            onSetCurrentStudent(item);
                            setDropdownShow(false);
                          }}
                          className="admin-item"
                        >
                          <p>
                            {item?.last_name}, {item.first_name}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className="no-admins">No Student Available</p>
                  )}
                </div>
              ) : (
                ""
              )} */}
            </div>
          )}
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Add"
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface ResetPasswordProps {
  isShow: boolean;
  onCancel: () => void;
  onAdd: () => void;
  loading: boolean;
  password: string;
  title: string;
}

export const ResetPasswordModal: React.FC<ResetPasswordProps> = ({
  isShow,
  loading,
  password,
  onCancel,
  onAdd,
  title,
}) => {
  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onCancel}>
      <div>
        <div className="modal__header">
          <img
            src={RPIcon}
            className="modal__header-icon margin-top-2"
            alt="Password"
          />
          <h6 className="modal__header-title">{title}</h6>
        </div>
        <div className="modal__body">
          <p className="modal__body-text">
            The password below will be valid for 24 hours.
          </p>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="modal__body-temporary">Temporary Password</p>
            <p className="modal__body-password">{password && password}</p>
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={onCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Create"
            onClick={onAdd}
            className="btn success-btn btn-lg"
            disabled={loading}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};
