import {
  HeaderTabs,
  ProfessionalDevelopment,
  SingleFormative,
  SingleSummative,
  Assessment,
  ReportsNavigation,
} from "interfaces/professionalDevelopment";
import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllFormatives,
  getAllSummatives,
  getAssessmentMonitorData,
  getFormativeRemediationData,
  getProfessionalDevelopmentClassroom,
  getProfessionalDevelopmentClassroomTillDate,
  markCompleted,
  reEnableTest,
  triggerAdvanceWeek,
} from "services/professionalDevelopment";
import {
  ProfessionalDevelopmentActions,
  ProfessionalDevelopmentReducer,
} from "./reducers/professionalDevelopment";
import Cookie from "js-cookie";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  isPrevYearClassroomSTLExists,
  isPrevYearSTLExists,
} from "services/common";

const initialState: ProfessionalDevelopment = {
  selectedGoal: {
    instructional_text: "",
    goal: "",
    complete_by: "",
    is_multi_step: false,
    is_recurring: false,
    tasks: [],
    tasks_count: 0,
    completed_tasks_count: 0,
  },
  formatives: [],
  summatives: [],
  activeHeaderTab: "",
  classroomId: null!,
  schoolId: null!,
  navigateToScreen: null!,
  pdClassroom: {
    loading: true,
    goals: [],
    is_enabled: true,
    tools: [],
    week_text: "",
    tooltips_seen: true,
    week_date: "",
    has_books_access: true,
    show_no_schedule_tooltip: false,
    show_no_goal_tooltip: false,
    book_access: "",
  },
  goalsList: {
    month: "",
    year: "",
    week_goals: [],
    recurring_week_goals: [],
    week_goals_available: [],
    week_goals_upcoming: [],
  },
  markAssessmentCompleted: null!,
  setFormatives: null!,
  showReportModal: "",
  classrooms: [],
  showReviewHelpModel: false,
  isLoading: true,
  updateActiveHeaderTab: () => {},
  navigateTo: () => {},
  setShowReportAlertModal: () => {},
  setShowReviewHelpModel: () => {},
  setIsLoading: () => {},
  setAssessmentMonitor: () => {},
  fetchFormatives: null!,
  setSummatives: null!,
  fetchSummatives: (id: number, classroomId: number) => Promise<any>,
  advanceWeek: (classroomId: number) => {},
  fetchProfessionalDevelopmentClassroomTillDate: null!,
  fetchAssessmentMonitorData: null!,
  setPdClassroom: null!,
  setGoalsList: null!,
  prevSTLexists: false,
  prevSTLClassroomExists: false,
  setSTLType: null!,
  STLType: "",
  assessmentMonitorLoading: true,
  fetchFormativeRemediateData: null!,
  formativeRemediateList: {
    objective_id: 0,
    objective: "",
    status_key: "",
    students: [],
  },
  reEnableAssessments: null!,
  assessmentMonitor: {
    open_assessments: 0,
    online_students: 0,
    assessments: [],
  },
};

export const ProfessionalDevelopmentContext =
  createContext<ProfessionalDevelopment>(initialState);

const ProfesssionalDevelopmentProvider = ({ children }: any) => {
  const [
    {
      formativeRemediateList,
      assessmentMonitor,
      selectedGoal,
      formatives,
      summatives,
      activeHeaderTab,
      pdClassroom,
      goalsList,
      prevSTLexists,
      prevSTLClassroomExists,
    },
    dispatch,
  ] = useReducer(ProfessionalDevelopmentReducer, initialState);

  const {
    classroom: { classrooms },
    auth,
  } = useSelector((state: any) => state);
  const classroomId = Number(sessionStorage.getItem("c_classroom_id"));
  const schoolId = Number(sessionStorage.getItem("c_school_id"));
  const navigate = useNavigate();

  const [assessmentMonitorLoading, setAssessmentMonitorLoading] = useState(
    initialState.assessmentMonitorLoading
  );
  const [showReportModal, setShowReportAlertModal] = useState<string>("");
  const [showReviewHelpModel, setShowReviewHelpModel] = useState(false);
  const [isLoading, setIsLoading] = useState(initialState.isLoading);
  const [STLType, setSTLType] = useState("");

  const setPdClassroomLoading = (loading: boolean) => {
    dispatch({
      type: ProfessionalDevelopmentActions.SET_PD_CLASSROOM_LOADING,
      payload: loading,
    });
  };

  const setPdClassroom = (pdClassroom: any) => {
    dispatch({
      type: ProfessionalDevelopmentActions.SET_PD_CLASSROOM,
      payload: pdClassroom,
    });
  };

  const fetchFormativeRemediateData = async (formativeId: any) => {
    const remediatedList = await getFormativeRemediationData(
      classroomId,
      formativeId
    );
    remediatedList &&
      dispatch({
        type: ProfessionalDevelopmentActions.SET_FORMATIVE_REMEDIATE_DATA,
        payload: remediatedList,
      });
  };

  const setAssessmentMonitor = (data: any) => {
    dispatch({
      type: ProfessionalDevelopmentActions.SET_ASSESSMENT_MONITOR,
      payload: data,
    });
  };

  const fetchAssessmentMonitorData = async () => {
    setAssessmentMonitorLoading(true);
    const data = await getAssessmentMonitorData(classroomId);
    setAssessmentMonitorLoading(false);
    data && setAssessmentMonitor(data);

    return data;
  };

  const fetchProfessionalDevelopmentClassroom = async () => {
    setPdClassroomLoading(true);
    const pdClassroom = await getProfessionalDevelopmentClassroom(classroomId);
    pdClassroom ? setPdClassroom(pdClassroom) : setPdClassroomLoading(false);

    // if(pdClassroom && !pdClassroom.is_enabled){
    //     updateActiveHeaderTab(HeaderTabs.Resources);
    //     return;
    // }

    // if(!activeHeaderTab){
    //     updateActiveHeaderTab(HeaderTabs.THIS_WEEK);
    // }
  };

  const setGoalsList = (goalsList: any) => {
    goalsList &&
      dispatch({
        type: ProfessionalDevelopmentActions.SET_PD_CLASSROOM_TILL_DATE,
        payload: goalsList,
      });
  };

  const fetchProfessionalDevelopmentClassroomTillDate = async () => {
    // setPdClassroomLoading(true);
    const month = pdClassroom.week_date
      ? moment(pdClassroom.week_date).format("MMMM")
      : new Date().getMonth() + 1;
    const dateNo = pdClassroom.week_date
      ? parseInt(moment(pdClassroom.week_date).format("D"))
      : new Date().getDate();
    const goalsList = await getProfessionalDevelopmentClassroomTillDate(
      classroomId,
      month,
      dateNo
    );
    goalsList && setGoalsList(goalsList);
    // : setPdClassroomLoading(false);
  };

  const setFormatives = (response: any) => {
    dispatch({
      type: ProfessionalDevelopmentActions.SET_FORMATIVES,
      payload: response,
    });
  };

  const fetchFormatives = useCallback(
    async (schoolId: number, classroomId: number, order_by?: string) => {
      const response = (await getAllFormatives(
        schoolId,
        classroomId,
        order_by
      )) as Array<SingleFormative>;

      response && setFormatives(response);
    },
    []
  );

  const markAssessmentCompleted = async (payload: any) => {
    const { isSummative, ...otherData } = payload;

    const ACTION = isSummative
      ? "SET_SUMMATIVE_MARK_COMPLETED"
      : "SET_FORMATIVE_MARK_COMPLETED";

    // dispatch({
    //     type: ProfessionalDevelopmentActions[ACTION],
    //     payload
    // });

    const res = await markCompleted(otherData);

    //set mark completed status to old value if req is not successfull
    if (!res || !res.updated) {
      dispatch({
        type: ProfessionalDevelopmentActions[ACTION],
        payload: { ...payload, status: !otherData.status },
      });
      return;
    }

    if (isSummative) {
      dispatch({
        type: ProfessionalDevelopmentActions[ACTION],
        payload: { ...payload, color: res.color },
      });
      return;
    }

    const isAllMoved = payload.status
      ? formatives.filter((item: SingleFormative) => !item.is_completed)
          .length <= 1
      : formatives.filter((item: SingleFormative) => item.is_completed)
          .length <= 1;

    if (isAllMoved) {
      dispatch({
        type: ProfessionalDevelopmentActions[ACTION],
        payload: { ...payload, isAllMoved: true, color: res.color },
      });
    } else {
      dispatch({
        type: ProfessionalDevelopmentActions[ACTION],
        payload: { ...payload, color: res.color },
      });
    }
  };

  const setSummatives = (data: any) => {
    dispatch({
      type: ProfessionalDevelopmentActions.SET_SUMMATIVES,
      payload: data,
    });
  };
  const fetchSummatives = useCallback(
    async (schoolId: number, classroomId: number) => {
      const response = (await getAllSummatives(
        schoolId,
        classroomId
      )) as Array<SingleSummative>;

      response && setSummatives(response);
    },
    []
  );

  const updateActiveHeaderTab = (tab: string) => {
    dispatch({
      type: ProfessionalDevelopmentActions.SET_ACTIVE_TAB,
      payload: tab,
    });

    switch (tab) {
      case HeaderTabs.Pacing_Calender: {
        navigate("/pacing-calendar");
        break;
      }
      case HeaderTabs.Discussion: {
        navigate("/discussions");
        break;
      }
      case HeaderTabs.Resources: {
        navigate("/classroom/resources");
        break;
      }
      case HeaderTabs.Report_Cards: {
        navigate("/classroom/report-cards", { state: null });
        break;
      }
      default:
        break;
    }
  };

  const advanceWeek = async (classroomId: number) => {
    await triggerAdvanceWeek(classroomId);
    setIsLoading(true);
    await fetchProfessionalDevelopmentClassroom();
    await fetchFormatives(schoolId, classroomId, "Scheduled");
    await fetchSummatives(schoolId, classroomId);
    setIsLoading(false);
  };

  const checkPrevYearSTLExists = async (schoolId: any) => {
    const res = await isPrevYearSTLExists(schoolId);
    res &&
      dispatch({
        type: ProfessionalDevelopmentActions.SET_PREV_STL_EXISTS,
        payload: res.previous_year_exists,
      });
  };

  const checkPrevYearClassroomSTLExists = async (
    schoolId: any,
    classroomId: number
  ) => {
    const res = await isPrevYearClassroomSTLExists(schoolId, classroomId);
    res &&
      dispatch({
        type: ProfessionalDevelopmentActions.SET_PREV_CLASSROOM_STL_EXISTS,
        payload: res.previous_year_exists,
      });
  };

  const reEnableAssessments = async (payload: any) => {
    const tests = await reEnableTest(payload);
    return !!tests;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (schoolId && classroomId) {
        setIsLoading(true);
        await fetchFormatives(schoolId, classroomId, "Scheduled");
        await fetchSummatives(schoolId, classroomId);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setFormatives([]);
        dispatch({
          type: ProfessionalDevelopmentActions.SET_SUMMATIVES,
          payload: [],
        });
      }
    };

    fetchData();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [classroomId, schoolId, fetchFormatives, fetchSummatives]);

  const navigateToScreen = (route: string) => {
    classroomId && navigate(route);
  };

  useEffect(() => {
    if (classroomId) {
      fetchProfessionalDevelopmentClassroom();
      checkPrevYearClassroomSTLExists(schoolId, classroomId);
      // fetchProfessionalDevelopmentClassroomTillDate();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [classroomId]);

  useEffect(() => {
    if (schoolId && auth?.currentUser?.role === "School Admin") {
      checkPrevYearSTLExists(schoolId);
      // fetchProfessionalDevelopmentClassroomTillDate();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [schoolId]);

  const navigateTo = (
    assessment: Assessment,
    location: string,
    object: any = {}
  ) => {
    if (object.enableReportButtons === false) {
      setShowReportAlertModal(assessment);
      return;
    }

    switch (location) {
      case ReportsNavigation.SBR: {
        navigateToScreen(`report/standard-base-report/${classroomId}`);
        return;
      }
      case ReportsNavigation.OAG: {
        navigateToScreen(`report/oag-report/${classroomId}`);
        return;
      }
      case ReportsNavigation.IRR: {
        navigateToScreen(
          `report/item-response-report-formative/${object.fk_objective_id}/${classroomId}`
        );
        return;
      }
      case ReportsNavigation.PREVIEW: {
        const assessmentClass = classrooms.find(
          (cls: any) => cls.id === classroomId
        );

        const isMath = assessmentClass.label.includes("Math");

        Cookie.set("isMath", isMath);
        Cookie.set("assessmentName", object.name);

        navigateToScreen(`/preview/${assessment}/${object.id}`);
        return;
      }
      case ReportsNavigation.ANSWER: {
        navigateToScreen(
          `/classroom/${assessment}/answer/${object.id}/${classroomId}`
        );
        return;
      }
      case ReportsNavigation.MONITOR: {
        // navigateToScreen(`/classroom/monitor/${classroomId}`);
        classroomId &&
          navigate(`/classroom/monitor/${classroomId}`, {
            state: {
              id: object.id,
              is_formative: assessment === Assessment.formative,
            },
          });
        return;
      }
      case ReportsNavigation.MASTER: {
        navigateToScreen(`report/master-report/${classroomId}`);
        return;
      }
      default:
        return;
    }
  };

  return (
    <ProfessionalDevelopmentContext.Provider
      value={{
        assessmentMonitor,
        fetchAssessmentMonitorData,
        reEnableAssessments,
        formativeRemediateList,
        fetchFormativeRemediateData,
        fetchProfessionalDevelopmentClassroomTillDate,
        setFormatives,
        pdClassroom,
        formatives,
        summatives,
        activeHeaderTab,
        updateActiveHeaderTab,
        classroomId,
        schoolId,
        prevSTLClassroomExists,
        navigateToScreen,
        navigateTo,
        markAssessmentCompleted,
        classrooms,
        showReviewHelpModel,
        showReportModal,
        isLoading,
        setShowReportAlertModal,
        setShowReviewHelpModel,
        setIsLoading,
        fetchFormatives,
        setSummatives,
        fetchSummatives,
        goalsList,
        advanceWeek,
        selectedGoal,
        setPdClassroom,
        setGoalsList,
        prevSTLexists,
        setSTLType,
        STLType,
        setAssessmentMonitor,
        assessmentMonitorLoading,
      }}
    >
      {children}
    </ProfessionalDevelopmentContext.Provider>
  );
};
export default ProfesssionalDevelopmentProvider;
